import React, { useMemo } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";
import unionBy from "lodash/unionBy";
import MultiSelect from "components/Common/MultiSelect";

import "./styles.scss";
import { connect } from "react-redux";
import { getPeopleFilterCompaniesLoading, getPeopleFilterJobsLoading, getPeopleFilterRatingsLoading, getPeopleFilterStatusesLoading, getPeoplePageSelectedFilter, getSearchPeopleFilterLoading } from "store/modules/people/selectors";
import { store } from "store";
import { fetchSearchPeopleFilter, setLatestPeopleFilterJobsLoading, setPeoplesPageSelectedFilter } from "store/modules/people/actions";
import { getRatingToString } from "mixins/helperCandidate";

const Filters = ({
  t,
  filters,
  filter,
  clearFilter,
  filtersPaginationData,
  fetchLatestPeopleFilterCompanies,
  fetchLatestPeopleFilterJobs,
  fetchLatestPeopleFilterOwners,
  isFilterCompaniesLoading,
  isFilterJobsLoading,
  isFilterOwnersLoading,
  isSearchFilterLoading,
  isFilterRatingsLoading,
  isFilterStatusesLoading
}) => {
  const optionCompanies = useMemo(() => unionBy((filters.companies || [])
    .map(({ name, candidates_amount: amount, key }) => ({
      label: name,
      value: key,
      amount
    })) || [], filter?.companies.map(val => ({
      ...val,
      amount: filters?.companies?.find(a => a.value === val.value) ? val.amount : 0
    })) || [], "value"), [filter.companies, filters.companies]);

  const optionJobs = useMemo(() => unionBy((filters.jobs || [])
    .map(({ title, candidates_amount: amount, key }) => ({
      label: title,
      value: key,
      amount
    })) || [], filter?.jobs.map(val => ({
      ...val,
      amount: filters?.jobs?.find(a => a.value === val.value) ? val.amount : 0
    })) || [], "value"), [filter.jobs, filters.jobs]);

  const optionOwners = useMemo(() => unionBy((filters.owners || [])
    .map(({ full_name: name, candidates_amount: amount, key }) => ({
      label: name,
      value: key,
      amount
    })) || [], filter?.owners.map(val => ({
      ...val,
      amount: filters?.owners?.find(a => a.value === val.value) ? val.amount : 0
    })) || [], "value"), [filter.owners, filters.owners]);
  const optionRatings = useMemo(() => (filters.ratings || [])
    .map(({ rank, candidates_amount: amount }) => ({
      label: getRatingToString({ t, rating: Math.floor(rank) })?.label || String("people.table.noRating"),
      value: rank,
      amount
    })), [filters.ratings, t]);
  const optionStatuses = useMemo(() => (filters.statuses || [])
    .map(({ status, candidates_amount: amount }) => ({
      label: `people.table.${status}`,
      value: status,
      amount
    })), [filters.statuses]);
  const filterCount = useMemo(() => {
    const filterArray = Object.keys(filter)
      .filter(item => Array.isArray(filter[item]) && filter[item].length);
    return filterArray.length;
  }, [filter]);

  return (
    <Row justify="center" className="people__filters">
      <Col md={6} lg={5}>
        <input
          id="name-search"
          className="form__search-input name-search__input n-font-small jobs"
          placeholder={t("input.searchPeoplePlaceholder")}
          onChange={e => {
            store.dispatch(setPeoplesPageSelectedFilter({
              value: e.target.value,
              field: "title"
            }));
          }}
          value={filter.title}
          type="text"
        />
      </Col>
      <Col md={12}>
        <Row justify="center">
          <Col sm={12} md={6} lg>
            <MultiSelect
              options={optionCompanies}
              value={filter.companies}
              handleSelect={value => {
                store.dispatch(setPeoplesPageSelectedFilter({
                  value,
                  field: "companies"
                }));
              }}
              isLoading={optionCompanies.length === 0 && isFilterCompaniesLoading}
              placeholder="select.companyPlaceholder"
              next={filtersPaginationData.companiesNext}
              fetchLatest={next => {
                if (next) return store.dispatch(fetchLatestPeopleFilterCompanies(next));
                return store.dispatch(fetchSearchPeopleFilter({ search: "", filter, field: "companies" }));
              }}
              isNextLoading={isFilterCompaniesLoading}
              isSearchLoading={isSearchFilterLoading}
              fetchOnOpen
            />
          </Col>
          <Col sm={12} md={6} lg>
            <MultiSelect
              options={optionJobs}
              value={filter.jobs}
              handleSelect={newJobs => {
                store.dispatch(setPeoplesPageSelectedFilter({
                  value: newJobs,
                  field: "jobs"
                }));
              }}
              placeholder="select.interviewPlaceholder"
              next={filtersPaginationData.jobsNext}
              isLoading={optionJobs.length === 0 && isFilterJobsLoading}
              fetchLatest={next => {
                if (next) return store.dispatch(fetchLatestPeopleFilterJobs(next));
                return store.dispatch(fetchSearchPeopleFilter({ search: "", filter, field: "jobs" }));
              }}
              fetchOnSearch={val => {
                store.dispatch(setLatestPeopleFilterJobsLoading(true));
                store.dispatch(fetchSearchPeopleFilter({
                  search: val, filter, field: "jobs"
                }));
              }}
              isNextLoading={isFilterJobsLoading}
              isSearchLoading={isSearchFilterLoading}
              fetchOnOpen
            />
          </Col>
          <Col sm={12} md={4} lg>
            <MultiSelect
              options={optionOwners}
              value={filter.owners}
              handleSelect={newOwners => {
                store.dispatch(setPeoplesPageSelectedFilter({
                  value: newOwners,
                  field: "owners"
                }));
              }}
              isLoading={optionOwners.length === 0 && isFilterOwnersLoading}
              placeholder="select.ownerPlaceholder"
              next={filtersPaginationData.ownersNext}
              fetchLatest={next => {
                if (next) return store.dispatch(fetchLatestPeopleFilterOwners(next));
                return store.dispatch(fetchSearchPeopleFilter({ search: "", filter, field: "owners" }));
              }}
              isNextLoading={isFilterOwnersLoading}
              // filterOptions={fetchSearchPeopleFilterOwners}
              isSearchLoading={isSearchFilterLoading}
              fetchOnOpen
            />
          </Col>
          <Col sm={12} md={4} lg>
            <MultiSelect
              options={optionStatuses}
              value={filter.statuses}
              handleSelect={value => {
                store.dispatch(setPeoplesPageSelectedFilter({
                  value,
                  field: "statuses"
                }));
              }}
              isLoading={optionStatuses.length === 0 && isFilterStatusesLoading}
              placeholder="select.statusPlaceholder"
              withTranslationLabel
              fetchOnOpen
              fetchLatest={() => {
                store.dispatch(fetchSearchPeopleFilter({ search: "", filter, field: "statuses" }));
              }}
            />
          </Col>
          <Col sm={12} md={4} lg>
            <MultiSelect
              options={optionRatings}
              value={filter.ratings}
              handleSelect={value => {
                store.dispatch(setPeoplesPageSelectedFilter({
                  value,
                  field: "ratings"
                }));
              }}
              isLoading={optionRatings.length === 0 && isFilterRatingsLoading}
              placeholder="people.table.rating"
              withTranslationLabel
              fetchLatest={() => {
                store.dispatch(fetchSearchPeopleFilter({ search: "", filter, field: "ratings" }));
              }}
              fetchOnOpen
            />
          </Col>
        </Row>
      </Col>
      <Col md={5} className="people__clear">
        <p className="n-font-small n-font-medium-weight n-grey-60">
          {t(`people.table.selected.${filterCount === 1 ? "0" : "1"}`, { count: filterCount })}
        </p>
        <p className="n-font-small n-font-medium-weight n-grey-60 people__clear-separate">|</p>
        <button
          type="button"
          className="jobs__clear-filter n-font-small n-font-light n-grey-60"
          onClick={clearFilter}
        >
          <span className="text ">{t("input.clearAll")}</span>
        </button>
      </Col>

      {/* <Col md={12}>
        <div className="jobs__message">
          {success && <Message success message={t(success)} />}
          {error && <Message error message={t(error)} />}
        </div>

        {isShowText && (
          <p className="main-text n-font-medium n-text-center">
            {`${t("job.createCompany")} `}
            <a
              href="https://support.willo.video"
              target="_blank"
              rel="noopener noreferrer"
              className="main-text main-link n-purple-100"
            >
              {t("link.needHelp")}
            </a>
          </p>
        )}
      </Col> */}
    </Row>
  );
};

Filters.defaultProps = {
  filter: {
    title: "",
    companies: [],
    jobs: [],
    owners: [],
    ratings: [],
    statuses: []
  },
  clearFilter: null,
  isFilterCompaniesLoading: undefined,
  isFilterJobsLoading: undefined,
  isFilterOwnersLoading: undefined,
  isSearchFilterLoading: undefined
};

Filters.propTypes = {
  t: PropTypes.func.isRequired,
  filters: PropTypes.shape({
    companies: PropTypes.arrayOf(PropTypes.any),
    jobs: PropTypes.arrayOf(PropTypes.any),
    owners: PropTypes.arrayOf(PropTypes.any),
    ratings: PropTypes.arrayOf(PropTypes.any),
    statuses: PropTypes.arrayOf(PropTypes.any)
  }).isRequired,
  filter: PropTypes.shape({
    title: PropTypes.string,
    companies: PropTypes.arrayOf(PropTypes.any),
    jobs: PropTypes.arrayOf(PropTypes.any),
    owners: PropTypes.arrayOf(PropTypes.any),
    ratings: PropTypes.arrayOf(PropTypes.any),
    statuses: PropTypes.arrayOf(PropTypes.any)
  }),
  clearFilter: PropTypes.func,
  filtersPaginationData: PropTypes.shape({
    companiesNext: PropTypes.string,
    jobsNext: PropTypes.string,
    ownersNext: PropTypes.string
  }).isRequired,
  fetchLatestPeopleFilterCompanies: PropTypes.func.isRequired,
  fetchLatestPeopleFilterJobs: PropTypes.func.isRequired,
  fetchLatestPeopleFilterOwners: PropTypes.func.isRequired,
  isFilterCompaniesLoading: PropTypes.bool,
  isFilterJobsLoading: PropTypes.bool,
  isFilterOwnersLoading: PropTypes.bool,
  isSearchFilterLoading: PropTypes.bool,
  isFilterRatingsLoading: PropTypes.bool.isRequired,
  isFilterStatusesLoading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  isFiltersLoading: getSearchPeopleFilterLoading(state),
  isFilterCompaniesLoading: getPeopleFilterCompaniesLoading(state),
  isFilterJobsLoading: getPeopleFilterJobsLoading(state),
  isFilterRatingsLoading: getPeopleFilterRatingsLoading(state),
  isFilterStatusesLoading: getPeopleFilterStatusesLoading(state),
  filter: getPeoplePageSelectedFilter(state)
  // isFilterOwnersLoading: getIsFilter
});
export default connect(mapStateToProps)(withTranslation()(Filters));
