import { create } from "zustand";
import { getRequestWithToken } from "sagas/api";
import { TranscriptionsType } from "types/transcription.ts";

const useTranscrpitionStore = create(set => ({
  transcriptions: [],
  searchString: "",
  setSearchString: (value: string) => {
    set({
      searchString: value
    });
  },
  fetch: async (id: string, token: string) => {
    try {
      const res = await getRequestWithToken({
        url: `/api/transcripts/candidate/${id}/list/`,
        token
      }) as {
        data: TranscriptionsType
      };
      set({
        transcriptions: res.data
      });
    } catch (err) {
      console.error(err);
      set({
        transcriptions: []
      });
    }
  }
}));


export default useTranscrpitionStore;
