/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { withTranslation } from "react-i18next";

import Interview from "components/Candidates/Interview";
import { Avatar, ChevronRight } from "mixins/svgIcons";

import "./styles.scss";
import { useObject } from "react-firebase-hooks/database";
import { ref } from "firebase/database";
import StartFirebase from "configs/firebase";
import ContentExpired from "components/ContentExpired";
import SkeletonWrapper from "containers/Hoc/SkeletonWrapper";
import { connect } from "react-redux";
import { isPublicInfoLoading } from "store/modules/public/selectors";
import CandidateHeader from "../CandidateHeader/CandidateHeader";

const Candidate = ({
  tokenId,
  publicUserInfo: { candidate = {}, answers = [], company },
  isLoading,
  selected,
  publicBulk,
  fetchPublicUserInfo,
  clearErrors,
  setSelected,
  buttonColor
}) => {
  const fileteredQuestions = answers.sort(
    (a, b) => a.question?.order - b.question?.order
  );

  const currentIndex = useMemo(() =>
    publicBulk.findIndex(({ key }) => key === selected), [publicBulk, selected]);
  const nextIndex = useMemo(() =>
    Math.min(currentIndex + 1, publicBulk.length - 1), [currentIndex, publicBulk]);
  const nextCandidate = useMemo(() =>
    publicBulk[nextIndex], [publicBulk, nextIndex]);

  const [db, setDB] = useState();
  const [snapshot] = useObject((db && candidate?.key) ? ref(db, `Candidates/${candidate?.key}`) : null);

  useEffect(() => {
    const getDatabase = async () => {
      const database = await StartFirebase();
      setDB(database);
    };

    getDatabase();
  }, []);

  useEffect(() => {
    clearErrors();
    if (tokenId) fetchPublicUserInfo({ tokenId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenId]);

  return (
    <>
      <div className="public-candidate__container">
        <CandidateHeader
          selected={selected}
          setSelected={setSelected}
          currentIndex={currentIndex}
          nextIndex={nextIndex}
          items={publicBulk}
          company={company}
          isLoading={isLoading}
          candidate={candidate}
          isShowcase
          contactInfo={candidate?.showcase_contact_info}
        />

        <Container>
          <Col md={12} className="preview-container">
            {candidate?.anonymized_at || candidate?.is_expired ? (
              <div style={{ marginBottom: 50 }}>
                <ContentExpired
                  setCurrentQuestion={() => {}}
                  isCurrent
                  noHeader
                  size="big"
                />
              </div>
            ) : (
              <Interview
                answers={fileteredQuestions}
                withUpload={false}
                buttonColor={buttonColor}
                realtimeAnswers={snapshot?.val()?.answers}
                isRecruiter
              />
            )}
          </Col>
          {isMobile && selected && currentIndex !== publicBulk.length - 1 && (
            <Row
              className="candidates__next-button"
              onClick={() => setSelected(nextCandidate.key)}
            >
              <Row>
                <SkeletonWrapper
                  skeletonProps={{
                    circle: true,
                    width: 45,
                    height: 45,
                    enableAnimation: !nextCandidate.anonymized_at
                  }}
                  skeletonWrapperProps={{
                    style: {
                      paddingRight: 12
                    }
                  }}
                  anonymizedDate={nextCandidate.anonymized_at}
                  isLoading={isLoading}
                >
                  {nextCandidate.avatar_remote_link ? (
                    <img
                      src={nextCandidate.avatar_remote_link}
                      className="candidates__list-item-avatar"
                      alt="avatar"
                    />
                  ) : (
                    <div className="candidates__list-item-avatar candidates__list-item-empty-avatar">
                      <Avatar svgIconClass="n-icon__small right" />
                    </div>
                  )}
                </SkeletonWrapper>
                <div className="candidates__next-button-title-container">
                  <SkeletonWrapper
                    skeletonProps={{
                      count: 1,
                      height: 16,
                      width: 100,
                      enableAnimation: !nextCandidate.anonymized_at
                    }}
                    anonymizedDate={nextCandidate.anonymized_at}
                    isLoading={isLoading}
                  >
                    <span className="candidates__next-button-title">
                      {nextCandidate.name}
                    </span>
                  </SkeletonWrapper>
                  <span>View next response</span>
                </div>
              </Row>
              <ChevronRight fill="#768493" width="15" height="24" />
            </Row>
          )}
        </Container>
      </div>
    </>
  );
};

Candidate.defaultProps = {
  selected: "",
  publicBulk: [],
  setSelected: null,
  buttonColor: null,
  isLoading: true
};

Candidate.propTypes = {
  tokenId: PropTypes.string.isRequired,
  publicUserInfo: PropTypes.shape({
    candidate: PropTypes.shape({}),
    answers: PropTypes.arrayOf(PropTypes.shape({})),
    company: PropTypes.shape({
      layout_color: PropTypes.string
    })
  }).isRequired,
  isLoading: PropTypes.bool,
  selected: PropTypes.string,
  publicBulk: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      avatar_remote_link: PropTypes.string,
      name: PropTypes.string,
      anonymized_at: PropTypes.string
    })
  ),
  fetchPublicUserInfo: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  setSelected: PropTypes.func,
  buttonColor: PropTypes.string
};

const mapStateToProps = state => ({
  isLoading: isPublicInfoLoading(state)
});

export default connect(mapStateToProps)(withTranslation()(Candidate));
