import React from "react";
import PropTypes from "prop-types";

const CandidateVideoIcon = ({ height = "40", width = "40" }) => (
  <svg width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M27.0185 18.51L16.2285 13.1133C15.9235 12.9607 15.5845 12.8886 15.2437 12.9039C14.903 12.9192 14.5718 13.0214 14.2817 13.2008C13.9916 13.3801 13.7522 13.6307 13.5862 13.9287C13.4202 14.2267 13.3333 14.5622 13.3335 14.9033V25.0966C13.3333 25.4377 13.4202 25.7732 13.5862 26.0712C13.7522 26.3692 13.9916 26.6198 14.2817 26.7991C14.5718 26.9785 14.903 27.0807 15.2437 27.096C15.5845 27.1113 15.9235 27.0393 16.2285 26.8866L27.0185 21.49C27.295 21.3514 27.5274 21.1386 27.6899 20.8755C27.8523 20.6124 27.9384 20.3092 27.9384 20C27.9384 19.6907 27.8523 19.3876 27.6899 19.1244C27.5274 18.8613 27.295 18.6485 27.0185 18.51Z" stroke="#5A2AF1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M20 35C28.2843 35 35 28.2843 35 20C35 11.7157 28.2843 5 20 5C11.7157 5 5 11.7157 5 20C5 28.2843 11.7157 35 20 35Z" stroke="#5A2AF1" strokeWidth="2" />
  </svg>
);

CandidateVideoIcon.defaultProps = {
  height: "40",
  width: "40"
};

CandidateVideoIcon.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string
};

export default CandidateVideoIcon;
