/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useMemo, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import moment from "moment";
import { isMobile } from "react-device-detect";
import TooltipSuccess from "components/Common/Tooltip/Success";

import { constructMonthLabel } from "mixins/helperTranslate";
import { Avatar, CheckAvatar, Alert } from "mixins/svgIcons";

import { PLANS_NAMES } from "configs/plans";
import CANDIDATE_STATUSES from "configs/candidates";
import { DATE_FORMATS } from "configs/dates";

import { connect } from "react-redux";
import { getIsTrial } from "store/modules/notifications/selectors";
import { store } from "store";
import {
  removeExcludedCandidates,
  setTotalSelectedCount
} from "store/modules/jobs/actions";
import {
  getExcludedCandidates,
  getHasSelectAllStatus
} from "store/modules/jobs/selectors";
import { getRatingToString } from "mixins/helperCandidate";
import { isInteger, isNull } from "lodash";
import { FaLock } from "react-icons/fa";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import { shouldShowScorecard } from "store/modules/scorecards/action";
import { getInterviewHasScorecard } from "store/modules/scorecards/selectors";
import SkeletonWrapper from "containers/Hoc/SkeletonWrapper";
import Title from "../Title";
import KanbanScorecardModal from "./KanbanScorecardModal";

const Item = ({
  t,
  user = {},
  job = {},
  userColumnName = "",
  handleBillingModal,
  currentUser: { plan_name: planName = PLANS_NAMES.freeForever, role },
  changeSelected,
  selected,
  isOnTrial,
  handleSetInterviewersByStatusList,
  interviewHasScorecard,
  isNextLoading,
  clearSelectedStatus = () => {}
}) => {
  const lockRef = useRef(null);
  const [isShowTooltip, setIsShowTooltip] = useState(false);
  const isAdminAndOnTrial = isOnTrial && role === "admin";
  const [showScorecardModal, setShowScorecardModal] = useState(false);

  const isSelected = useMemo(
    () => selected?.map(a => a.id)?.includes(user.key),
    [user.key, selected]
  );

  const rating = getRatingToString({
    rating: isInteger(user.avg_rank)
      ? user.avg_rank
      : Math.floor(user.avg_rank),
    isActive: true,
    height: "16",
    width: "16",
    t
  });

  const shouldSubmitScorecard = user.status !== CANDIDATE_STATUSES.INVITED && isNull(user.avg_rank);

  return (
    <>
      <Link
        to={{
          pathname:
            user?.anonymized_at ? "" : Boolean(user?.is_locked) && isAdminAndOnTrial
              ? "/plans"
              : `/jobs/candidate/${user.key}/${job.key}`
        }}
        onClick={event => {
          if (user?.anonymized_at) {
            event.preventDefault();
            event.stopPropagation();
          } else if (
            (Boolean(user?.is_locked) &&
              planName === PLANS_NAMES.freeForever) ||
            (Boolean(user?.is_locked) && isOnTrial && role !== "admin")
          ) {
            event.preventDefault();
            handleBillingModal();
          } else {
            handleSetInterviewersByStatusList();
          }
        }}
        disabled={
          Boolean(user?.is_locked) &&
          planName !== PLANS_NAMES.freeForever &&
          !isOnTrial
        }
      >
        <div
          className={`candidates__list-item ${userColumnName}
        ${userColumnName !== "invited" &&
          user?.is_viewed === false &&
          "new-interview"}`}
        >
          <SkeletonWrapper
            skeletonProps={{
              circle: true,
              width: 45,
              height: 45,
              enableAnimation: !user?.anonymized_at
            }}
            skeletonWrapperProps={{
              style: {
                paddingRight: 12
              }
            }}
            anonymizedDate={user?.anonymized_at}
            isLoading={isNextLoading}
          >
            {isSelected ? (
              <div
                className={`candidates__list-item-avatar candidates__list-item-empty-avatar ${userColumnName} cursor`}
                role="presentation"
                onClick={e => {
                  e.preventDefault();
                  if (changeSelected) {
                    clearSelectedStatus();
                    changeSelected(
                      [
                        {
                          id: user.key,
                          status: user.status
                        }
                      ],
                      true
                    );
                  }
                }}
              >
                <CheckAvatar svgIconClass="n-icon__small right" />
              </div>
            ) : (
              <div
                role="presentation"
                className={`candidates__list-item-avatar ${user?.status !==
                  CANDIDATE_STATUSES.INVITED &&
                  "candidates__list-item-avatar-container"} ${changeSelected &&
                  "cursor"} ${!isMobile && !user?.is_locked && "with-hover"}`}
                onClick={e => {
                  e.preventDefault();
                  if (changeSelected) {
                    clearSelectedStatus();
                    changeSelected(
                      [
                        {
                          id: user.key,
                          status: user.status
                        }
                      ],
                      false,
                      user.status
                    );
                    store.dispatch(setTotalSelectedCount());
                    store.dispatch(removeExcludedCandidates(user));
                  }
                }}
              >
                {user.avatar_remote_link ? (
                  <img
                    src={user.avatar_remote_link}
                    className="candidates__list-item-avatar"
                    alt="avatar"
                  />
                ) : (
                  <div className="candidates__list-item-avatar candidates__list-item-empty-avatar">
                    <Avatar svgIconClass="n-icon__small right" />
                  </div>
                )}
                <div
                  className={`candidates__list-item-avatar candidates__list-item-empty-avatar ${userColumnName} hover`}
                >
                  <CheckAvatar svgIconClass="n-icon__small right" />
                </div>
              </div>
            )}
          </SkeletonWrapper>
          <div className="candidates__list-item-container">
            <SkeletonWrapper
              skeletonProps={{
                count: 3,
                enableAnimation: !user?.anonymized_at,
                height: 16
              }}
              skeletonWrapperProps={{
                style: {
                  display: "block",
                  lineHeight: 1.5,
                  marginBottom: "0.5rem",
                  width: "90%"
                }
              }}
              anonymizedDate={user?.anonymized_at}
              isLoading={isNextLoading}
            >
              <div
                className="candidates__list-item-container"
                style={{ width: "100%" }}
              >
                <Title
                  user={user}
                  handleBillingModal={handleBillingModal}
                  plan_name={planName}
                />

                {Boolean(user?.is_locked) && (
                  <>
                    <i
                      ref={lockRef}
                      onMouseEnter={() => setIsShowTooltip(true)}
                      onMouseLeave={() => setIsShowTooltip(false)}
                      className="fas gold fa-lock candidates__list-item-icon"
                    >
                      <FaLock />
                    </i>
                    <TooltipSuccess
                      position="top"
                      message={t("plan.unlock")}
                      target={lockRef.current}
                      show={isShowTooltip}
                    />
                  </>
                )}

                {user?.status === CANDIDATE_STATUSES.INVITED &&
                  (user?.has_email_bounced || user?.has_email_dropped) && (
                    <p className="candidates__list-item-submited n-font-extra-small n-grey-400">
                      <Alert />
                      <span className="candidates__list-item-date n-font-extra-small n-grey-400">
                        {t("candidate.common.emailBounced")}
                      </span>
                    </p>
                  )}

                {user?.status !== CANDIDATE_STATUSES.INVITED ? (
                  <p className="candidates__list-item-submited n-font-extra-small n-grey-400">
                    <span className="candidates__list-item-date n-font-extra-small n-grey-400">
                      {constructMonthLabel(
                        t,
                        "month",
                        moment(user?.submitted_at).format(
                          DATE_FORMATS.SPACE_SEPARATOR_LONG_MONTH
                        )
                      )}
                    </span>
                  </p>
                ) : null}

                {user?.is_expired ? (
                  <p className="candidates__list-item-submited n-font-extra-small n-grey-400">
                    <span className="candidates__list-item-date n-font-extra-small n-grey-400 expired">
                      {t("people.table.Expired")}
                    </span>
                  </p>
                ) : null}

                {interviewHasScorecard && (
                  <>
                    {Boolean(user?.avg_rank) && rating ? (
                      <div
                        className="candidates__list-item-rating n-font-extra-small"
                        style={{ color: rating?.color }}
                        role="presentation"
                        onClick={e => {
                          e.preventDefault();
                          setShowScorecardModal(true);
                        }}
                      >
                        <div>{rating?.icon}</div>
                        {` ${rating?.label} (${user?.ranks_count})`}
                      </div>
                    ) : shouldSubmitScorecard ? (
                      <div
                        className="candidates__list-item-rating n-font-extra-small"
                        style={{ color: "#5A2AF1", fontSize: 13 }}
                        role="presentation"
                        onClick={() => store.dispatch(shouldShowScorecard(true))}
                      >
                        <HiOutlineExclamationCircle size={18} color="#5A2AF1" />
                        {t("scorecard.kanban.submit")}
                      </div>
                    ) : null}
                  </>
                )}
              </div>
            </SkeletonWrapper>
          </div>
        </div>
      </Link>
      {showScorecardModal && (
        <KanbanScorecardModal
          user={user}
          title={job?.scorecard?.title}
          userId={user?.key}
          handleCloseModal={() => {
            setShowScorecardModal(false);
          }}
        />
      )}
    </>
  );
};

Item.defaultProps = {
  job: undefined,
  userColumnName: undefined,
  user: undefined,
  handleBillingModal: undefined,
  changeSelected: undefined,
  selected: undefined,
  handleSetInterviewersByStatusList: undefined
};

Item.propTypes = {
  t: PropTypes.func.isRequired,
  job: PropTypes.shape({
    key: PropTypes.string,
    title: PropTypes.string
  }),
  userColumnName: PropTypes.string,
  user: PropTypes.shape({
    jobId: PropTypes.number,
    name: PropTypes.string,
    submitted: PropTypes.string,
    rating: PropTypes.number,
    submitted_at: PropTypes.string
  }),
  handleBillingModal: PropTypes.func,
  currentUser: PropTypes.shape({
    plan_name: PropTypes.string,
    role: PropTypes.string
  }).isRequired,
  changeSelected: PropTypes.func,
  selected: PropTypes.arrayOf(PropTypes.string),
  isOnTrial: PropTypes.bool.isRequired,
  interviewHasScorecard: PropTypes.bool.isRequired,
  handleSetInterviewersByStatusList: PropTypes.func,
  selectAll: PropTypes.shape({
    [PropTypes.string]: PropTypes.string
  }).isRequired,
  clearSelectedStatus: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  isOnTrial: getIsTrial(state),
  selectAll: getHasSelectAllStatus(state),
  excludedCandidates: getExcludedCandidates(state),
  interviewHasScorecard: getInterviewHasScorecard(state),
  isNextLoading: state?.jobsOverview?.isNextCandidatesLoading || false
});

export default connect(mapStateToProps)(withTranslation()(Item));
