/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { useCallback } from "react";
import { withTranslation } from "react-i18next";
import Select from "components/Forms/Select";
import { components } from "react-select";
import { getDurations, RETAKES } from "configs/jobs/constants";
import TooltipInfo from "components/Common/Tooltip/Info";
import { FileInfo } from "mixins/svgIcons";
import { getIsTrialOrPaid } from "store/modules/notifications/selectors";
import PropTypes from "prop-types";
import { store } from "store";
import { getCurrentUser } from "store/modules/users/selectors";
import { ROLE_ADMIN } from "configs/user/rolesConfig";
import { Controller } from "react-hook-form";
import { FaRedo, FaStopwatch } from "react-icons/fa";

const RetakesAndDuration = ({
  t,
  control,
  order
}) => {
  const { role } = getCurrentUser(store.getState());
  const isAdmin = role === ROLE_ADMIN;

  const hasAbilityToCreate = getIsTrialOrPaid(store.getState());
  const durationOptions = getDurations(!hasAbilityToCreate);

  const getRetakeLabel =
    useCallback(({ titleKey, value: total }) => t(titleKey, { total }), []);

  const getDurationLabel =
    useCallback(({ value: seconds }) => t(seconds / 60 > 1 ? "time.minutes" : "time.minute", { total: seconds / 60 }), []);


  const SingleValueRetakes = props => (
    <components.SingleValue className="flex flex-row" {...props}>
      <i className="fa xsm mr4 ml4 grey fa-repeat">
        <FaRedo />
      </i>
      {t(props.data.shortTitle, { total: props.data.value })}
    </components.SingleValue>
  );

  const SingleValueDuration = props => {
    const total = props.data.value / 60;
    return (
      <components.SingleValue className="flex flex-row" {...props}>
        <i className="fa xsm mr4 ml4 grey fa-stopwatch">
          <FaStopwatch />
        </i>
        {t(total > 1 ? "time.minutes" : "time.minute", { total })}
      </components.SingleValue>
    );
  };

  const Option = props => (
    <div
      role="presentation"
      {...props}
      onClick={() => {
        if (props.data.isDisabled && isAdmin) {
          window.open(
            "/plans?utm_source=internal&utm_medium=user&utm_campaign=locked_response_length",
            "_blank"
          );
        }
      }}
    >
      <components.Option {...props} />
    </div>
  );

  return (
    <>
      <Controller
        name={`questions[${order - 1}].max_retakes`}
        control={control}
        render={({
          field: { onChange, value }
        }) => (
          <Select
            label={t("job.questions.retakes.title")}
            value={RETAKES.find(option => option.value === value)}
            options={RETAKES}
            changed={newVal => onChange(newVal.value)}
            getOptionLabel={getRetakeLabel}
            components={{ SingleValue: SingleValueRetakes }}
            isSearchable={false}
            topRightLabel={(
              <span className="label__main">
                <TooltipInfo
                  position="top"
                  message={t("job.questions.retakes.tooltip")}
                  element={<FileInfo width="13" height="13" fill="#C5C4C4" />}
                />
              </span>
            )}
          />
        )}
      />
      <Controller
        name={`questions[${order - 1}].max_duration`}
        control={control}
        render={({
          field: { onChange, value }
        }) => (
          <Select
            label={t("job.questions.minutes.title")}
            value={durationOptions.find(option => option.value === value)}
            options={durationOptions}
            changed={newVal => onChange(newVal.value)}
            getOptionLabel={getDurationLabel}
            components={{ SingleValue: SingleValueDuration, Option }}
            isSearchable={false}
          />
        )}
      />

    </>
  );
};

RetakesAndDuration.defaultProps = {
  t: undefined
};

RetakesAndDuration.propTypes = {
  t: PropTypes.func,
  control: PropTypes.shape({}).isRequired,
  order: PropTypes.number.isRequired
};

export default withTranslation()(RetakesAndDuration);
