/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useRef, useState } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { CopyTable, SuccessIcon } from "mixins/svgIcons";
import { clearSharableLink } from "store/modules/public/actions";
import TooltipSuccess from "components/Common/Tooltip/Success";

const CopyButton = ({ t, isCopied, handleCopy, className = "" }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [color, setColor] = useState("#768493");
  const buttonRef = useRef();

  const copyToClipboard = useCallback(() => {
    setShowTooltip(true);
    handleCopy();

    const hide = setTimeout(() => {
      setShowTooltip(false);
      clearSharableLink();
      clearTimeout(hide);
    }, 2000);
  }, []);

  return (
    <>
      <button
        style={{ border: "none", marginLeft: 11, padding: 0 }}
        onClick={copyToClipboard}
        onMouseEnter={() => {
          setShowTooltip(true);
          setColor("#5A2AF1");
        }}
        onMouseLeave={() => {
          setShowTooltip(false);
          setColor("#768493");
        }}
        ref={buttonRef}
        type="button"
        className={className}
      >
        {
          isCopied ? <SuccessIcon svgIconClass="not-clickable-icon" />
            : (
              <CopyTable
                svgIconClass="not-clickable-icon"
                fill={color}
              />
            )
        }
      </button>

      <TooltipSuccess
        position="bottom"
        message={isCopied ? t("job.invite.inviteOne.buttonTipCopied") : t("job.invite.inviteOne.buttonTip")}
        target={buttonRef.current}
        show={showTooltip}
      />
    </>
  );
};

CopyButton.defaultProps = {
  className: ""
};

CopyButton.propTypes = {
  t: PropTypes.func.isRequired,
  isCopied: PropTypes.bool.isRequired,
  handleCopy: PropTypes.func.isRequired,
  className: PropTypes.string
};

export default withTranslation()(CopyButton);
