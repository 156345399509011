/* eslint-disable no-useless-escape */
/* eslint-disable camelcase */
import * as Yup from "yup";
import moment from "moment";
import _ from "lodash";
import uuidv4 from "uuid/v4";

import React from "react";
import CANDIDATE_STATUSES from "configs/candidates";
import { DATE_FORMATS, defaultTimeFormat } from "configs/dates";
import classNames from "classnames";
import {
  RatingNo,
  RatingStrongNo,
  RatingStrongYes,
  RatingUndecided,
  RatingYes
} from "./svgIcons";

export const handleClassNameById = (
  isAllAnsweredSaved,
  currentStepActive,
  currentValue,
  savedValue
) => {
  const currentClass = ["steps__circle small"];

  if (isAllAnsweredSaved || currentValue === savedValue?.question?.key) {
    currentClass.push("saved");
  }

  if (currentStepActive === currentValue) {
    currentClass.push("active");
  }

  return currentClass.join(" ");
};

export const handleGoNextQuestion = (index, questions, answers) => {
  /*
    We need allow user to go to the next question
    if previous was uploaded
  */
  const prevQuestion = questions?.[index - 1];
  const prevAnswer = answers?.[index - 1];

  const prevQuestionKey = prevQuestion ? prevQuestion.key : 0;
  const prevAnswerKey = prevAnswer ? prevAnswer.question.key : 0;

  return prevQuestionKey !== prevAnswerKey;
};

export const initialStateForPermissions = {
  agreement: false,
  errors: {},
  touched: {}
};

export const fileFormSchemaForPersmissions = () =>
  Yup.object().shape({
    agreement: Yup.bool().oneOf([true], "Must Accept Terms and Conditions")
  });

export const initialStateVideoAnswersConfirm = {
  full_name: "",
  email: "",
  phoneNumber: "",
  errors: {},
  touched: {}
};

// Here add so long regular as on the backend ( to avoid aditional handling errors )
export const schemaVideoAnswersConfirm = () =>
  Yup.object().shape({
    full_name: Yup.string().required("errors.required"),
    email: Yup.string()
      .trim()
      .email("errors.validEmail")
      .required("errors.required"),
    phone: Yup.string()
      .matches(
        /^(1[ \-\+]{0,3}|\+1[ -\+]{0,3}|\+1|\+)?((\(\+?1-[2-9][0-9]{1,2}\))|(\(\+?[2-8][0-9][0-9]\))|(\(\+?[1-9][0-9]\))|(\(\+?[17]\))|(\([2-9][2-9]\))|([ \-\.]{0,3}[0-9]{2,4}))?([ \-\.][0-9])?([ \-\.]{0,3}[0-9]{2,4}){2,3}$/,
        { message: "Invalid number", excludeEmptyString: true }
      )
      .required("errors.required")
  });

export const schemaQuestions = Yup.object().shape({
  questions: Yup.array()
    .of(
      Yup.object().shape({
        text: Yup.string().required()
      })
    )
    .required()
});

const checkIfRatingIsEqual = (item, inputValue) => {
  if ("rating" in item) {
    const ratingNumberValue = item?.rating;
    return ratingNumberValue === +inputValue;
  }
  return item;
};

const checkIfNameHasString = (item, inputValue) => {
  if ("name" in item) {
    const loverCaseUserName = item?.name?.toLowerCase();
    return loverCaseUserName.includes(inputValue);
  }
  return item;
};

const isNumeric = value => /^-{0,1}\d+$/.test(value);

const filterUsersByInputValue = (inputValue, users, fieldName) => {
  const isNumber = isNumeric(inputValue);
  const isString = !isNumber;

  const filteredUsers = users?.[fieldName]?.filter(item => {
    if (isNumber) {
      return checkIfRatingIsEqual(item, inputValue);
    }

    if (isString) {
      return checkIfNameHasString(item, inputValue);
    }

    return item;
  });

  return filteredUsers;
};

// Search users into the list by value ( string or number )
export const searchUsersIntoList = (inputValue, candidates) => {
  const inputLowerCase = inputValue?.toLowerCase();
  const isInputHasValue = Boolean(inputValue);
  const candidatesGroupNames = Object.keys(candidates);

  let filteredUsers;

  if (isInputHasValue) {
    filteredUsers = candidatesGroupNames?.reduce((acc, groupName) => {
      acc[groupName] = filterUsersByInputValue(
        inputLowerCase,
        candidates,
        groupName
      );

      return acc;
    }, {});
  } else {
    filteredUsers = candidates;
  }

  return filteredUsers;
};

export const formDatesRange = (totalDays, isOverview) => {
  const daysRange = [];
  let count = 0;
  let tmpDate = moment(isOverview).subtract(1, "days");

  while (count < totalDays) {
    tmpDate = tmpDate.add(1, "days");

    const isNotSunday =
      tmpDate.weekday() !==
      moment()
        .day("Sunday")
        .weekday();
    const isNotSaturday =
      tmpDate.weekday() !==
      moment()
        .day("Saturday")
        .weekday();

    if (isNotSunday && isNotSaturday) {
      daysRange.push({
        dateFormatted: tmpDate.format(DATE_FORMATS.DAY_OF_WEEK),
        dateISO: tmpDate.format(DATE_FORMATS.HYPHEN_SEPARATOR),
        isCurrentDate: tmpDate.isSame(new Date(), "day")
      });
      count += 1;
    }
  }

  return daysRange;
};

export const isTimeHasMinutes = currentTime =>
  currentTime % 2 === 0.5 || currentTime % 2 === 1.5;

export const formDataTime = (minTime, maxTime, isOverview) => {
  const data = [];
  let currentTime = minTime;

  while (currentTime <= maxTime) {
    // Here we add 2 numbers, time to add proper styles to the row, timeFromatted
    // to output proper time

    const timeFormatted = moment
      .utc()
      .startOf("day")
      .add(currentTime * 60, "minutes");
    const hasMinutes = isTimeHasMinutes(currentTime);

    data.push({
      id: uuidv4(),
      hasMinutes,
      timeFormatted: defaultTimeFormat(
        timeFormatted,
        isOverview && !hasMinutes
      ),
      timeISO: timeFormatted.format("HH:mm")
    });
    currentTime += 0.5;
  }

  return data;
};

export const handleStatus = status => {
  switch (true) {
  case status === CANDIDATE_STATUSES.INVITED:
    return "invited";
  case status === CANDIDATE_STATUSES.RECEIVED:
    return "review";
  case status === CANDIDATE_STATUSES.ACCEPTED:
    return "accepted";
  case status === CANDIDATE_STATUSES.REJECTED:
    return "rejected";
  case status === CANDIDATE_STATUSES.EXPIRED:
    return "expired";
  default:
    return "";
  }
};

export const getQueryParams = query => {
  const dividedQuery = query.split("&");

  // Add + 1 because string will be with = symbol
  return [...dividedQuery.map(item => item.substring(item.indexOf("=") + 1))];
};

export const handleLastDate = (arr = [], type = "") => {
  const filteredVideos = arr?.filter(item => item.action_type === type);
  const invited = arr?.filter(item => item.action_type === "Default");

  const latestVideo = filteredVideos?.reduce((acc, current) => {
    const accUpdateDate = acc.updated_at;
    const currentUpdateDate = current.updated_at;

    return accUpdateDate > currentUpdateDate ? acc : current;
  }, {});

  return _.isEmpty(latestVideo) ? invited[0] : latestVideo;
};

export const filterAnswers = answers =>
  answers?.sort((a, b) => a?.question?.order - b?.question?.order);

export const isTimeBeforeCurrent = timeISO => {
  const currentDate = moment();
  const cellTime = moment(timeISO, "HH:mm");
  const isBeforeCurrent = cellTime.isBefore(currentDate);

  return isBeforeCurrent;
};

export const isDateBeforeCurrent = dateISO =>
  moment(dateISO).isBefore(moment().startOf("day"));

export const isCurrentTime = timeISO => {
  const currentDate = moment();
  const cellTime = moment(timeISO, "HH:mm");
  const isBeforeCurrent = cellTime.isBefore(currentDate);
  const isAfterCurrent = currentDate.isBefore(cellTime.add(31, "minutes"));

  return isBeforeCurrent && isAfterCurrent;
};

export const formCalendarData = (timeData, datesRange) => {
  const result = [];
  timeData.forEach(({ timeISO, timeFormatted, hasMinutes }) => {
    const rowData = [
      {
        timeFormatted,
        hasMinutes
      }
    ];

    datesRange.forEach(({ dateISO, isCurrentDate }) => {
      const rowInfo = {
        dateTimeISO: `${dateISO}T${timeISO}`,
        isTimeBeforeCurrent: isCurrentDate && isTimeBeforeCurrent(timeISO),
        isDateBeforeCurrent: isDateBeforeCurrent(dateISO),
        isCurrentTime: isCurrentDate && isCurrentTime(timeISO),
        hasMinutes
      };
      rowData.push(rowInfo);
    });

    result.push(rowData);
  });

  return result;
};

export const isRedirectMissingSavedAnswers =
  process.env.REACT_APP_REDIRECT_MISSING_SAVED_ANSWERS === "true" ||
  process.env.REACT_APP_REDIRECT_MISSING_SAVED_ANSWERS === true;

export const getIsStorageSupported = () => {
  try {
    return window.sessionStorage && true;
  } catch (e) {
    return false;
  }
};

export const storageSessionId = getIsStorageSupported()
  ? window?.sessionStorage?.getItem("sessionId")
  : null;
export const storageSessionToken = getIsStorageSupported()
  ? window?.sessionStorage?.getItem("sessionToken")
  : null;
export const storageIsIdvCompleted = getIsStorageSupported()
  ? window?.sessionStorage?.getItem("isIdvCompleted")
  : null;
export const storageSessionUserId = getIsStorageSupported()
  ? window?.sessionStorage?.getItem("sessionUserId")
  : null;
export const storageSessionExpiry = getIsStorageSupported()
  ? window?.sessionStorage?.getItem("sessionExpiry")
  : null;

export const resetIdvSessionStorage = () => {
  if (getIsStorageSupported()) {
    window.sessionStorage.removeItem("sessionId");
    window.sessionStorage.removeItem("sessionToken");
    window.sessionStorage.removeItem("isIdvCompleted");
    window.sessionStorage.removeItem("sessionExpiry");
    window.sessionStorage.removeItem("sessionUserId");
  }
};

export const isCandidateRoute =
  (window?.location?.pathname?.includes("/candidate/") ||
    window?.location?.pathname?.includes("/common/") ||
    window?.location?.pathname?.includes("/invite/") ||
    window?.location?.pathname?.includes("sample-error-candidate")) &&
  !window?.location?.pathname?.includes("/jobs/candidate/");

export const isCurrentUserCandidate = JSON.parse(
  // eslint-disable-next-line no-nested-ternary
  getIsStorageSupported()
    ? window?.sessionStorage?.getItem("isCandidate")
    : isCandidateRoute
      ? "true"
      : "false"
);

export const getRatingToString = ({
  rating,
  isActive = false,
  isRatingIcon = false,
  t,
  ...iconProps
}) => {
  if (!rating) return {};

  const stringValues = {
    1: {
      label: t("scorecard.scores.strongNo"),
      untranslated_label: "Strong No",
      color: "#E11F1F",
      icon: (
        <RatingStrongNo
          svgIconCLass={classNames(
            `strong-no ${isActive ? "active" : ""} ${
              isRatingIcon ? "rating" : ""
            }`,
            ""
          )}
          {...iconProps}
        />
      )
    },
    2: {
      label: t("scorecard.scores.no"),
      untranslated_label: "No",
      color: "#E11F1F",
      icon: (
        <RatingNo
          svgIconCLass={classNames(`no ${isActive ? "active" : ""}`, "")}
          {...iconProps}
        />
      )
    },
    3: {
      label: t("scorecard.scores.maybe"),
      untranslated_label: "Maybe",
      color: "#F47704",
      icon: (
        <RatingUndecided
          svgIconCLass={classNames(`undecided ${isActive ? "active" : ""}`, "")}
          {...iconProps}
        />
      )
    },
    4: {
      label: t("scorecard.scores.yes"),
      untranslated_label: "Yes",
      color: "#0FAC00",
      icon: (
        <RatingYes
          svgIconCLass={classNames(`yes ${isActive ? "active" : ""}`, "")}
          {...iconProps}
        />
      )
    },
    5: {
      label: t("scorecard.scores.strongYes"),
      untranslated_label: "Strong Yes",
      color: "#13ac00",
      icon: (
        <RatingStrongYes
          svgIconCLass={classNames(
            `strong-yes ${isActive ? "active" : ""} ${
              isRatingIcon ? "rating" : ""
            }`,
            ""
          )}
          {...iconProps}
        />
      )
    }
  };

  return stringValues[rating];
};

export const getRatingIcon = ({ rating, isActive = false, ...iconProps }) => {
  if (!rating) return {};

  const stringValues = {
    "Strong No": {
      rank: 1,
      color: "#E11F1F",
      icon: (
        <RatingStrongNo
          svgIconCLass={classNames(
            `strong-no rating ${isActive ? "active" : ""}`,
            ""
          )}
          {...iconProps}
        />
      )
    },
    No: {
      rank: 2,
      color: "#E11F1F",
      icon: (
        <RatingNo
          svgIconCLass={classNames(`no ${isActive ? "active" : ""}`, "")}
          {...iconProps}
        />
      )
    },
    Maybe: {
      rank: 3,
      color: "#F47704",
      icon: (
        <RatingUndecided
          svgIconCLass={classNames(`undecided ${isActive ? "active" : ""}`, "")}
          {...iconProps}
        />
      )
    },
    Yes: {
      rank: 4,
      color: "#0FAC00",
      icon: (
        <RatingYes
          svgIconCLass={classNames(`yes ${isActive ? "active" : ""}`, "")}
          {...iconProps}
        />
      )
    },
    "Strong Yes": {
      rank: 5,
      color: "#13ac00",
      icon: (
        <RatingStrongYes
          svgIconCLass={classNames(
            `strong-yes rating ${isActive ? "active" : ""}`,
            ""
          )}
          {...iconProps}
        />
      )
    }
  };

  return stringValues[rating];
};
