import React, { useRef } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import DOMPurify from "dompurify";
import PropTypes from "prop-types";

const CandidateAnswerText = ({
  currentQuestionAnswer
}) => {
  const ref = useRef(null);

  const handleSelectText = () => {
    const selection = window.getSelection();
    const range = document.createRange();
    range.setStartBefore(ref.current);
    range.setEndAfter(ref.current);
    selection.removeAllRanges();
    selection.addRange(range);
  };

  function transform(node) {
    if (node.type === "tag") {
      node.attribs = {
        ...node.attribs,
        target: "_blank"
      };
    }
  }

  return (
    <>
      <div className="candidateanswer__section__response-text  response" ref={ref} onDoubleClick={handleSelectText}>
        <div className="text__answer-scroll">
          <div>
            {ReactHtmlParser(
              DOMPurify.sanitize(currentQuestionAnswer?.html_text, {
                ADD_ATTR: "href"
              }) ??
          "<p>Response missing. If this is unexpected, please contact support@willo.video</p>",
              {
                transform
              }
            )}
          </div>
        </div>
      </div>

      <div className="candidateanswer__floatingbackground" />
    </>
  );
};

CandidateAnswerText.defaultProps = {
  currentQuestionAnswer: {}
};

CandidateAnswerText.propTypes = {
  currentQuestionAnswer: PropTypes.shape({
    html_text: PropTypes.string
  })
};

const mapStateToProps = state => ({
  currentQuestionAnswer: state?.jobsCandidate?.currentQuestionAnswer
});

export default connect(mapStateToProps)(withTranslation()(CandidateAnswerText));
