import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { DBS_RTW_TYPE, IDVStatusChip, RTW_COUNTRIES, RTW_TYPE } from "configs/jobs/constants";
import { connect } from "react-redux";

const RTWCheck = ({
  t,
  idvCheckData,
  currentQuestionAnswer
}) => {
  const isRTWCheck = (
    [RTW_TYPE, DBS_RTW_TYPE].includes(idvCheckData?.represent_as) ||
    [RTW_TYPE, DBS_RTW_TYPE].includes(idvCheckData?.check_type)
  ) && RTW_COUNTRIES.includes(currentQuestionAnswer?.question?.idv_country_of_employment_iso_code);

  if (!isRTWCheck) return null;

  return (
    <div className="candidateanswer__section__response-idv__response-item">
      <div className="candidateanswer__section__response-idv__response-item__row title">
        <div className="candidateanswer__section__response-idv__response-item-title">
          {t("job.candidate.overview.idv.rtw_check.title")}
        </div>
        {IDVStatusChip[idvCheckData?.right_to_work?.status]}
      </div>

      <div className="candidateanswer__section__response-idv__response-item__row">
        <p style={{ width: "75%" }}>{`${t("job.candidate.overview.idv.answer.rtw.description")}`}</p>

      </div>
    </div>
  );
};


const mapStateToProps = state => ({
  currentQuestionAnswer: state?.jobsCandidate?.currentQuestionAnswer
});

export default connect(mapStateToProps)(withTranslation()(RTWCheck));


RTWCheck.defaultProps = {
  idvCheckData: {},
  currentQuestionAnswer: {}
};

RTWCheck.propTypes = {
  t: PropTypes.func.isRequired,
  idvCheckData: PropTypes.shape({
    right_to_work: PropTypes.shape({
      status: PropTypes.string
    }),
    represent_as: PropTypes.string,
    check_type: PropTypes.string
  }),
  currentQuestionAnswer: PropTypes.shape({
    question: PropTypes.shape({
      idv_country_of_employment_iso_code: PropTypes.string
    })
  })
};
