/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import debounce from "lodash/debounce";
import BeatLoader from "react-spinners/BeatLoader";
import { withTranslation } from "react-i18next";
import { useMultiSelect, Cross } from "react-multi-select-component";
import PropTypes from "prop-types";

import { isFunction } from "lodash";
import SelectList from "./SelectList";

const SelectPanel = ({ t, value, onChange, withTranslationLabel }) => {
  const {
    options,
    isLoading,
    filterOptions,
    fetchOnSearch
  } = useMultiSelect();

  const listRef = useRef();
  const searchInputRef = useRef();
  const [searchText, setSearchText] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [searchTextForFilter, setSearchTextForFilter] = useState("");
  const debouncedSearch = useCallback(
    debounce(query => setSearchTextForFilter(query), 300),
    []
  );

  const debouncedFetchOnSearch = useCallback(
    debounce(query => {
      if (fetchOnSearch) {
        fetchOnSearch(query);
      }
    }, 500),
    [fetchOnSearch]
  );


  const handleSearchChange = e => {
    debouncedSearch(e.target.value);
    setSearchText(e.target.value);
    if (fetchOnSearch && isFunction(fetchOnSearch)) {
      debouncedFetchOnSearch(e.target.value);
    }
  };

  const handleClear = () => {
    setSearchTextForFilter("");
    setSearchText("");
    debouncedFetchOnSearch("");
    if (searchInputRef.current) searchInputRef.current.focus();
  };

  useEffect(() => {
    if (!filterOptions && !fetchOnSearch) {
      setFilteredOptions(
        options.filter(
          ({ label }) =>
            label?.toLowerCase()?.trim()?.includes(searchTextForFilter?.toLowerCase()?.trim())
        )
      );
    }
  }, [searchTextForFilter, options]);

  useEffect(() => {
    if (filterOptions && !fetchOnSearch) {
      filterOptions(searchTextForFilter?.toLowerCase()?.trim());
    }
  }, [searchTextForFilter]);

  useEffect(() => {
    if (fetchOnSearch) {
      setFilteredOptions(options.filter(
        ({ label }) =>
          label?.toLowerCase()?.trim()?.includes(searchTextForFilter?.toLowerCase()?.trim())
      ));
    }
  }, [options]);

  useEffect(() => {
    if (filterOptions) {
      setFilteredOptions(options);
    }
  }, [options]);

  return (
    <div className="select-panel" role="listbox" ref={listRef}>
      <div className="search">
        <input
          placeholder={t("input.searchOwnerPlaceholder")}
          type="text"
          onChange={handleSearchChange}
          value={searchText}
          ref={searchInputRef}
        />
        <button
          type="button"
          className="search-clear-button"
          hidden={!searchText}
          onClick={handleClear}
        >
          <Cross />
        </button>
      </div>

      <ul className="options">
        {filteredOptions.length > 0 ? (
          <SelectList
            skipIndex={0}
            options={filteredOptions}
            onChange={onChange}
            value={value}
            withTranslationLabel={withTranslationLabel}
          />
        ) : (
          <li className="no-options">
            {isLoading ? (
              <BeatLoader color="#BBC2C9" size={6} margin={1} loading />
            ) : t("job.table.noOptions")}
          </li>
        )}
      </ul>
    </div>
  );
};

SelectPanel.propTypes = {
  t: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onChange: PropTypes.func.isRequired,
  withTranslationLabel: PropTypes.bool.isRequired
};

export default withTranslation()(SelectPanel);
