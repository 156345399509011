/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable camelcase */
import Copy from "components/Tables/Jobs/Buttons/Copy";
import { formatStringtoDuration } from "mixins/helperVideoRecording";
import { copyToClipboard } from "mixins/helpers";
import { CopyTable } from "mixins/svgIcons";
import React, { MouseEvent, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import useMediaPlayerStore from "store/mediaPlayerStore";
import { TranscriptionItemType } from "types/transcription.ts";

const CandidateTranscriptionItem = ({ transcription }: {
  transcription: TranscriptionItemType
}) => {
  const { setCurrentTimeStamp } = useMediaPlayerStore();
  const [isCopied, setIsCopied] = useState(false);
  const [showToolTip, setShowToolTip] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [isHoveringIcon, setIsHoveringIcon] = useState(false);
  const buttonRef = useRef<HTMLDivElement | null>(null);

  const handleCopy = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsCopied(true);

    setShowToolTip(true);
    copyToClipboard(transcription.text);

    setTimeout(() => {
      setShowToolTip(false);
    }, 3000);
  };


  return (
    <div
      className="transcript-item"
      onMouseEnter={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}

    >
      <div
        className="transcript-details"
        onClick={() => {
          setCurrentTimeStamp(transcription.start_time);
        }}
      >
        <span className="transcript-timestamp">{formatStringtoDuration(transcription.start_time)}</span>
        <div className="flex items-center flex-1">
          <div className="flex-1 w-full text-sm hover:text-main-purple">
            {transcription.text}
          </div>
          <div className="transcript-action" ref={buttonRef}>
            <Copy
              handleCopy={handleCopy}
              isCopied={isCopied}
              isShowTooltip={showToolTip}
              successStyles={{ marginLeft: 5 }}
              copyBtn={<CopyTable
                style={{ marginLeft: 5, display: isHovering || isMobile ? "inline-block" : "none" }}
                svgIconClass="not-clickable-icon"
                fill={isHoveringIcon ? "#5A2AF1" : "#B6BDC5"}
                onMouseEnter={() => setIsHoveringIcon(true)}
                onMouseLeave={() => setIsHoveringIcon(false)}
              />}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default CandidateTranscriptionItem;
