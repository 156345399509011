import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import ProfileCard from "components/Common/ProfileCard";
import { Link } from "react-router-dom";
import { FaCheckCircle, FaLock } from "react-icons/fa";

const PlanUpgrade = ({ t, isProAccount }) => (
  <ProfileCard>
    <div className="plan-upgrade__info">
      <h2 className="plan-upgrade__title flex flex-row items-center justify-center">
        <i className="fas gold fa-lock"><FaLock /></i>
        {t("modals.billing.upgradeToUnlock")}
      </h2>

      <ul>
        <li className="plan-upgrade__list-item flex flex-row items-center justify-center pb-2">
          <i className="far xs purple fa-check-circle plan-upgrade__list-icon">
            <FaCheckCircle />
          </i>
          <span>{t("modals.billing.listItemOne")}</span>
        </li>
        <li className="plan-upgrade__list-item flex flex-row items-center justify-center pb-2">
          <i className="far xs purple fa-check-circle plan-upgrade__list-icon">
            <FaCheckCircle />
          </i>
          <span>{isProAccount ? "" : t("modals.billing.listItemTwo")}</span>
        </li>
        <li className="plan-upgrade__list-item flex flex-row items-center justify-center pb-2">
          <i className="far xs purple fa-check-circle plan-upgrade__list-icon">
            <FaCheckCircle />
          </i>
          <span>{t("modals.billing.listItemThree")}</span>
        </li>
        <li className="plan-upgrade__list-item flex flex-row items-center justify-center pb-2">
          <i className="far xs purple fa-check-circle plan-upgrade__list-icon">
            <FaCheckCircle />
          </i>
          <span>{t("modals.billing.listItemFive")}</span>
        </li>
        <li className="plan-upgrade__list-item flex flex-row items-center justify-center pb-2">
          <i className="far xs purple fa-check-circle plan-upgrade__list-icon">
            <FaCheckCircle />
          </i>
          <span>{t("modals.billing.listItemFour")}</span>
        </li>
      </ul>

      <p className="plan-upgrade__text">{t("plan.noContactsOrSetup")}</p>

      <Link
        to="/plans?utm_source=internal&utm_medium=user&utm_campaign=profile_upgrade"
        className="plan-upgrade__link"
      >
        <button
          type="button"
          className="n-button__medium n-bg-purple-100 button__plan-upgrade"
        >
          {t("link.upgrade")}
        </button>
      </Link>
    </div>
  </ProfileCard>
);

PlanUpgrade.propTypes = {
  t: PropTypes.func.isRequired,
  isProAccount: PropTypes.bool.isRequired
};

export default withTranslation()(PlanUpgrade);
