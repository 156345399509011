/* eslint-disable camelcase */
import { create } from "zustand";
import { SummaryType } from "types/summaries.ts";
import { getRequestWithToken } from "../sagas/api";

type SummaryStoreType = {
  getCandidateSummary: (candidateId: string, token: string) => {};
  summary: {
    isGeneratingSummary: boolean;
    content: string;
    answersUsed: number;
  };
  isLoading: boolean;
  error: string | null;
  reset: () => void;
}

const initialState = {
  summary: {
    isGeneratingSummary: false, // @TODO: get from firebase
    content: "",
    answersUsed: 0
  },
  isLoading: false,
  error: null
};

const useSummaryStore = create<SummaryStoreType>(set => ({
  ...initialState,
  getCandidateSummary: async (candidateId, token) => {
    try {
      set({ isLoading: true });

      const response = await getRequestWithToken({
        url: `/api/transcripts/candidate/${candidateId}/summary/`,
        token
      }) as {
        data: SummaryType
      };

      set({
        summary: {
          isGeneratingSummary: false,
          content: response?.data?.summary_content,
          answersUsed: response?.data?.answers_used
        },
        isLoading: false
      });
    } catch (err: any) {
      console.log(err);

      set({
        isLoading: false,
        error: err.message
      });
    }
  },
  reset: () => set({ ...initialState })
}));


export default useSummaryStore;
