
import React, { useMemo } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { get, isNull } from "lodash";
import { ClipLoader } from "react-spinners";
import { ALL_IDV_REJECTED_REASON, IDVStatusChip } from "configs/jobs/constants";

const IDVDocument = ({
  t,
  idvCheckData,
  countryName,
  handleDownloadClick = () => {},
  isIdvSkipped
}) => {
  const isDocumentProcessing =
    useMemo(() => (isNull(get(idvCheckData, "documents[0].document_type", null)) ||
    isNull(get(idvCheckData, "documents[0].images_id")) ||
    !idvCheckData?.documents) && !isIdvSkipped, [idvCheckData, isIdvSkipped]);

  if (isDocumentProcessing) {
    return (
      <div className="candidateanswer__section__response-idv__document-processing">
        <ClipLoader color="#BBC2C9" size={18} />
        <div className="idv-check__container-title">
          <div className="idv-check__container-title-wrapper">
            {t("job.candidate.overview.idv.document.processingTitle")}
            <strong>
              {t("job.candidate.overview.idv.document.processing")}
            </strong>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {
        idvCheckData?.documents?.map((document, index) => (
          <div
            key={`idv-document-${String(index)}`}
            className="candidateanswer__section__response-idv__response-item"
          >
            <div className="candidateanswer__section__response-idv__response-item__row title">
              <div className="candidateanswer__section__response-idv__response-item-title">
                {t("job.candidate.overview.idv.document.title")}
              </div>
              {IDVStatusChip[document.status]}
            </div>

            <div className="candidateanswer__section__response-idv__response-item__row">
              <span>
                {
                  `${
                      document?.document_type === "n/a" || document?.document_type === null
                        ? t("job.candidate.overview.idv.document.skippedTitle")
                        : document?.document_type?.replace("_", " ")
                    }${countryName ? `: ${countryName}` : ""}`
                }
              </span>

            </div>

            <div className="candidateanswer__section__response-idv__response-item__row">
              {Boolean(document?.rejection_reason) && (
                <p>
                  {ALL_IDV_REJECTED_REASON?.includes(document?.rejection_reason)
                    ? t(`job.candidate.overview.idv.rejection_reason.${document?.rejection_reason}`)
                    : document?.rejection_reason}
                </p>
              )}
            </div>

            <div className="candidateanswer__section__response-idv__response-item__row">
              {document.images_id && !isDocumentProcessing ? (
                <button
                  type="button"
                  className="button__without-styles link_url"
                  onClick={() =>
                    handleDownloadClick("document", index)
                  }
                  aria-label="Download"
                >
                  {t("job.candidate.overview.idv.media")}
                </button>
              ) : null}
            </div>
          </div>
        ))
      }
    </>
  );
};
export default withTranslation()(IDVDocument);

IDVDocument.propTypes = {
  t: PropTypes.func.isRequired,
  idvCheckData: PropTypes.shape({
    documents: PropTypes.array
  }),
  isIdvSkipped: PropTypes.bool,
  handleDownloadClick: PropTypes.func,
  countryName: PropTypes.string
};

IDVDocument.defaultProps = {
  idvCheckData: {},
  isIdvSkipped: false,
  handleDownloadClick: () => {},
  countryName: ""
};
