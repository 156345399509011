import React, { useState } from "react";
import PropTypes from "prop-types";
import CandidateVideoIcon from "assets/images/candidate/CandidateVideoIcon";
import { formatStringtoDuration } from "../../../../../mixins/helperVideoRecording";

const CandidateThumbnailVideo = ({ answer, ...iconProps }) => {
  const [imageClass, setImageClass] = useState("");

  const handleImageLoad = e => {
    const { naturalWidth, naturalHeight } = e.target;
    const orientationClass = naturalHeight > naturalWidth ? "portrait" : "landscape";
    setImageClass(orientationClass);
  };


  return (
    <div>
      {
        answer?.thumbnail_remote_link ? (
          <img
            className={`candidateanswer__section__thumbnail__item__image ${imageClass}`}
            src={answer?.thumbnail_remote_link}
            alt="thumbnail"
            onLoad={handleImageLoad}
          />
        ) : <CandidateVideoIcon {...iconProps} />
      }
      {
        answer?.media_duration ? (
          <div
            className="candidatethumbnail__item__header-filechip"
            style={{
              background: "#FFFFFF",
              color: "#595959"
            }}
          >
            {formatStringtoDuration(answer?.media_duration)}
          </div>
        ) : null
      }
    </div>
  );
};

export default CandidateThumbnailVideo;

CandidateThumbnailVideo.defaultProps = {
  iconProps: {}
};

CandidateThumbnailVideo.propTypes = {
  answer: PropTypes.shape({
    thumbnail_remote_link: PropTypes.string,
    html_text: PropTypes.string,
    text: PropTypes.string,
    question: PropTypes.shape({
      html_text: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      answer_type: PropTypes.string.isRequired
    }).isRequired,
    media_duration: PropTypes.string.isRequired
  }).isRequired,
  iconProps: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number
  })
};
