import React, { ReactNode, useState } from "react";

type CustomTooltipProps = {
  content: ReactNode;
  children: ReactNode;
}

const CustomTooltip = ({ content, children }: CustomTooltipProps) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  return (
    <div className="relative inline-block">
      <div
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
      >
        {children}
      </div>
      {isVisible && (
        <div className="absolute z-10 w-56 lg:w-64 p-2 lg:p-4 top-full left-0 mt-2 bg-white rounded-lg shadow-lg border border-gray-200">
          <div className="text-sm text-gray-700">
            {content}
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomTooltip;
