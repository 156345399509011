import PropTypes from "prop-types";
import React, { useState } from "react";

const TranscriptionIcon = ({ fill }) => {
  const [mouseOver, setMouseOver] = useState(false);

  fill = mouseOver ? "#5A2AF1" : fill;

  return (
    <svg
      width="18"
      height="24"
      viewBox="0 0 18 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onMouseEnter={() => {
        setMouseOver(true);
      }}
      onMouseLeave={() => setMouseOver(false)}
    >
      <path d="M8.75294 0.175C8.78824 0.07 8.89412 0 9.0353 0C9.14118 0 9.24706 0.07 9.28235 0.175L9.77647 1.505L11.1176 1.995C11.2235 2.03 11.2941 2.135 11.2941 2.24C11.2941 2.38 11.2235 2.485 11.1176 2.52L9.77647 3.01L9.28235 4.305C9.24706 4.41 9.14118 4.48 9.0353 4.48C8.89412 4.48 8.78824 4.41 8.75294 4.305L8.25882 3.01L6.91765 2.52C6.81177 2.485 6.77647 2.38 6.77647 2.24C6.77647 2.135 6.81177 2.03 6.91765 1.995L8.25882 1.505L8.75294 0.175ZM0.247059 4.13L2.25882 3.36L3 1.4C3.03529 1.225 3.21176 1.12 3.38824 1.12C3.52941 1.12 3.70588 1.225 3.74118 1.4L4.51765 3.36L6.49412 4.13C6.67059 4.165 6.77647 4.34 6.77647 4.48C6.77647 4.655 6.67059 4.83 6.49412 4.865L4.51765 5.6L3.74118 7.595C3.70588 7.735 3.52941 7.84 3.38824 7.84C3.21176 7.84 3.03529 7.735 3 7.595L2.25882 5.6L0.247059 4.865C0.0705883 4.83 0 4.655 0 4.48C0 4.34 0.0705883 4.165 0.247059 4.13ZM11.4706 8.29L13.4824 7.52L14.2235 5.56C14.2588 5.385 14.4353 5.28 14.6118 5.28C14.7529 5.28 14.9294 5.385 14.9647 5.56L15.7412 7.52L17.7176 8.29C17.8941 8.325 18 8.5 18 8.64C18 8.815 17.8941 8.99 17.7176 9.025L15.7412 9.76L14.9647 11.755C14.9294 11.895 14.7529 12 14.6118 12C14.4353 12 14.2588 11.895 14.2235 11.755L13.4824 9.76L11.4706 9.025C11.2941 8.99 11.2235 8.815 11.2235 8.64C11.2235 8.5 11.2941 8.325 11.4706 8.29Z" fill={fill} />
      <path d="M12.3818 13.5C12.825 13.5 13.2 13.8609 13.2 14.2875V15.6C13.2 18.5203 10.9159 20.9484 8.01818 21.3422V22.425H9.65455C10.0977 22.425 10.4727 22.7859 10.4727 23.2125C10.4727 23.6719 10.0977 24 9.62046 24H4.71136C4.26818 24 3.92727 23.6063 3.92727 23.2125C3.92727 22.7859 4.26818 22.425 4.71136 22.425H6.38182V21.2766C3.38182 20.8828 1.2 18.2578 1.2 15.3375V14.2875C1.2 13.8609 1.54091 13.5 2.01818 13.5C2.46136 13.5 2.83636 13.8609 2.83636 14.2875V15.4359C2.83636 17.6344 4.47273 19.5703 6.75682 19.8C9.34773 20.0297 11.5636 18.0609 11.5636 15.6V14.2875C11.5636 13.8609 11.9045 13.5 12.3818 13.5ZM7.2 18.75C5.39318 18.75 3.92727 17.3391 3.92727 15.6V10.35C3.92727 8.61094 5.39318 7.2 7.2 7.2C9.00682 7.2 10.4727 8.64375 10.4727 10.35V15.6C10.4727 17.3391 9.00682 18.75 7.2 18.75ZM5.56364 10.35V15.6C5.56364 16.4859 6.27955 17.175 7.2 17.175C8.08636 17.175 8.83636 16.4859 8.83636 15.6V10.35C8.83636 9.49688 8.08636 8.775 7.2 8.775C6.27955 8.775 5.56364 9.49688 5.56364 10.35Z" fill={fill} />
    </svg>
  );
};
TranscriptionIcon.propTypes = {
  fill: PropTypes.string.isRequired
};

export default TranscriptionIcon;
