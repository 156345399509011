/* eslint-disable consistent-return */
import React, { useMemo } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { Question } from "mixins/svgIcons";
import { ANSWER_TYPES } from "configs/jobs/constants";

import { useInView } from "react-intersection-observer";
import { Video } from "../svgIcons";


const Item = ({
  t,
  questionText,
  questionNumber,
  source,
  onlyAudio,
  isCurrent,
  setCurrentQuestion,
  buttonColor,
  duration = 0
}) => {
  const videoId = `thumbnail-item-${questionNumber}`;

  const time = useMemo(() => {
    if (!duration) return "";
    const seconds = Math.floor(duration % 60);
    const minutes = Math.floor((duration - seconds) / 60);
    return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  }, [duration]);


  return (
    <div
      role="presentation"
      className={`preview__list-item ${isCurrent && "current"}`}
      onClick={setCurrentQuestion}
      style={isCurrent && buttonColor ? { background: `${buttonColor}20` } : null}
    >
      <div
        className="preview__list-item-type"
      >
        {onlyAudio ? (
          <div className="preview__list-item-type-audio">
            <i className={`fa n-grey ${ANSWER_TYPES.audio.icon}`}>
              {ANSWER_TYPES.audio.reactIcon}
            </i>
          </div>
        ) : (
          <>
            <Video />
            <video
              src={source?.src}
              controls={false}
              volume={1}
              muted
              preload="metadata"
              id={videoId}
            />
          </>
        )}
        {duration ? (
          <p className="preview__list-item-type-duration n-black-50 n-font-extra-small">{time}</p>
        ) : null}
      </div>
      <div className="preview__list-item-info">
        <p className="preview__list-item-info-subtitle n-grey-100 n-font-small flex flex-row" id="candidate-thumbnail-answer">

          <Question svgIconClass="preview__list-item-info-icon" />
          <span>{`${t("candidate.common.question")} ${questionNumber}`}</span>
        </p>

        <p
          className="preview__list-item-info-title n-grey-300 n-font-small"
          title={questionText}
        >
          {questionText}
        </p>
      </div>
    </div>
  );
};

Item.defaultProps = {
  duration: undefined,
  buttonColor: ""
};

Item.propTypes = {
  t: PropTypes.func.isRequired,
  source: PropTypes.shape({
    src: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired
  }).isRequired,
  questionText: PropTypes.string.isRequired,
  questionNumber: PropTypes.number.isRequired,
  onlyAudio: PropTypes.bool.isRequired,
  isCurrent: PropTypes.bool.isRequired,
  setCurrentQuestion: PropTypes.func.isRequired,
  buttonColor: PropTypes.string,
  duration: PropTypes.number
};

const ItemWrapper = props => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    fallbackInView: true
  });

  return (
    <div ref={ref}>
      {
        inView ? <Item {...props} /> : (
          <div style={{
            height: 200
          }}
          />
        )
      }
    </div>

  );
};

export default withTranslation()(ItemWrapper);
