import { BigDiamondsIcon } from "mixins/svgIcons";
import React, { FC } from "react";
import { WithTranslation, withTranslation } from "react-i18next";

const CandidateSidebarUpsell: FC<WithTranslation> = ({
  t
}) => {
  const handleUpgrade = () => {
    const message = {
      subject: t("upsell.beacon.subject"),
      text: t("upsell.beacon.subject")
    };

    if (window.Beacon) {
      window.Beacon("open");
      window.Beacon("navigate", "/ask/message/");
      window.Beacon("prefill", message);
    }
  };

  return (
    <div className="flex flex-col justify-around items-center upsell__container h-full">
      <div className="pb-8">
        <BigDiamondsIcon />
      </div>

      <div className="upsell__text-container pb-8 justify-center items-center">
        <div className="title flex flex-row gap-1 items-center justify-center">
          {t("upsell.unlockFeatures.0")}
          <span className="premium">{t("upsell.unlockFeatures.1")}</span>
        </div>
        <div className="title">{t("upsell.unlockFeatures.2")}</div>
        <div className="subtitle">{t("upsell.getAIFeatures")}</div>
        <button
          type="button"
          className="button__without-styles upgrade"
          onClick={handleUpgrade}
          aria-label="Upgrade Now"
        >
          {t("upsell.upgrade")}
        </button>
      </div>
    </div>
  );
};
export default withTranslation()(CandidateSidebarUpsell);
