import React from "react";
import { connect } from "react-redux";
import { NoCommentsIcon, SidebarCloseIcon } from "mixins/svgIcons";
import { getIsJobCandidateLoading } from "store/modules/jobs/selectors";
import { BeatLoader } from "react-spinners";
import { withTranslation } from "react-i18next";
import { store } from "store";
import { setSidebarOpen } from "store/modules/jobs/actions";
import CandidateSidebarCommentItem from "./CandidateSidebarCommentItem";
import CandidateSidebarAddComment from "./CandidateSidebarAddComment";

const CandidateSidebarComments = ({
  t,
  comments,
  isJobCandidateLoading,
  isCommentsLoading
}) => {
  const hasComments = comments.length > 0;

  return (
    <div className={`flex flex-col h-full ${!(isJobCandidateLoading || isCommentsLoading) && !hasComments ? "bg-black-5" : "bg-white"}`}>
      <div className="candidate__sidebar-comments flex flex-col flex-1">
        <div className="candidate__sidebar-comments__header flex w-full items-center justify-between">
          <div className="flex flex-row items-center gap-4">
            <span className="text-base text-black-90">{t("job.candidate.comments.title")}</span>
          </div>

          <SidebarCloseIcon
            style={{ pointerEvents: "all", cursor: "pointer" }}
            onClick={() => store.dispatch(setSidebarOpen(false))}
          />
        </div>

        {
          isJobCandidateLoading || isCommentsLoading ? (
            <div className="flex flex-col justify-center items-center flex-1">
              <BeatLoader color="#5A2AF1" size={10} />
              <span className="loading-text">{t("button.loading")}</span>
            </div>
          ) : (
            <>
              <CandidateSidebarAddComment hasComments={hasComments} />
              <div className="candidate__sidebar-commentlist">
                {hasComments ? (
                comments?.map(comment => (
                  <CandidateSidebarCommentItem key={comment.key} comment={comment} />
                ))
                ) : (
                  <div className="flex flex-col items-center justify-around gap-8 flex-1">
                    <div><NoCommentsIcon /></div>
                    <span className="text-xs">{t("comments.noComment")}</span>
                  </div>
                )}
              </div>
            </>
          )
        }
      </div>

      <div className="flex flex-col sticky bottom-0">
        <div className="h-5 bg-gradient-to-t from-white to-transparent" />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  comments: state.sidebar.comments ?? [],
  isJobCandidateLoading: getIsJobCandidateLoading(state),
  isCommentsLoading: state.sidebar.isLoading
});

export default connect(mapStateToProps)(withTranslation()(CandidateSidebarComments));
