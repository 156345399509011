import React from "react";
import "./styles.scss";
import { connect } from "react-redux";
import useTranscrpitionStore from "store/transcriptionStore.ts";
import { getCandidateAnswers } from "store/modules/jobs/selectors";
import CandidateTranscriptionItem from "./CandidateTranscriptionItem.tsx";

const Transcript = ({ currentQuestionAnswer }) => {
  const { transcriptions } = useTranscrpitionStore();
  const currentTranscription = transcriptions?.find(a => a.key === currentQuestionAnswer?.key);
  return (
    <div className="transcript-content">
      {
      currentTranscription?.sentences?.map(transcription => (
        <CandidateTranscriptionItem transcription={transcription} />
      ))
      }
    </div>
  );
};

const mapStateToProps = state => ({
  currentQuestionAnswer: state?.jobsCandidate?.currentQuestionAnswer,
  candidateAnswers: getCandidateAnswers(state)
});
export default connect(mapStateToProps)(Transcript);
