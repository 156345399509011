import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import QRCode from "react-qr-code";
import { withTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";

import { copyToClipboard } from "mixins/helpers";
import TooltipInfo from "components/Common/Tooltip/Info";
import TooltipSuccess from "components/Common/Tooltip/Success";

import { ChevronLeft, ChevronRight } from "mixins/svgIcons";
import { Mobile, Copy } from "./svgIcons";
import "./styles.scss";

const Offshoot = ({
  t,
  userId,
  isRecording,
  isShareCode = false
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const link = `${window.location.origin}/candidate/transferred-loading/${userId}?isShareCode=${isShareCode}`;
  const wrapper = useRef();
  const buttonRef = useRef(null);
  const tooltipTimeout = useRef(null);

  const handleCopy = () => {
    copyToClipboard(link);
    clearTimeout(tooltipTimeout.current);
    setShowTooltip(true);

    tooltipTimeout.current = setTimeout(() => {
      setShowTooltip(false);
    }, 1500);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapper.current && !wrapper.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapper]);

  return isOpen ? (
    <div className="question-offshoot question-offshoot-open" ref={wrapper}>
      <p className="n-font-extra-smal n-font-semi-bold n-purple-100">
        {t("offshoot.havingTrouble")}
        <div
          className="question-offshoot-arrowright"
          role="presentation"
          onClick={() => setIsOpen(false)}
          data-testid="InterviewOffshoot-Btn-CloseOffshoot_Btn"
          aria-label="Close Offshoot"
        >
          <ChevronRight />
        </div>
      </p>
      <p className="n-font-extra-smal n-black-80">
        {t("offshoot.scan")}
      </p>
      <div className="question-offshoot-qr">
        <QRCode value={link} size={100} />
      </div>
      <div
        role="presentation"
        onClick={handleCopy}
        className="n-font-tiny n-black-100"
        ref={buttonRef}
        data-testid="InterviewOffshoot-Btn-CopyLink_Btn"
        aria-label="Copy Offshoot Link"
      >
        {t("offshoot.copy")}
        <Copy />
      </div>
      <TooltipSuccess
        position="top"
        message={t("job.invite.inviteOne.buttonCopiedToClipboard")}
        target={buttonRef.current}
        show={showTooltip}
      />
    </div>
  ) : (
    <TooltipInfo
      position="top"
      message={isRecording ? t("offshoot.finishRecording") : t("offshoot.transfer")}
      {...isMobile ? { trigger: [] } : null}
    >
      <div
        role="presentation"
        className={`question-offshoot question-offshoot-close ${isRecording || isMobile ? "" : "enabled"}`}
        onClick={isRecording ? null : () => setIsOpen(true)}
        data-testid="InterviewOffshoot-Btn-OpenOffshoot_Btn"
        aria-label="Open Offshoot"
      >
        <ChevronLeft />
        <Mobile height="25" width="20" />
      </div>
    </TooltipInfo>
  );
};

Offshoot.defaultProps = {
  isShareCode: false,
  isRecording: true
};

Offshoot.propTypes = {
  t: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  isRecording: PropTypes.bool,
  isShareCode: PropTypes.bool
};

export default withTranslation()(Offshoot);
