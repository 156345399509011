import React from "react";
import { withTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import { Formik } from "formik";
import PropTypes from "prop-types";

import {
  initialStateForAllInvite,
  fileFormForAllInvite,
  handleClassName
} from "mixins/helperJobInvites";

import MoreInfo from "./MoreInfo";

const InviteAllCandidates = ({ t, jobKey, sendSpreadsheet }) => (
  <Formik
    enableReinitialize
    initialValues={initialStateForAllInvite}
    validationSchema={fileFormForAllInvite()}
  >
    {({ values, errors, handleSubmit, setFieldValue }) => (
      <form onSubmit={handleSubmit}>
        <div className="form-invite-all">
          <div className="invite-all">
            <Row noGutters>
              <Col md={12}>
                <div className="form-candidates__divider">
                  <span className="form-candidates__divider-left-line" />
                  <span className="form-candidates__divider-text">{t("job.invite.or")}</span>
                  <span className="form-candidates__divider-right-line" />
                </div>
              </Col>

              <Col md={12}>
                <h2 className="invite-all__title">
                  {t("job.invite.inviteMany.title")}
                </h2>
                <p className="invite-all__text">
                  {t("job.invite.inviteMany.text")}
                </p>
              </Col>

              <Col md={{ span: 6, offset: 3 }}>
                <div className="input">
                  <div
                    className={handleClassName(
                      "input-upload-wrapper",
                      errors.fileName
                    )}
                  >
                    <p className="input-upload__file-name">
                      {values.fileName && values.fileName.name}
                    </p>

                    <label
                      htmlFor="invite-all-input"
                      className="
                        n-button__small
                        n-bg-purple-100
                        n-white
                      "
                    >
                      <input
                        id="invite-all-input"
                        type="file"
                        name="fileName"
                        accept=".csv, .txt"
                        onChange={({ currentTarget: { files = "" } }) => {
                          setFieldValue("fileName", files[0]);
                          sendSpreadsheet({
                            key: jobKey,
                            file: files[0]
                          });
                        }}
                      />
                      {t("button.browse")}
                    </label>

                    {errors.fileName && (
                      <p className="input-error-message">{errors.fileName}</p>
                    )}
                  </div>
                </div>
              </Col>

              <Col md={12}>
                <p className="invite-all-input__caption flex flex-row items-center justify-center">
                  {t("job.invite.inviteMany.info")}
                  <span className="invite-all-input__caption-info flex flex-row items-center justify-center">
                    {t("job.invite.inviteMany.more")}
                    <MoreInfo />
                  </span>
                </p>
              </Col>
            </Row>
          </div>
        </div>
      </form>
    )}
  </Formik>
);

InviteAllCandidates.defaultProps = {
  sendSpreadsheet: undefined,
  jobKey: undefined
};

InviteAllCandidates.propTypes = {
  t: PropTypes.func.isRequired,
  sendSpreadsheet: PropTypes.func,
  jobKey: PropTypes.string
};

export default withTranslation()(InviteAllCandidates);
