/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from "prop-types";
import React, { useMemo, useRef, useState } from "react";
import "./index.scss";
import { deleteComment, updateCachedComment } from "store/modules/jobs/actions";
import { store } from "store";
import { useClickAway } from "react-use";
import { IoEllipsisVertical } from "react-icons/io5";
import ReactHtmlParser from "react-html-parser";
import { BeatLoader } from "react-spinners";
import { withTranslation } from "react-i18next";
import { computeTimePassed, getAvatarName } from "mixins/helpers";
import { DeleteTrashIcon, EditPencilIcon } from "../../svgIcons";
import CandidateSidebarAddComment from "./CandidateSidebarAddComment";

const CandidateSidebarCommentItem = ({ t, comment }) => {
  const userId = store.getState()?.jobsCandidate?.currentCandidateJob?.key;
  const [isEditing, setIsEditing] = useState(false);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useClickAway(menuRef, () => {
    setIsMenuOpen(false);
  });

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleEdit = () => {
    store.dispatch(updateCachedComment(comment));
    setIsEditing(true);
    setIsMenuOpen(false);
  };

  const handleDelete = () => {
    store.dispatch(deleteComment({
      userId,
      commentId: comment.key
    }));
    setIsMenuOpen(false);
  };

  const authorName = useMemo(() => {
    if (comment?.author_name) {
      const [first, last] = comment.author_name.split(" ");
      return `${first.charAt(0)}. ${last}`;
    }

    return "";
  }, [comment.author_name]);

  if (isEditing) {
    return (
      <CandidateSidebarAddComment
        id={comment?.key}
        handleCancel={() => setIsEditing(false)}
      />
    );
  }

  if (comment?.isLoading) {
    return (
      <div className="candidate__comment-item flex-col">
        <div className="flex flex-row gap-2 items-center">
          <div
            className="users__user-list-item-info-avatar candidate__comment-item-avatar"
          >
            <p className="n-font-extra-small n-font-medium-weight n-white n-uppercase">
              {getAvatarName(comment.author_name, comment.author_email)}
            </p>
          </div>
          <div className="candidate__comment-container">
            <div className="candidate__comment-header">
              <div className="flex flex-row gap-2">
                <span>{authorName}</span>
                <span>{computeTimePassed(comment.updated_at)}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col justify-center items-center flex-1">
          <BeatLoader color="#5A2AF1" size={5} />
        </div>
      </div>
    );
  }

  return (
    <div className="candidate__comment-item">
      <div
        className="users__user-list-item-info-avatar candidate__comment-item-avatar"
      >
        <p className="n-font-extra-small n-font-medium-weight n-white n-uppercase">
          {getAvatarName(comment.author_name, comment.author_email)}
        </p>
      </div>
      <div className="candidate__comment-container">
        <div className="candidate__comment-header">
          <div className="flex flex-col flex-wrap gap-2">
            <p className="font-medium author">{authorName}</p>
            <span>{computeTimePassed(comment.updated_at)}</span>
          </div>
          <div className="relative flex flex-col" ref={menuRef}>
            <div className="flex justify-end">
              <button
                onClick={toggleMenu}
                className="p-1 button__without-styles toggle-menu"
                type="button"
                aria-label="Open Menu"
              >
                <IoEllipsisVertical size={16} />
              </button>
            </div>
            {isMenuOpen && (
              <div className="absolute mt-1 bg-white toggle-menu__content flex flex-col">
                <button
                  onClick={handleEdit}
                  className="flex items-center gap-2 w-full px-3 py-2 transition-colors duration-200 ease-in-out button__without-styles toggle-menu__content-item edit"
                  type="button"
                  aria-label="Edit"
                >
                  <div className="w-5 h-5 flex items-center justify-center"><EditPencilIcon /></div>
                  <span>{t("button.edit")}</span>
                </button>

                <button
                  onClick={handleDelete}
                  className="flex items-center gap-2 w-full px-3 py-2 transition-colors duration-200 ease-in-out button__without-styles toggle-menu__content-item delete"
                  type="button"
                  aria-label="Delete"
                >
                  <div className="w-5 h-5 flex items-center justify-center"><DeleteTrashIcon /></div>
                  <span>{t("button.delete")}</span>
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="candidate__comment-text text-sm text-main-link">
          {ReactHtmlParser(comment?.text.replace(/\n/g, "<br>"))}
        </div>
      </div>
    </div>
  );
};

CandidateSidebarCommentItem.propTypes = {
  comment: PropTypes.shape({
    author_email: PropTypes.string,
    author_name: PropTypes.string,
    key: PropTypes.string,
    text: PropTypes.string,
    updated_at: PropTypes.string
  }).isRequired
};

export default withTranslation()(CandidateSidebarCommentItem);
