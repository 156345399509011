import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  saveAnswer,
  saveIsAllAnswerSaved
} from "store/modules/сandidates/actions";
import { getErrorMessage } from "store/modules/сandidates/selectors";
import IdvCompleted from "./IdvCompleted";

const mapStateToProps = state => ({
  state,
  error: getErrorMessage(state)
});

const mapDispatchToProps = {
  saveAnswer,
  saveIsAllAnswerSaved
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(IdvCompleted));
