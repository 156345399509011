import { NoTranscriptionIcon } from "mixins/svgIcons";
import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

const NoTranscriptionsHistory = ({
  t,
  isEnabled = false
}: {
  t: (value: string) => string,
  isEnabled: boolean
}) => {
  const handleTranscribeNow = () => {
    console.log("Transcribe Now", t);
  };

  return (
    <div className="no-transcriptions__container">
      <div className="no-transcriptions__icon">
        <NoTranscriptionIcon />
      </div>
      <span className="no-transcriptions__text">
        {t("transcriptions.noTranscriptions")}
      </span>
      {
        isEnabled ? (
          <button
            type="button"
            onClick={handleTranscribeNow}
            className="no-transcriptions__transcribe button__without-styles"
            aria-label="Transcribe Now"
          >
            {t("transcriptions.transcribeNow")}
          </button>
        ) : null
      }
    </div>
  );
};

NoTranscriptionsHistory.propTypes = {
  t: PropTypes.func.isRequired,
  isEnabled: PropTypes.bool
};

NoTranscriptionsHistory.defaultProps = {
  isEnabled: false
};

export default withTranslation()(NoTranscriptionsHistory);
