import PropTypes from "prop-types";
import React, { useState } from "react";

const CommentsIcon = ({ fill }) => {
  const [mouseOver, setMouseOver] = useState(false);

  fill = mouseOver ? "#5A2AF1" : fill;
  return (
    <svg
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onMouseEnter={() => {
        setMouseOver(true);
      }}
      onMouseLeave={() => setMouseOver(false)}
    >
      <path d="M11.0215 0C17.092 0 22 4.24107 22 9.33036C22 14.4196 17.092 18.5714 11.0215 18.5714C9.60078 18.5714 8.22309 18.3482 7.01761 17.9464C5.72603 18.8839 3.65949 20 1.03327 20C0.60274 20 0.215264 19.7768 0.0861057 19.3304C-0.0430528 18.9286 0 18.4821 0.30137 18.1696C0.30137 18.1696 1.63601 16.6518 2.2818 14.9107C0.861057 13.3482 0 11.3839 0 9.28571C0 4.15179 4.90802 0 11.0215 0ZM11.0215 17.1429C16.317 17.1429 20.5793 13.6161 20.5793 9.28571C20.5793 4.95536 16.274 1.42857 10.9785 1.42857C5.68297 1.42857 1.37769 4.95536 1.37769 9.28571C1.37769 10.9821 2.02348 12.5893 3.27202 13.9732C3.61644 14.4196 3.7456 14.9554 3.53033 15.4464C3.05675 16.7411 2.2818 17.9018 1.76517 18.5714C3.70254 18.3482 5.25245 17.5 6.19961 16.7857C6.54403 16.5179 7.01761 16.4732 7.40509 16.6071C8.56751 16.9643 9.77299 17.1429 11.0215 17.1429ZM14.8532 4.91071L15.1977 5.3125C15.6282 5.71429 15.8004 6.25 15.7573 6.83036C15.7143 7.32143 15.499 7.76786 15.1546 8.08036L9.8591 13.5714C9.77299 13.6607 9.64384 13.7054 9.51468 13.75L6.88845 14.2857C6.37182 14.375 5.98434 13.9732 6.07045 13.4821L6.58708 10.7589C6.63014 10.625 6.67319 10.4911 6.7593 10.4018L12.1409 4.82143C12.5284 4.41964 13.0881 4.24107 13.6477 4.28571C14.1213 4.33036 14.5088 4.55357 14.8532 4.91071ZM9.0411 12.4107L12.7867 8.57143L11.6243 7.36607L7.87867 11.25L7.62035 12.6786L9.0411 12.4107ZM14.2505 7.05357C14.4227 6.83036 14.4227 6.51786 14.2505 6.29464L13.82 5.89286C13.6477 5.66964 13.3033 5.66964 13.0881 5.89286L12.6145 6.38393L13.7339 7.54464L14.2505 7.05357Z" fill={fill} />
    </svg>

  );
};

CommentsIcon.propTypes = {
  fill: PropTypes.string.isRequired
};

export default CommentsIcon;
