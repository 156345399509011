import { createAction } from "redux-actions";

export const isJobsLoading = createAction("IS_JOBS_LOADING");
export const isLatestJobsLoading = createAction("IS_LATEST_JOBS_LOADING");
export const isSendingUploading = createAction("IS_SENDING_UPLOADING");
export const isCommentsLoading = createAction("IS_COMMENTS_LOADING");
export const isJobCandidateLoading = createAction("IS_JOB_CANDIDATE_LOADING");
export const isCommentUpdating = createAction("IS_COMMENT_UPDATING");

export const isCandidatesStatsLoading = createAction(
  "IS_CANDIDATE_STATS_LOADING"
);
export const isNextCandidatesLoading = createAction(
  "IS_NEXT_CANDIDATES_LOADING"
);
export const isJobStatsLoading = createAction("IS_JOB_STATS_LOADING");

export const setFormSetupWasEdit = createAction("IS_FORM_SETUP_WAS_EDIT");
export const setFormQuestionsWasEdit = createAction(
  "IS_FORM_QUESTIONS_WAS_EDIT"
);

export const clearJobs = createAction("CLEAR_JOBS");
export const fetchJobs = createAction("FETCH_JOBS");
export const fetchLatestJobs = createAction("FETCH_LATEST_JOBS");
export const fetchJobsResponse = createAction("FETCH_JOBS_RESPONSE");
export const fetchLatestJobsResponse = createAction(
  "FETCH_LATEST_JOBS_RESPONSE"
);
export const fetchJobsError = createAction("FETCH_JOBS_ERROR");
export const fetchLatestJobsError = createAction("FETCH_LATEST_JOBS_ERROR");

export const restoreJob = createAction("RESTORE_JOB");
export const restoreJobResponse = createAction("RESTORE_JOB_RESPONSE");
export const drestoreJobError = createAction("RESTORE_JOB_ERROR");

export const deleteJob = createAction("DELETE_JOB");
export const deleteJobResponse = createAction("DELETE_JOB_RESPONSE");
export const deleteJobError = createAction("DELETE_JOB_ERROR");

export const fetchCompaniesForQuestion = createAction(
  "FETCH_COMPANIES_FOR_QUESTION"
);
export const fetchCompaniesForQuestionResponse = createAction(
  "FETCH_COMPANIES_FOR_QUESTION_RESPONSE"
);
export const fetchCompaniesForQuestionError = createAction(
  "FETCH_COMPANIES_FOR_QUESTION_ERROR"
);

export const fetchLanguagesForQuestion = createAction(
  "FETCH_LANGUAGES_FOR_QUESTION"
);
export const fetchLanguagesForQuestionResponse = createAction(
  "FETCH_LANGUAGES_FOR_QUESTION_RESPONSE"
);
export const fetchLanguagesForQuestionError = createAction(
  "FETCH_LANGUAGES_FOR_QUESTION_ERROR"
);

export const fetchIndustries = createAction("FETCH_INDUSTRY");
export const fetchIndustriesResponse = createAction("FETCH_INDUSTRY_RESPONSE");

export const fetchCurrentJob = createAction("FETCH_CURRENT_JOB");
export const fetchCurrentJobResponse = createAction(
  "FETCH_CURRENT_JOB_RESPONSE"
);
export const fetchCurrentJobError = createAction("FETCH_CURRENT_JOB_ERROR");

export const fetchUserCurrentJob = createAction("FETCH_USER_CURRENT_JOB");
export const fetchUserCurrentJobResponse = createAction(
  "FETCH_USER_CURRENT_JOB_RESPONSE"
);
export const fetchUserCurrentJobError = createAction(
  "FETCH_USER_CURRENT_JOB_ERROR"
);

export const sendComment = createAction("SEND_COMMENT");
export const sendCommentResponse = createAction("SEND_COMMENT_RESPONSE");
export const sendCommentError = createAction("SEND_COMMENT_ERROR");

export const updateComment = createAction("UPDATE_COMMENT");
export const updateCommentResponse = createAction("UPDATE_COMMENT_RESPONSE");
export const updateCommentError = createAction("UPDATE_COMMENT_ERROR");

export const updateCachedComment = createAction("UPDATE_CACHED_COMMENT");

export const deleteComment = createAction("DELETE_COMMENT");
export const deleteCommentResponse = createAction("DELETE_COMMENT_RESPONSE");
export const deleteCommentError = createAction("DELETE_COMMENT_ERROR");

export const createSetupInfo = createAction("CREATE_SETUP_INFO");
export const saveSetupInfo = createAction("SAVE_SETUP_INFO");
export const createQuestionsInfo = createAction("CREATE_QUESTIONS_INFO");

export const setJobSetupValid = createAction("SET_JOB_SETUP_VALID");
export const setJobQuestionsValid = createAction("SET_JOB_QUESTION_VALID");

export const saveJob = createAction("SAVE_JOB_START");
export const saveJobResponse = createAction("SAVE_JOB_RESPONSE");
export const updateJobResponse = createAction("UPDATE_JOB_RESPONSE");
export const saveJobError = createAction("SAVE_JOB_ERROR");
export const saveJobErrorWithFields = createAction("SAVE_JOB_ERROR_WITH_FIELDS");

export const duplicateJob = createAction("DUPLICATE_JOB");
export const duplicateJobResponse = createAction("DUPLICATE_JOB_RESPONSE");
export const duplicateJobSuccess = createAction("DUPLICATE_JOB_SUCCESS");
export const duplicateJobError = createAction("DUPLICATE_JOB_ERROR");

export const cashCurrentJob = createAction("CASH_CURRENT_JOB");
export const clearCurrentJob = createAction("CLEAR_CURRENT_JOB");

export const addJobInvite = createAction("ADD_JOB_INVITE");
export const updateJobInvite = createAction("UPDATE_JOB_INVITE");
export const deleteJobInvite = createAction("DELETE_JOB_INVITE");
export const setJobInvites = createAction("SET_JOB_INVITES");

export const sendInvites = createAction("SEND_INVITES");
export const sendInvitesResponse = createAction("SEND_INVITES_RESPONSE");
export const sendInvitesError = createAction("SEND_INVITES_ERROR");

export const fetchCandidatesList = createAction("FETCH_CANDIDATE_LIST");
export const fetchCandidatesListResponse = createAction(
  "FETCH_CANDIDATE_LIST_RESPONSE"
);
export const fetchCandidatesListError = createAction(
  "FETCH_CANDIDATE_LIST_ERROR"
);

export const fetchCandidatesByStatusList = createAction(
  "FETCH_CANDIDATES_BY_STATUS_LIST"
);
export const fetchCandidatesByStatusListResponse = createAction(
  "FETCH_CANDIDATES_BY_STATUS_LIST_RESPONSE"
);
export const fetchCandidatesByStatusListError = createAction(
  "FETCH_CANDIDATES_BY_STATUS_LIST_ERROR"
);
export const fetchCandidatesByStatusListReset = createAction(
  "FETCH_CANDIDATES_BY_STATUS_LIST_RESET"
);

export const fetchNextCandidatesList = createAction(
  "FETCH_NEXT_CANDIDATE_LIST"
);
export const fetchNextCandidatesListResponse = createAction(
  "FETCH_NEXT_CANDIDATE_LIST_RESPONSE"
);
export const fetchNextCandidatesListError = createAction(
  "FETCH_NEXT_CANDIDATE_LIST_ERROR"
);

export const fetchCandidateHistory = createAction("FETCH_CANDIDATE_HISTORY");
export const fetchCandidateHistoryLoading = createAction("FETCH_CANDIDATE_HISTORY_LOADING");
export const fetchCandidateHistoryResponse = createAction(
  "FETCH_CANDIDATE_HISTORY_RESPONSE"
);
export const fetchCandidateHistoryError = createAction(
  "FETCH_CANDIDATE_HISTORY_ERROR"
);

export const fetchCandidateAnswers = createAction("FETCH_CANDIDATE_ANSWERS");
export const fetchCandidateAnswersResponse = createAction(
  "FETCH_CANDIDATE_ANSWERS_RESPONSE"
);
export const fetchCandidateAnswersError = createAction(
  "FETCH_CANDIDATE_ANSWERS_ERROR"
);

export const fetchJobsStats = createAction("FETCH_JOBS_STATS");
export const fetchJobsStatsResponse = createAction("FETCH_JOBS_STATS_RESPONSE");
export const fetchJobsStatsError = createAction("FETCH_JOBS_STATS_ERROR");

export const fetchJobCandidatesStats = createAction(
  "FETCH_JOB_CANDIDATES_STATS"
);
export const fetchJobCandidatesStatsResponse = createAction(
  "FETCH_JOB_CANDIDATES_STATS_RESPONSE"
);
export const fetchJobCandidatesStatsError = createAction(
  "FETCH_JOB_CANIDATES_STATS_ERROR"
);

export const fetchJobCandidatesStatsFilterResponse = createAction(
  "FETCH_JOB_CANDIDATES_STATS_FILTER_RESPONSE"
);

export const updateCandidateStatus = createAction("UPDATE_CANDIDATE_STATUS");
export const updateCandidateStatusResponse = createAction(
  "UPDATE_CANDIDATE_STATUS_RESPONSE"
);
export const updateCandidateStatusError = createAction(
  "UPDATE_CANDIDATE_STATUS_ERROR"
);

export const deleteCandidate = createAction("DELETE_CANDIDATE");
export const deleteCandidateResponse = createAction(
  "DELETE_CANDIDATE_RESPONSE"
);
export const deleteCandidateResponseError = createAction(
  "DELETE_CANDIDATE_ERROR"
);
export const deleteCandidateFromInterviewersList = createAction(
  "DELETE_CANDIDATE_FROM_INTERVIEWERS_LIST"
);

export const updateCandidateRatingResponse = createAction(
  "UPDATE_CANDIDATE_RATING_RESPONSE"
);
export const updateCandidateRatingError = createAction(
  "UPDATE_CANDIDATE_RATING_ERROR"
);

export const sendSpreadsheet = createAction("SEND_SPREADSSHEET");
export const sendSpreadsheetResponse = createAction(
  "SEND_SPREADSSHEET_RESPONSE"
);
export const sendSpreadsheetError = createAction("SEND_SPREADSSHEET_ERROR");

export const clearStatuses = createAction("CLEAR_STATUSES");
export const clearSetupState = createAction("CLEAR_SETUP_STATE");
export const clearQuestionsState = createAction("CLEAR_QUESTION_STATE");
export const clearPreviewState = createAction("CLEAR_PREVIEW_STATE");
export const clearJobInvites = createAction("CLEAR_JOB_INVITES");
export const clearSuccessMessage = createAction("CLEAR_SUCCESS_MESSAGE");
export const clearErrors = createAction("CLEAR_ERRORS");
export const clearJobInvitesParseData = createAction(
  "CLEAR_JOB_INVITES_PARSE_DATA"
);

export const setVideoError = createAction("SET_VIDEO_ERROR");

export const fetchSuggestions = createAction("FETCH_SUGGESTIONS");
export const setSuggestions = createAction("SET_SUGGESTIONS");

export const updateCandidatesList = createAction("UPDATE_CANDIDATES_LIST");
export const downloadCandidatesList = createAction("DOWNLOAD_CANDIDATES_LIST");

export const sendReminderCandidate = createAction("SEND_REMINDER_CANDIDATE");

export const setSelectedCandidates = createAction("SET_SELECTED_CANDIDATES");
export const setSelectedCandidatesByStatus = createAction(
  "SET_SELECTED_CANDIDATES_BY_STATUS"
);
export const setSelectedCandidatesByStatusResponse = createAction(
  "SET_SELECTED_CANDIDATES_BY_STATUS_RESPONSE"
);
export const setSelectAll = createAction("SET_SELECT_ALL");
export const setExcludedCandidates = createAction("SET_EXCLUDED_CANDIDATES");
export const removeExcludedCandidates = createAction(
  "REMOVE_EXCLUDED_CANDIDATES"
);
export const setSelectedCandidatesLoading = createAction(
  "SET_SELECTED_CANDIDATES_LOADING"
);
export const setTotalSelectedCount = createAction("SET_TOTAL_SELECTED_COUNT");

export const createJob = createAction("CREATE_JOB");

export const fetchCandidateInformation = createAction(
  "FETCH_CANDIDATE_INFORMATION"
);
export const setCandidateInformationLoading = createAction(
  "SET_CANDIDATE_INFORMATION_LOADING"
);

export const fetchJobUsersList = createAction("FETCH_JOB_USERS_LIST");
export const fetchJobUsersListResponse = createAction(
  "FETCH_JOB_USERS_LIST_RESPONSE"
);
export const fetchJobUsersListError = createAction(
  "FETCH_JOB_USERS_LIST_ERROR"
);

export const fetchNextJobUsersList = createAction("FETCH_NEXT_JOB_USERS_LIST");
export const fetchNextJobUsersListResponse = createAction(
  "FETCH_NEXT_JOB_USERS_LIST_RESPONSE"
);
export const fetchNextJobUsersListError = createAction(
  "FETCH_NEXT_JOB_USERS_LIST_ERROR"
);

export const isJobUsersLoading = createAction("JOB_USERS_LOADING");
export const isNextJobUsersLoading = createAction("NEXT_JOB_USERS_LOADING");

export const updateJobUsersList = createAction("UPDATE_JOB_USERS_LIST");
export const updateJobUsersListResponse = createAction(
  "UPDATE_JOB_USERS_LIST_RESPONSE"
);

export const fetchJobsOwnersFilter = createAction("FETCH_JOB_OWNERS_FILTERS");
export const fetchJobsCompaniesFilter = createAction(
  "FETCH_JOB_COMPANIES_FILTERS"
);
export const fetchJobsStatusesFilter = createAction(
  "FETCH_JOB_STATUSES_FILTERS"
);
export const fetchJobsFilters = createAction("FETCH_JOBS_FILTERS");
export const fetchJobsFiltersResponse = createAction(
  "FETCH_JOBS_FILTERS_RESPONSE"
);
export const fetchJobsFiltersError = createAction("FETCH_JOBS_FILTERS_ERROR");
export const fetchJobsCompanyFilterResponse = createAction(
  "FETCH_JOBS_COMPANY_FILTERS"
);
export const fetchJobsOwnersFilterResponse = createAction(
  "FETCH_JOBS_OWNERS_FILTERS"
);
export const fetchJobsStatusesFilterResponse = createAction(
  "FETCH_JOBS_STATUSES_FILTERS"
);

export const setJobsFiltersLoading = createAction("SET_JOBS_FILTERS_LOADING");

export const fetchCandidateIdv = createAction("GET_CANDIDATE_IDV");
export const fetchCandidateIdvLoading = createAction(
  "GET_CANDIDATE_IDV_LOADING"
);
export const fetchCandidateIdvError = createAction("GET_CANDIDATE_IDV_ERROR");
export const fetchCandidateIdvResponse = createAction(
  "GET_CANDIDATE_IDV_RESPONSE"
);

export const fetchCandidateIdvDocumentImage = createAction(
  "GET_CANDIDATE_IDV_DOCUMENT_IMG"
);
export const fetchCandidateIdvDocumentImageLoading = createAction(
  "GET_CANDIDATE_IDV_DOCUMENT_IMG_LOADING"
);
export const fetchCandidateIdvDocumentImageError = createAction(
  "GET_CANDIDATE_IDV_DOCUMENT_IMG_ERROR"
);
export const fetchCandidateIdvDocumentImageResponse = createAction(
  "GET_CANDIDATE_IDV_DOCUMENT_IMG_RESPONSE"
);

export const fetchCandidateIdvIdentityImage = createAction(
  "GET_CANDIDATE_IDV_IDENTITY_IMG"
);
export const fetchCandidateIdvIdentityImageLoading = createAction(
  "GET_CANDIDATE_IDV_IDENTITY_IMG_LOADING"
);
export const fetchCandidateIdvIdentityImageError = createAction(
  "GET_CANDIDATE_IDV_IDENTITY_IMG_ERROR"
);
export const fetchCandidateIdvIdentityImageResponse = createAction(
  "GET_CANDIDATE_IDV_IDENTITY_IMG_RESPONSE"
);

export const fetchCandidateIdvPdf = createAction("GET_CANDIDATE_IDV_PDF");
export const fetchCandidateIdvPdfLoading = createAction(
  "GET_CANDIDATE_IDV_PDF_LOADING"
);
export const fetchCandidateIdvPdfError = createAction(
  "GET_CANDIDATE_IDV_PDF_ERROR"
);
export const fetchCandidateIdvPdfResponse = createAction(
  "GET_CANDIDATE_IDV_PDF_RESPONSE"
);

export const setIdvActivationRedirect = createAction(
  "SET_IDV_ACTIVATION_REDIRECT"
);
export const setCurrentStepActive = createAction("SET_CURRENT_STEP_ACTIVE");
export const setIsUpgradeComplete = createAction("SET_IS_UPGRADE_COMPLETE");

export const fetchCandidateAnswer = createAction("FETCH_CANDIDATE_ANSWER");

export const updateJobsListAtsDetails = createAction(
  "UPDATE_JOBS_LIST_ATS_DETAILS"
);

export const isJobAtsLoading = createAction("IS_JOB_ATS_LOADING");
export const resetJobAtsDetails = createAction("RESET_JOB_ATS_DETAILS");
export const getJobAtsDetails = createAction("GET_JOB_ATS_DETAILS");
export const getJobAtsDetailsResponse = createAction(
  "GET_JOB_ATS_DETAILS_RESPONSE"
);
export const updateAvailableAtsJobs = createAction("UPDATE_AVAILABLE_ATS_JOBS");
export const updateNextAvailableAtsJobs = createAction(
  "UPDATE_NEXT_AVAILABLE_ATS_JOBS"
);

export const getAvailableAtsJobStages = createAction(
  "GET_AVAILABLE_ATS_JOB_STAGES"
);
export const getAvailableAtsJobStagesLoading = createAction(
  "GET_AVAILABLE_ATS_JOB_STAGES_LOADING"
);
export const getAvailableAtsJobStagesResponse = createAction(
  "GET_AVAILABLE_ATS_JOB_STAGES_RESPONSE"
);

export const getNextAtsJobs = createAction("GET_NEXT_ATS_JOBS");
export const getNextAtsJobsLoading = createAction("GET_NEXT_ATS_JOBS_LOADING");
export const getNextAtsJobsResponse = createAction(
  "GET_NEXT_ATS_JOBS_RESPONSE"
);

export const updateConnectedAtsJobsAndStages = createAction(
  "UPDATE_CONNECTED_ATS_JOBS"
);
export const subscribeToAtsJob = createAction("SUBSCRIBE_TO_ATS_JOB");
export const subscribeToAtsJobLoading = createAction(
  "SUBSCRIBE_TO_ATS_JOB_LOADING"
);
export const subscribeToAtsJobResponse = createAction(
  "SUBSCRIBE_TO_ATS_JOB_RESPONSE"
);

export const filterAtsJobs = createAction("FILTER_ATS_JOBS");
export const filterAtsJobsLoading = createAction("FILTER_ATS_JOBS_LOADING");
export const filterAtsJobsResponse = createAction("FILTER_ATS_JOBS_RESPONSE");
export const resetFilterAtsJobs = createAction("RESET_FILTER_ATS_JOBS");

export const saveCandidatesExportLink = createAction("SAVE_CANDIDATES_EXPORT_LINK");

export const setCurrentQuestionAnswer = createAction(
  "SET_CURRENT_QUESTION_ANSWER"
);
export const setCurrentAnswerDuration = createAction(
  "SET_CURRENT_ANSWER_DURATION"
);
export const setCurrentAnswerStatus = createAction(
  "SET_CURRENT_ANSWER_STATUS"
);

export const updateCurrentJobQuestions = createAction("UPDATE_CURRENT_JOB_QUESTIONS");
export const setSidebarOpen = createAction("SET_SIDEBAR_OPEN");
