import { DBS_RTW_TYPE, RTW_COUNTRIES, RTW_TYPE } from "configs/jobs/constants";
import { copyToClipboard } from "mixins/helpers";
import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Copy from "components/Tables/Jobs/Buttons/Copy";
import { CopyTable } from "mixins/svgIcons";

const IDVShareCode = ({
  t,
  idvCheckData,
  isOverAllStatusSkipped,
  currentQuestionAnswer
}) => {
  const [copied, setCopied] = useState("");
  const [isHoveringIcon, setIsHoveringIcon] = useState(false);

  const shouldShowShareCode =
    [RTW_TYPE, DBS_RTW_TYPE].includes(idvCheckData?.represent_as) &&
    !RTW_COUNTRIES.includes(currentQuestionAnswer?.idv_citizenship_iso_code) &&
    !isOverAllStatusSkipped;

  const handleCopy = () => {
    const regex = /-/gi;
    const shareCode = idvCheckData?.right_to_work?.share_code?.replaceAll(
      regex,
      " "
    );
    setCopied(idvCheckData?.right_to_work?.share_code);
    copyToClipboard(shareCode);

    setTimeout(() => {
      setCopied("");
      setIsHoveringIcon(false);
    }, 3000);
  };

  if (!shouldShowShareCode) return null;

  return (
    <div className="candidateanswer__section__response-idv__response-item">
      <div className="candidateanswer__section__response-idv__response-item__row">
        <p className="candidateanswer__section__response-idv__response-item-title">
          {t(
            "job.candidate.overview.idv.answer.rtw.shareCode.required"
          )}
        </p>
      </div>

      <div className="candidateanswer__section__response-idv__response-item__row">
        {idvCheckData?.right_to_work?.share_code_skipped ? (
          <p>
            {`${t(
              "job.candidate.overview.idv.answer.rtw.shareCode.notProvided"
            )}`}
          </p>
        ) : (
          <p className="flex flex-row items-center gap-2">
            {t(
              "job.candidate.overview.idv.answer.rtw.shareCode.code",
              { code: idvCheckData?.right_to_work?.share_code }
            )}

            <Copy
              handleCopy={handleCopy}
              isCopied={copied === idvCheckData?.right_to_work?.share_code}
              isShowTooltip={copied === idvCheckData?.right_to_work?.share_code}
              copyBtn={<CopyTable
                fill={isHoveringIcon ? "#5A2AF1" : "#B6BDC5"}
                onMouseEnter={() => setIsHoveringIcon(true)}
                onMouseLeave={() => setIsHoveringIcon(false)}
              />}
            />
          </p>
        )}
      </div>
    </div>
  );
};

IDVShareCode.defaultProps = {
  idvCheckData: {
    right_to_work: {
      share_code: "",
      status: "",
      share_code_skipped: false
    }
  },
  isOverAllStatusSkipped: false,
  currentQuestionAnswer: {}
};

IDVShareCode.propTypes = {
  t: PropTypes.func.isRequired,
  idvCheckData: PropTypes.shape({
    right_to_work: PropTypes.shape({
      share_code: PropTypes.string,
      status: PropTypes.string,
      share_code_skipped: PropTypes.bool
    }),
    represent_as: PropTypes.string
  }),
  isOverAllStatusSkipped: PropTypes.bool,
  currentQuestionAnswer: PropTypes.shape({
    idv_citizenship_iso_code: PropTypes.string
  })
};

export default withTranslation()(IDVShareCode);
