/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-indent */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo, useRef } from "react";
import PropTypes from "prop-types";
// import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import useReactIpLocation from "react-ip-details";
import countryList from "react-select-country-list";
import { getAllInfoByISO } from "iso-country-currency";
import Collapse from "react-bootstrap/Collapse";

import PageWrapper from "hoc/PageWrapper";
import Header from "containers/Common/HeaderContainer";
import PlansSwitcher from "components/Billing/Plans/Switcher";
import PlanInfo from "components/Billing/Plans/Info";
import Spinner from "components/Common/Spinner";
import { PLANS, API_PLANS, PERIODS, YEAR_VALUES, PLANS_NAMES } from "configs/plans";
import Select from "components/Common/Select";
import DropdownIndicator from "components/Forms/Select/dropdown";

import "./styles.scss";
import PlanInfoFooter from "components/Billing/Plans/Info/PlanInfoFooter";
import { useMediaQuery } from "react-responsive";
import { store } from "store";
import { fetchMarketplaceIcons } from "store/modules/marketplace/actions";
import { connect } from "react-redux";
import { getMarketplaceIcons } from "store/modules/marketplace/selectors";
import { Row } from "react-bootstrap";
import { getCurrentUser } from "store/modules/users/selectors";
import { Message, Coupon } from "./svgIcons";

const defaultCurrency = {
  label: "USD",
  value: "usd"
};

const CURRENCY_OPTIONS = [
  {
    label: "USD",
    value: "usd"
  },
  {
    label: "GBP",
    value: "gbp"
  },
  {
    label: "EUR",
    value: "eur"
  },
  {
    label: "AUD",
    value: "aud"
  },
  {
    label: "INR",
    value: "inr"
  }
];


const Plans = ({
  t,
  isLoading,
  currentUser: {
    users_amount: usersAmount
  },
  notifications: { size, country },
  getCheckoutLink,
  isBillingInfoLoading,
  getCheckoutLinkError,
  errorCheckout,
  currencies,
  isCurrenciesLoading,
  getCurrencies,
  marketplaceIcons,
  planName
}) => {
  const isFree = planName === PLANS_NAMES.freeForever;

  const countries = useMemo(() => countryList().getData(), []);
  const [currentPeriod, setCurrentPeriod] = useState(PERIODS.monthly);
  const [currentCurrency, setCurrentCurrency] = useState(defaultCurrency);
  const [optionCurrencies] = useState(CURRENCY_OPTIONS);
  const { currency } = useReactIpLocation();
  const [width, setWidth] = useState(0);
  const [leftWidth, setLeftWidth] = useState(0);
  const ref = useRef();
  const leftRef = useRef();
  const [hoverIndex, setHoverIndex] = useState(null);
  const [isApi, setIsApi] = useState(false);
  const timeoutRef = useRef();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1330px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 680px)" });
  const [currentYear, setCurrentYear] = useState(YEAR_VALUES[0]);
  const [showFairUsage, setshowFairUsage] = useState(false);
  const fairUsageRef = useRef();

  useEffect(() => {
    setWidth(ref?.current?.offsetWidth);
    setLeftWidth(leftRef?.current?.offsetWidth);
  }, [ref?.current?.offsetWidth, leftWidth?.current?.offsetWidth]);

  const getCurrencyPlan = currentPlanName => {
    const currenciesPlan = currencies?.filter(a => a.product_name === currentPlanName);

    const currencyPlan = currenciesPlan?.find(
      ({ currency: currencyValue }) => currencyValue === currentCurrency?.value
    );

    const planValue = {
      ...currencyPlan ?? {},
      price: currentPeriod === PERIODS.annual && currentPlanName !== "Build"
        ? Math.ceil(currencyPlan?.price / 12) : currencyPlan?.price
    };

    if (isCurrenciesLoading) {
      return {};
    }

    return planValue || {};
  };

  useEffect(() => {
    store.dispatch(fetchMarketplaceIcons());
  }, []);

  useEffect(() => {
    store.dispatch(fetchMarketplaceIcons());
  }, []);

  useEffect(() => {
    getCurrencies({
      currency: currentCurrency?.value,
      period: currentPeriod,
      year: currentYear,
      isApi
    });
  }, [currentCurrency, currentPeriod, currentYear, isApi]);

  useEffect(() => {
    const countryValue = countries.find(({ label }) => label === country);
    if (country && countryValue) {
      const countryInfo = getAllInfoByISO(countryValue.value);
      const newCurrency =
        optionCurrencies.find(({ label }) => label === countryInfo?.currency) ||
        defaultCurrency;
      setCurrentCurrency(newCurrency);
    } else if (currency && currency !== defaultCurrency.value) {
      const newCurrency =
        optionCurrencies.find(({ value }) => value === currency) ||
        defaultCurrency;
      setCurrentCurrency(newCurrency);
    }
  }, [currency, country, optionCurrencies.length]);


  useEffect(() => {
    isBillingInfoLoading(false);
    getCheckoutLinkError("");
  }, []);

  const openBeacon = message => {
    if (window.Beacon) {
      window.Beacon("open");
      window.Beacon("navigate", "/ask/message/");
      window.Beacon("prefill", message);
    }
  };
  const openBeaconTrial = () =>
    openBeacon({
      subject: "Trial Complete",
      text: t("plans.moreTrial")
    });
  const openBeaconError = () =>
    openBeacon({
      subject: "Update plan",
      text: t("plans.troubleWithCheckout")
    });

  if (isLoading) {
    return (
      <PageWrapper header={<Header />}>
        <Spinner />
      </PageWrapper>
    );
  }


  return (
    <PageWrapper showBanner={false} header={<Header showUpgrade={false} />}>
      <div className="plans">
        <div className="plans__header">
          <img
            className="plans__header-curve"
            src="https://assets-global.website-files.com/5fc55083e1e580fcdb76f05f/641297cf42cb31e983c50e48_c-header-curve.svg"
            alt="curve"
          />
          <h1 className="plans__header-title">
            {t("plans.title")}
          </h1>
          <p className="plans__header-subTitle">
            {t("plans.subTitle")}
          </p>
        </div>
        {errorCheckout ? (
          <p className="plans__error n-font-medium n-text-center n-red-100">
            {t("plans.errorMessage.0")}
            <button
              type="button"
              className="button__without-styles n-font-medium n-red-100"
              onClick={openBeaconError}
            >
              {t("plans.errorMessage.1")}
            </button>
            {t("plans.errorMessage.2")}
          </p>
        ) : null}
        <div
          className="plans__wrap"
          style={{
            width: isTabletOrMobile ? 0 : width * 4 + leftWidth,
            margin: "auto"
          }}
        >
          <PlansSwitcher
            currentPeriod={currentPeriod}
            setCurrentPeriod={setCurrentPeriod}
            t={t}
            setCurrentYear={setCurrentYear}
            currentYear={currentYear}
            isApi={isApi}
            setIsApi={val => {
              setIsApi(val);
              setCurrentPeriod(PERIODS.annual);
            }}
          />
          <Select
            style={{
              width: 150
            }}
            formElement={{
              elementType: "select",
              elementConfig: {
                type: "text"
              },
              value: currentCurrency,
              options: optionCurrencies,
              prefix: "overview",
              label: "currency"
            }}
            inputChangedHandler={value => setCurrentCurrency(value)}
            className="plan-info__select"
            components={{
              DropdownIndicator: props => (
                <DropdownIndicator
                  {...props}
                  height="13"
                  width="13"
                  fill="#BBC2C9"
                />
              )
            }}
            isLoading={isCurrenciesLoading}
          />
        </div>

        <div className="plans__border">
          <div
            className="plans__container"
            style={{
              minWidth: isTabletOrMobile ? 0 : width * 4 + leftWidth
            }}
          >
            {
              !isTabletOrMobile ? (
                <div className="plan__left" ref={leftRef}>
                  <div className="plan__left-header" />
                  <div className="plan__left-item">{t("plans.activeJobs")}</div>
                  <div className="plan__left-item">{t("plans.includedResponse")}</div>
                  <div
                    className="plan__left-item fair-usage"
                    style={{
                      display: showFairUsage ? "block" : "none",
                      transition: "display 0.3s ease-out"
                    }}
                  >
                    {t("plans.fairUsage")}
                  </div>
                  <div className="plan__left-item">{t("plans.includedSMS")}</div>
                  <div className="plan__left-item">{t("plans.dataStorage")}</div>
                  <div className="plan__left-item">{t("plans.integrations")}</div>

                </div>
              ) : null
            }

            {(isApi ? API_PLANS : PLANS)?.map((plan, index) => (
              <div
                key={plan.name}
                ref={index === 0 ? ref : null}
                style={{
                  width: isMobile ? "100%" : ""
                }}
              >
                <PlanInfo
                  currentPeriod={currentPeriod}
                  index={index}
                  marketplaceIcons={marketplaceIcons}
                  plan={plan}
                  currencyPlan={getCurrencyPlan(plan.name)}
                  t={t}
                  getCheckoutLink={getCheckoutLink}
                  openBeacon={openBeacon}
                  currency={currentCurrency?.value}
                  currentSize={size}
                  usersAmount={usersAmount}
                  setHoverIndex={idx => {
                    clearTimeout(timeoutRef.current);
                    setHoverIndex(idx);
                  }}
                  handleHoverInfoIcon={() => {
                    clearTimeout(fairUsageRef.current);
                    setshowFairUsage(true);
                  }}

                  showFairUsage={showFairUsage}
                  onMouseLeave={() => setHoverIndex(null)}
                  onHoverIconMouseLeave={() => setshowFairUsage(false)}
                  isCurrenciesLoading={isCurrenciesLoading}
                />
              </div>
            ))}
          </div>
          {!isTabletOrMobile ? (
            <>
              <Collapse in={hoverIndex === 0 || hoverIndex}>
                <div
                  className="plans__container"
                  style={{
                    width: width * 4 + leftWidth
                  }}
                  onMouseEnter={() => {
                    clearTimeout(timeoutRef.current);
                  }}
                >
                  <div className="plan__left integrations">
                    <div className="plan__left-item integrations">
                      {t("plans.includedIntegrations")}
                    :
                    </div>
                  </div>
                  <div className="plans-integration__icons">
                    {marketplaceIcons?.map(icon => (
                      <div
                        key={icon.image_url}
                        style={{
                          display: (icon.products.includes((isApi ? API_PLANS : PLANS)?.[hoverIndex]?.name) || (isApi ? API_PLANS : PLANS)?.[hoverIndex]?.name === "Enterprise") ? "block" : "none"
                        }}
                      >
                        <img src={icon.image_url} style={{ height: 48, width: 48 }} alt="integrations" />
                      </div>
                    ))}
                  </div>
                </div>
              </Collapse>
              <div
                className="plans__container"
                style={{
                  minWidth: width * 4 + leftWidth
                }}
              >
                <div className="plan__left">
                  {
                    isApi ? (
                      <div className="plan__left-item">{t("plans.ownBranding")}</div>
                    ) : (
                      <>
                        <div className="plan__left-item">{t("plans.whiteLabel")}</div>
                        <div className="plan__left-item">{t("link.scorecards")}</div>
                      </>
                    )
                  }
                </div>
                {(isApi ? API_PLANS : PLANS)?.map((plan, index) => (
                  <PlanInfoFooter
                    isApi={isApi}
                    width={width}
                    key={plan.name}
                    index={index}
                    currencyPlan={getCurrencyPlan(plan.name)}

                  />
                ))}
              </div>
            </>
          ) : null}
        </div>
        {
          !isFree ? (
            <Row
              className="plans__wrap"
              style={{
                width: width * 4 + leftWidth,
                margin: "40px auto"
              }}
            >
              <div className="plans__pro">
                <span className="plans__pro-title">Free Forever</span>
                <span className="plans__pro-desc n-font-small n-grey">
                  {t("plans.free.description")}
                </span>
                <button
                  type="button"
                  className="n-button__medium-border n-purple-100"
                  onClick={() => getCheckoutLink()}
                >
                  {t("button.downgrade")}
                </button>
              </div>
            </Row>
          ) : null
        }

        <p className="n-font-small n-text-center plans__support flex flex-row items-center justify-center" style={{ marginTop: 20 }}>
          <Coupon />
          {t("trialPeriod.prefer")}
          <button
            type="button"
            onClick={openBeaconTrial}
            className="n-font-small plans__support"
          >
            {t("trialPeriod.team")}
          </button>
        </p>
        <p className="n-font-small n-text-center plans__support flex flex-row items-center justify-center">
          <Message />
          {t("trialPeriod.coupon")}
        </p>
      </div>
    </PageWrapper>
  );
};

Plans.propTypes = {
  t: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  currentUser: PropTypes.shape({
    users_amount: PropTypes.number,
    plan_name: PropTypes.string,
    responses_quota_used: PropTypes.number
  }).isRequired,
  notifications: PropTypes.shape({
    size: PropTypes.number,
    country: PropTypes.string
  }).isRequired,
  getCheckoutLink: PropTypes.func.isRequired,
  isBillingInfoLoading: PropTypes.func.isRequired,
  getCheckoutLinkError: PropTypes.func.isRequired,
  errorCheckout: PropTypes.string.isRequired,
  currencies: PropTypes.arrayOf().isRequired,
  isCurrenciesLoading: PropTypes.bool.isRequired,
  getCurrencies: PropTypes.func.isRequired,
  marketplaceIcons: PropTypes.arrayOf().isRequired,
  planName: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  marketplaceIcons: getMarketplaceIcons(state),
  planName: getCurrentUser(state)?.plan_name
});
export default connect(mapStateToProps)(withTranslation()(Plans));
