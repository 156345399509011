import React, { useCallback, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { store } from "store";
import { signInError, signInWithSSO } from "store/modules/auth/actions";

import "./styles.scss";
import { FaKey } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

const OktaLogin = ({
  isLoadingScreen
}: { isLoadingScreen: boolean }) => {
  const { t } = useTranslation();
  const {
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    getIdTokenClaims,
    error
  } = useAuth0();

  const handleToken = useCallback(async () => {
    if (isAuthenticated && !isLoading) {
      const idTokenClaims = await getIdTokenClaims();
      const idToken = idTokenClaims?.__raw;

      store.dispatch(signInWithSSO({
        provider: "auth0",
        access_token: idToken
      }));
    }
  }, [getIdTokenClaims, isAuthenticated, isLoading]);

  useEffect(() => {
    handleToken();
  }, [handleToken]);

  useEffect(() => {
    if (typeof error !== "undefined" && !isLoading && !isAuthenticated && !isLoadingScreen) {
      store.dispatch(signInError({
        errorMessage: error
      }));
    }
  }, [error, isAuthenticated, isLoading, isLoadingScreen]);

  const handleLogin = async () => {
    try {
      await loginWithRedirect({
        appState: {
          targetUrl: "/sign-in"
        },
        authorizationParams: {
          redirect_uri: window.location.origin,
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: process.env.REACT_APP_AUTH0_SCOPE
        }
      });

      return true;
    } catch (err: any) {
      console.error("Login error details:", {
        message: err.message,
        error: err.error,
        description: err.error_description
      });

      return false;
    }
  };

  return (
    <div>
      <button
        type="button"
        onClick={handleLogin}
        className="w-full rounded bg-white text-main-purple mt-4 text-sm okta-login__button flex flex-row items-center"
      >
        <FaKey size={16} />
        <span className="flex-1">{t("signIn.sso")}</span>
      </button>
    </div>
  );
};

const mapStateToProps = (state: { widgets: { isLoadingMaintenance: boolean} }) => ({
  isLoadingScreen: state.widgets.isLoadingMaintenance || false
});

export default connect(mapStateToProps)(OktaLogin);
