import React from "react";

const CandidateCorrectIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.5 5.5L6.875 14.5L2.5 10.4091" stroke="#0FAC00" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

);

export default CandidateCorrectIcon;
