import React, { useState, useRef, useEffect, useCallback } from "react";
import { withTranslation } from "react-i18next";
import { store } from "store";
import { sendComment, updateComment } from "store/modules/jobs/actions";
import autosize from "autosize";
import { connect } from "react-redux";
import { getSidebarComments } from "store/modules/sidebar/selectors";
import { BeatLoader } from "react-spinners";
import { MAX_TEXT_SYMBOLS, TEXT_WARNING_MESSAGE } from "../../Comments/constants";

const CandidateSidebarAddComment = ({
  t,
  id,
  comments,
  handleCancel = () => {},
  hasComments = false
} = {}) => {
  const userId = store.getState()?.jobsCandidate?.currentCandidateJob?.key;
  const textarea = useRef(null);
  const [value, setValue] = useState(id ? "" : "");
  const [warningMessage, setWarningMessage] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const updateValue = useCallback(() => {
    const comment = comments.find(item => item.key === id);
    setValue(comment?.cached || "");

    setTimeout(() => {
      textarea.current.focus();
      autosize.update(textarea.current);
    }, 0);
  }, [comments, id]);

  useEffect(() => {
    if (id) {
      updateValue();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    autosize(textarea.current);
  }, []);

  const saveComment = async event => {
    try {
      event.preventDefault();
      setIsLoading(true);

      if (id) {
        store.dispatch(updateComment({ userId, commentId: id, text: value }));
        if (typeof handleCancel === "function") handleCancel();
      } else {
        store.dispatch(sendComment({
          userId,
          text: event.target[0].value,
          successCallback: () => setIsLoading(false)
        }));
      }

      setValue("");
      autosize.update(textarea.current);
    } catch (error) {
      console.log({ error });
    }
  };

  const handleComment = e => {
    const str = e.target.value.replace(/\n/g, "");
    setWarningMessage(str.length >= MAX_TEXT_SYMBOLS ? TEXT_WARNING_MESSAGE : "");
    setValue(e.target.value);
  };

  const handleKeyDown = e => {
    if ([37, 39].includes(e.keyCode)) {
      e.stopPropagation();
    }
  };

  return (
    <div key={`add-comment-${id || "0"}`}>
      <form onSubmit={saveComment}>
        <div className={`candidate__comment-textarea ${hasComments ? "" : "bg-white"} ${warningMessage ? "with-warning-message" : ""} ${isActive ? "active" : ""}`}>
          <textarea
            id="comment-add"
            type="text"
            name="comment"
            row="1"
            ref={textarea}
            value={value}
            className="candidate__comment-textarea-input"
            placeholder={t("comments.addAComment")}
            onChange={handleComment}
            onKeyDown={handleKeyDown}
            onFocus={() => setIsActive(true)}
            onBlur={() => {
              setIsActive(false);
              autosize.update(textarea.current);
            }}
          />
        </div>

        {warningMessage && (
          <p className="add-comment__warning-message">{warningMessage}</p>
        )}

        <div className="form__add-comment-submit">
          {
            id ? (
              <div className="flex flex-row gap-2 w-full pt-2">
                <button
                  className="n-button__small n-border-purple-100 n-button__medium-border n-purple-100 w-full"
                  type="button"
                  onClick={typeof handleCancel === "function" ? handleCancel : () => {}}
                  aria-label="Cancel"
                >
                  Cancel
                </button>

                <button
                  disabled={Boolean(warningMessage) || !value?.trim()}
                  className="n-button__small n-border-purple-100 n-button__medium-border n-bg-purple-100 n-white w-full"
                  type="button"
                  onClick={saveComment}
                  aria-label="Save Comment"
                >
                  {t("button.save")}
                </button>
              </div>
            ) : (
              <button
                disabled={Boolean(warningMessage) || !value?.trim() || isLoading}
                className="n-button__small n-bg-purple-100 n-white w-full mb-4 font-medium"
                type="submit"
                aria-label="Add Comment"
              >
                { isLoading ? (
                  <BeatLoader color="#FFF" size={5} />
                ) : t("comments.addComment")}
              </button>
            )
          }
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = state => ({
  comments: getSidebarComments(state)
});

export default connect(mapStateToProps)(withTranslation()(CandidateSidebarAddComment));
