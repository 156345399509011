import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { Question } from "mixins/svgIcons";
import { prefilledEditor } from "mixins/helpers";

import { Text } from "../svgIcons";

const Item = ({
  t,
  questionNumber,
  questionText,
  answer: { html_text: htmlText },
  isCurrent,
  setCurrentQuestion,
  buttonColor
}) => {
  const text = prefilledEditor(htmlText)?.getCurrentContent()?.getPlainText();
  const count = htmlText ? text?.trim().split(/\s+/).length : 0;
  return (
    <div
      role="presentation"
      className={`preview__list-item ${isCurrent && "current"}`}
      onClick={setCurrentQuestion}
      style={isCurrent && buttonColor ? { background: `${buttonColor}20` } : null}
    >
      <div className="preview__list-item-type file">
        <Text />
        <p className="preview__list-item-type-name n-grey n-font-medium text" title={t("candidate.common.wordCount", { count })}>
          {t("candidate.common.wordCount", { count })}
        </p>
      </div>
      <div className="preview__list-item-info">
        <p className="preview__list-item-info-subtitle n-grey-100 n-font-small flex flex-row">
          <Question svgIconClass="preview__list-item-info-icon" />
          <span>{`${t("candidate.common.question")} ${questionNumber}`}</span>
        </p>

        <p
          className="preview__list-item-info-title n-grey-300 n-font-small"
          title={questionText}
        >
          {questionText}
        </p>
      </div>
    </div>
  );
};

Item.defaultProps = {
  buttonColor: ""
};

Item.propTypes = {
  t: PropTypes.func.isRequired,
  questionNumber: PropTypes.number.isRequired,
  questionText: PropTypes.string.isRequired,
  answer: PropTypes.shape({
    html_text: PropTypes.string
  }).isRequired,
  isCurrent: PropTypes.bool.isRequired,
  setCurrentQuestion: PropTypes.func.isRequired,
  buttonColor: PropTypes.string
};

export default withTranslation()(Item);
