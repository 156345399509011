import React from "react";

import Spinner from "components/Common/Spinner";

export default WrappedComponent => {
  const Wrapper = props => {
    const { match: { params: { userId } }, currentCandidateId } = props;
    if (userId !== currentCandidateId) {
      return <Spinner />;
    }
    return <WrappedComponent {...props} />;
  };

  return Wrapper;
};
