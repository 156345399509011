/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import moment from "moment";
import Steps, { Step } from "rc-steps";
import PropTypes from "prop-types";

import { defaultTimeFormat, DATE_FORMATS } from "configs/dates";
import { StepsIcons } from "mixins/svgIcons";

import { capitalizeFirstLetter } from "utils/string";
import { store } from "store";
import {
  getCandidateHistoryLoading,
  getCanSeeCandidateScores,
  getIsCurrentCandidateScored,
  getUserCurrentJob,
  getUserScoreDraftKey
} from "store/modules/jobs/selectors";
import { connect } from "react-redux";
import { getProfileInfo } from "store/modules/profile/selectors";
import { isNull } from "lodash";
import { getRatingToString } from "mixins/helperCandidate";
import {
  getCandidateScores,
  getInterviewHasScorecard,
  getScorecardInterviewTitle
} from "store/modules/scorecards/selectors";
import { fetchCandidateHistory } from "store/modules/jobs/actions";
import SkeletonWrapper from "containers/Hoc/SkeletonWrapper";
import useWindowResize from "hooks/useWindowResize.tsx";
import { isSidebarEnabled } from "mixins/helpers";
import AddComment from "./Comments/AddComment";
import Comment from "./Comments/Item";
import "./styles.scss";
import InterviewScorecard from "./InterviewScorecard";
import InterviewScorecardsView from "./InterviewScorecardsView";

const Title = ({ description, name, reason }) => (
  <span>
    {description}
    <span className="candidate-overview__subtitle">{`${name} ${reason}`}</span>
  </span>
);

const SUCCESSS = "success";
const COMPLETION = "completion";

const EmailSmsTitle = ({ action_type }) => {
  const state = store.getState();
  const user = getUserCurrentJob(state);
  const words = action_type.replace(SUCCESSS, COMPLETION).split("_");
  const type =
    words[1] === "sms"
      ? words[1].toUpperCase()
      : capitalizeFirstLetter(words[1]);
  const status = capitalizeFirstLetter(words[2]);
  const actionType = capitalizeFirstLetter(words[0]);
  const contact = words[1] === "sms" ? user.phone : user.email;

  return (
    <div>
      <div>{`${type} ${status}`}</div>
      <div className="candidate-overview__subtitle">
        {`${actionType} email ${status}: ${contact}`}
      </div>
    </div>
  );
};

EmailSmsTitle.propTypes = {
  action_type: PropTypes.string.isRequired
};

Title.propTypes = {
  description: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  reason: PropTypes.string.isRequired
};

const Activites = ({
  t,
  activities,
  userId,
  profileEmail,
  sendComment,
  updateComment,
  deleteComment,
  isInvited = false,
  activeTab,
  setActiveTab,
  isScored,
  jobId,
  scorecardTitle,
  candidateScores,
  canSeeScores,
  interviewHasScorecard,
  draftKey,
  isActivityLoading
}) => {
  const currentUserId = getProfileInfo(store.getState())?.key;
  const [currentComment, setCurrentComment] = useState("");
  const activityRef = useRef(null);

  const { isMobileScreen } = useWindowResize();

  const observer = new IntersectionObserver(
    entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          store.dispatch(fetchCandidateHistory({ userId }));
          observer.unobserve(entry.target);
        }
      });
    },
    {
      threshold: 0
    }
  );

  useEffect(() => {
    const currentRef = activityRef.current;

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div>
        {isInvited ? null : (
          <>
            <div className={`scorecard-tabs ${isMobileScreen && isSidebarEnabled ? "w-1/2" : "w-full"}`} id="scorecard-tabs">
              {
                ((!isMobileScreen && isSidebarEnabled) || !isSidebarEnabled) ? (
                  <div
                    className={`tab ${activeTab === "comments" ? "active" : ""}`}
                    onClick={() => setActiveTab("comments")}
                  >
                    {t("job.candidate.comments.title")}
                  </div>
                ) : null
              }
              {interviewHasScorecard && (
                <div
                  className={`tab ${activeTab === "scorecard" ? "active" : ""}`}
                  onClick={() => setActiveTab("scorecard")}
                >
                  <div>
                    {t("scorecard.title")}
                    {isScored ? (
                      <span className="submitted">
                        {t("people.table.submitted")}
                      </span>
                    ) : (
                      <>
                        {
                          draftKey ? <span className="draft">{t("job.table.draft")}</span> : null
                        }
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>


            <div className="comments" style={{ display: activeTab === "comments" && (isSidebarEnabled ? !isMobileScreen : true) ? "block" : "none" }}>
              <AddComment
                userId={userId}
                sendComment={sendComment}
                currentComment={currentComment}
                setCurrentComment={setCurrentComment}
              />
            </div>
            <div style={{ display: activeTab !== "comments" ? "block" : "none" }}>
              {isScored || (!isScored && canSeeScores) ? (
                <InterviewScorecardsView
                  userId={userId}
                  title={scorecardTitle}
                  candidateScores={candidateScores}
                  jobId={jobId}
                />
              ) : (
                <InterviewScorecard
                  userId={userId}
                  jobId={jobId}
                  key={userId}
                />
              )}
            </div>
          </>
        )}
      </div>
      <p className="n-font-medium n-font-regular n-grey-100 candidate-overview__activity">
        {t("job.candidate.activity")}
      </p>

      <div className="flex" style={{ gap: 16 }} ref={activityRef}>
        <SkeletonWrapper
          skeletonProps={{
            circle: true,
            width: 26,
            height: 26,
            enableAnimation: true
          }}
          skeletonWrapperProps={{
            style: {
              paddingRight: 12
            }
          }}
          isLoading={isActivityLoading}
        />
        <SkeletonWrapper
          skeletonProps={{
            count: 2,
            height: 16,
            width: 180,
            enableAnimation: true
          }}
          skeletonWrapperProps={{
            style: {
              display: "block",
              lineHeight: 1.5,
              marginBottom: "0.5rem",
              width: "100%"
            }
          }}
          isLoading={isActivityLoading}
        >
          <Steps direction="vertical">
            {activities.map(item => {
              const [, , rating] = item.description
                ? item.description.split(" ")
                : [];
              const description = ["Bounced", "Dropped"].includes(
                item.action_type
              )
                ? t("status.user.Bounced.pleaseTry")
                : `${moment(item.created_at).format(
                  DATE_FORMATS.SLASH_SEPARATOR_LONG_YEAR
                )}
              ${t("default.at")}
              ${defaultTimeFormat(moment(item.created_at))}`;
              let title;

              switch (true) {
              case ["email", "sms"].some(a => item.action_type.includes(a)):
                title = <EmailSmsTitle action_type={item.action_type} />;
                break;
              case item.action_type === "Rating":
                title = (
                  <Title
                    description={
                      isNull(item.description)
                        ? t("status.user.Rating.submittedBy")
                        : getRatingToString({ t, rating })?.label
                    }
                    name={` ${
                        isNull(item.description)
                          ? ""
                          : t("plans.numerator").toLowerCase()
                      } ${
                        item.user_key === currentUserId
                          ? t("status.user.Rating.you")
                          : item.user_name
                      }`}
                    reason=""
                  />
                );
                break;
              case item.action_type === "Dropped":
                title = t("status.user.Bounced.description");
                break;
              case ["Accepted", "Rejected"].includes(item.action_type):
                title = (
                  <Title
                    description={t(
                        `status.user.${item.action_type}.description`
                    )}
                    name={`${t("status.user.by")}${item.user_name}`}
                    reason={
                      item.action_type === "Rejected" && item.rejection_type
                        ? ` (${t(`status.user.Rejected.${item.rejection_type}`)})`
                        : ""
                    }
                  />
                );
                break;
              case item.action_type === "Comment":
                title = (
                  <Comment
                    key={item.key}
                    userId={userId}
                    profileEmail={profileEmail}
                    comment={item}
                    updateComment={updateComment}
                    deleteComment={deleteComment}
                  />
                );
                break;
              default:
                title = t(`status.user.${item.action_type}.description`);
                break;
              }

              return (
                <Step
                  key={item.created_at}
                  title={title}
                  icon={
                    item.action_type === "Rating" && rating
                      ? getRatingToString({
                        t,
                        rating: Number(rating),
                        isActive: true,
                        isRatingIcon: true
                      })?.icon
                      : StepsIcons[item.action_type]
                  }
                  description={description}
                  status="wait"
                  className={`${
                    item.action_type === "Comment" ? "rc-steps-comment" : ""
                  } ${
                    item.action_type === "Rating" && !isNull(item.description)
                      ? "rc-steps-rating"
                      : ""
                  }`}
                />
              );
            })}
          </Steps>
        </SkeletonWrapper>
      </div>
    </>
  );
};

Activites.defaultProps = {
  userId: undefined,
  activities: undefined,
  profileEmail: undefined,
  isInvited: undefined,
  sendComment: undefined,
  updateComment: undefined,
  deleteComment: undefined,
  isActivityLoading: false
};

Activites.propTypes = {
  t: PropTypes.func.isRequired,
  userId: PropTypes.string,
  activities: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      text: PropTypes.string
    })
  ),
  profileEmail: PropTypes.string,
  isInvited: PropTypes.bool,
  sendComment: PropTypes.func,
  updateComment: PropTypes.func,
  deleteComment: PropTypes.func,
  activeTab: PropTypes.string.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  isScored: PropTypes.bool.isRequired,
  canSeeScores: PropTypes.bool.isRequired,
  interviewHasScorecard: PropTypes.bool.isRequired,
  jobId: PropTypes.string.isRequired,
  scorecardTitle: PropTypes.string.isRequired,
  candidateScores: PropTypes.arrayOf.isRequired,
  draftKey: PropTypes.bool.isRequired,
  isActivityLoading: PropTypes.bool
};
const mapStateToProps = state => ({
  isScored: getIsCurrentCandidateScored(state),
  draftKey: getUserScoreDraftKey(state),
  scorecardTitle: getScorecardInterviewTitle(state),
  candidateScores: getCandidateScores(state),
  canSeeScores: getCanSeeCandidateScores(state),
  interviewHasScorecard: getInterviewHasScorecard(state),
  isActivityLoading: getCandidateHistoryLoading(state)
});
export default connect(mapStateToProps)(withTranslation()(Activites));
