import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Formik, Field, Form } from "formik";

import { Phone, Agency, MailBlack, Person } from "mixins/svgIcons";

import { setInitialInfo, editFormSchema, editFormOwnerSchema } from "mixins/helperProfile";
import { BeatLoader } from "react-spinners";

const EditForm = ({ t, updateProfileInfo, hideEditProfile, profileInfo, isOwner }) => {
  const initialFormState = setInitialInfo(profileInfo);

  return (
    <Formik
      initialValues={initialFormState}
      validationSchema={isOwner ? editFormOwnerSchema() : editFormSchema()}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        updateProfileInfo({
          full_name: values.full_name,
          agency_name: values.agency,
          phone: values.phone,
          successCallback: () => {
            setSubmitting(false);
            hideEditProfile();
          }
        });
      }}
      render={({ errors, touched, isSubmitting }) => (
        <Form className="edit-from flex flex-col gap-3">
          <Field
            type="text"
            name="full_name"
            render={({ field }) => (
              <div className="flex flex-col pb-2">
                <div className="flex flex-row items-center gap-4 ">
                  <Person fill="#1E384B" height="15" />
                  <input
                    {...field}
                    type="text"
                    placeholder={t("input.namePlaceholder")}
                    className={classNames(
                      "edit-profile__input mb-0",
                      touched.full_name && errors.full_name && "error"
                    )}
                  />
                </div>
                {errors.full_name && touched.full_name ? (
                  <div className="text-red-600 mt-1 text-left text-xs">
                    {t(errors.full_name)}
                  </div>
                ) : null}
              </div>
            )}
          />

          <Field
            type="email"
            name="email"
            render={({ field }) => (
              <div className="flex flex-col">
                <div className="flex flex-row items-center gap-4">
                  <MailBlack />
                  <input
                    {...field}
                    disabled
                    title={field.value}
                    placeholder="Email"
                    className={classNames(
                      "edit-profile__input mb-0",
                      "disabled",
                      touched.email && errors.email && "error"
                    )}
                  />
                </div>
                {errors.email && touched.email ? (
                  <div className="text-red-600 mt-1 text-left text-xs">

                    {t(errors.email)}
                  </div>
                ) : null}
              </div>
            )}
          />

          <Field
            type="tel"
            name="phone"
            render={({ field }) => (
              <div className="flex flex-col">
                <div className="flex flex-row items-center gap-4">
                  <Phone />
                  <input
                    {...field}
                    type="tel"
                    placeholder={t("input.phonePlaceholder")}
                    className={classNames(
                      "edit-profile__input mb-0",
                      touched.phone && errors.phone && "error"
                    )}
                  />
                </div>
                {errors.phone && touched.phone ? (
                  <div className="text-red-600 mt-1 text-left text-xs">

                    {t(errors.phone)}
                  </div>
                ) : null}
              </div>
            )}
          />

          {isOwner && (
            <Field
              type="text"
              name="agency"
              render={({ field }) => (
                <div className="flex flex-col pb-8">
                  <div className="flex flex-row items-center gap-4 ">
                    <Agency />
                    <input
                      {...field}
                      type="text"
                      placeholder={t("input.agencyPlaceholder")}
                      className={classNames(
                        "edit-profile__input mb-0",
                        touched.agency && errors.agency && "error"
                      )}
                    />
                  </div>
                  {errors.agency && touched.agency ? (
                    <div className="text-red-600 mt-1 text-left text-xs">
                      {t(errors.agency)}
                    </div>
                  ) : null}
                </div>
              )}
            />
          )}

          <div className="button__group mx-auto flex flex-row gap-2">
            <button
              type="button"
              onClick={hideEditProfile}
              className="button button__edit-form"
              disabled={isSubmitting}
            >
              {t("button.cancel")}
            </button>
            <button
              type="submit"
              disabled={isSubmitting}
              className="button button__edit-form button__edit-form_fill"
            >
              {
                isSubmitting ? <BeatLoader size={4} /> : t("button.submit")
              }
            </button>
          </div>
        </Form>
      )}
    />
  );
};

EditForm.defaultProps = {
  hideEditProfile: undefined,
  updateProfileInfo: undefined,
  profileInfo: undefined,
  isOwner: undefined
};

EditForm.propTypes = {
  t: PropTypes.func.isRequired,
  hideEditProfile: PropTypes.func,
  updateProfileInfo: PropTypes.func,
  profileInfo: PropTypes.shape({
    agency: PropTypes.string,
    agency_name: PropTypes.string,
    email: PropTypes.string,
    full_name: PropTypes.string,
    phone: PropTypes.string,
    worker_count: PropTypes.number
  }),
  isOwner: PropTypes.bool
};

export default withTranslation()(EditForm);
