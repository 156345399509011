import React from "react";
import PropTypes from "prop-types";

export const Mobile = ({
  height,
  width
}) => (
  <svg width={width} height={height} viewBox="0 0 26 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.1787 29.7229C15.3364 29.7229 14.6516 29.0375 14.6516 28.1953C14.6516 27.3542 15.3364 26.6693 16.1787 26.6693C17.024 26.6693 17.7088 27.3542 17.7088 28.1953C17.7088 29.0375 17.024 29.7229 16.1787 29.7229ZM19.4912 2.5516H12.8703C12.5896 2.5516 12.3651 2.32493 12.3651 2.04533C12.3651 1.76506 12.5896 1.54013 12.8703 1.54013H19.4912C19.7698 1.54013 19.9964 1.76506 19.9964 2.04533C19.9964 2.32493 19.7698 2.5516 19.4912 2.5516ZM21.9626 0H10.3974C8.42139 0 6.8042 1.6172 6.8042 3.5932V12.5464H8.63128V5.35053C8.63128 4.62866 9.21565 4.04427 9.937 4.04427H22.4224C23.1432 4.04427 23.7282 4.62866 23.7282 5.35053V24.0417C23.7282 24.763 23.1432 25.3474 22.4224 25.3474H9.937C9.21565 25.3474 8.63128 24.763 8.63128 24.0417V18.0208H6.8042V28.4099C6.8042 30.3932 8.41096 32 10.3943 32H21.9626C23.938 32 25.5547 30.3828 25.5547 28.4063V3.5932C25.5547 1.6172 23.938 0 21.9626 0Z" fill="#595959" />
    <path d="M17.2604 15.2811C17.2604 15.2848 17.2604 15.2848 17.2604 15.2863C17.2604 15.3176 17.2584 15.3467 17.2541 15.3764C17.2489 15.4322 17.2385 15.4874 17.2245 15.5406C17.215 15.5676 17.2046 15.5952 17.1958 15.6202C17.15 15.7338 17.0838 15.8379 16.9937 15.927L11.5609 21.3598C11.3838 21.5368 11.15 21.627 10.9172 21.627C10.6833 21.627 10.4494 21.5368 10.2703 21.3598C9.91507 21.002 9.91507 20.4249 10.2703 20.0686L14.1448 16.1952H0.912467C0.408301 16.1952 0.000488281 15.7863 0.000488281 15.2832C0.000488281 14.7796 0.408301 14.3702 0.912467 14.3702H14.1448L10.2703 10.4963C9.91507 10.1406 9.91507 9.56189 10.2703 9.20562C10.6286 8.85042 11.2047 8.85042 11.5609 9.20562L16.9937 14.6368V14.639C17.038 14.6811 17.0745 14.7264 17.1046 14.7743C17.1213 14.7972 17.136 14.8228 17.15 14.8483C17.1656 14.8743 17.177 14.8999 17.189 14.927C17.1994 14.9524 17.2088 14.9786 17.2177 15.004C17.2297 15.0384 17.236 15.0728 17.2437 15.1062C17.2541 15.163 17.2604 15.2207 17.2604 15.2811Z" fill="#595959" />
  </svg>
);

Mobile.defaultProps = {
  height: "32",
  width: "26"
};

Mobile.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string
};

export const Copy = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.5 0.5H2C1.45 0.5 1 0.95 1 1.5V8C1 8.275 1.225 8.5 1.5 8.5C1.775 8.5 2 8.275 2 8V2C2 1.725 2.225 1.5 2.5 1.5H7.5C7.775 1.5 8 1.275 8 1C8 0.725 7.775 0.5 7.5 0.5ZM9.5 2.5H4C3.45 2.5 3 2.95 3 3.5V10.5C3 11.05 3.45 11.5 4 11.5H9.5C10.05 11.5 10.5 11.05 10.5 10.5V3.5C10.5 2.95 10.05 2.5 9.5 2.5ZM9 10.5H4.5C4.225 10.5 4 10.275 4 10V4C4 3.725 4.225 3.5 4.5 3.5H9C9.275 3.5 9.5 3.725 9.5 4V10C9.5 10.275 9.275 10.5 9 10.5Z" fill="#5A2AF1" />
  </svg>
);
