import { useState, useEffect } from "react";

function useWindowResize() {
  const [isMobileScreen, setIsMobileScreen] = useState(window.innerWidth < 768);
  const [isTabletAndDesktopScreen, setIsTabletAndDesktopScreen] = useState(
    window.innerWidth >= 768
  );
  const [isMobileAndTabletScreen, setIsMobileAndTabletScreen] = useState(
    window.innerWidth <= 1023
  );
  const [isSmallLaptopScreen, setIsSmallLaptopScreen] = useState(
    window.innerWidth >= 1024 && window.innerWidth <= 1919
  );
  const [isDesktopScreen, setIsDesktopScreen] = useState(window.innerWidth >= 1920);

  useEffect(() => {
    function handleResize() {
      setIsMobileScreen(window.innerWidth < 768);
      setIsTabletAndDesktopScreen(window.innerWidth >= 768);
      setIsMobileAndTabletScreen(window.innerWidth <= 1023);
      setIsSmallLaptopScreen(window.innerWidth >= 1024 && window.innerWidth <= 1919);
      setIsDesktopScreen(window.innerWidth >= 1920);
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return {
    isMobileScreen,
    isMobileAndTabletScreen,
    isSmallLaptopScreen,
    isTabletAndDesktopScreen,
    isDesktopScreen
  };
}

export default useWindowResize;
