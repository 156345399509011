/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CopyTable, SmallDiamondsIcon, SidebarCloseIcon } from "mixins/svgIcons";
import Copy from "components/Tables/Jobs/Buttons/Copy";

import { connect } from "react-redux";
import { BeatLoader } from "react-spinners";
import { getCandidateAnswers, getIsJobCandidateLoading } from "store/modules/jobs/selectors";
import { store } from "store";
import { setSidebarOpen } from "store/modules/jobs/actions";
import { copyToClipboard } from "mixins/helpers";
import { isMobile } from "react-device-detect";
import useSummaryStore from "store/summaryStore.ts";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import { getToken } from "store/modules/auth/selectors";
import { getCurrentUser } from "store/modules/users/selectors";
import WilloIntelligence from "assets/images/candidate/willo-intelligence.png";
import CustomTooltip from "components/Common/CustomTooltip/CustomTooltip.tsx";
import "../CandidateSidebarTranscriptions/styles.scss";
import { CandidateAnswersType } from "types/candidateAnswer.ts";
import { CurrentUserType } from "types/user.ts";
import { SUMMARY_STATUS } from "types/summaries.ts";
import ReactMarkdown from "react-markdown";
import NoSummaryHistory from "./NoSummaryHistory.tsx";

type SummaryPropsType = {
  isJobCandidateLoading: boolean;
  candidateAnswers: CandidateAnswersType;
  currentUser: CurrentUserType
};

type CandidateSidebarSummaryPropsType = SummaryPropsType & RouteComponentProps;

const CandidateSidebarSummary = ({
  isJobCandidateLoading,
  candidateAnswers,
  currentUser: {
    answers_summaries_status: summaryStatus
  }
} : CandidateSidebarSummaryPropsType) => {
  const [isHovering, setIsHovering] = useState(false);
  const [isShowTooltip, setIsShowTooltip] = useState(false);
  const [isHoveringIcon, setIsHoveringIcon] = useState(false);

  const { t } = useTranslation();
  const { summary, isLoading: isSummaryLoading } = useSummaryStore();

  const noSummary = (!summary?.content?.length && !(isJobCandidateLoading ||
    isSummaryLoading)) || summaryStatus === SUMMARY_STATUS.DISABLED;

  const handleCopy = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    setIsShowTooltip(true);
    copyToClipboard(summary?.content);

    setTimeout(() => {
      setIsShowTooltip(false);
      setIsHoveringIcon(false);
    }, 1000);
  };

  const handleOpenBeacon = () => {
    const message = {
      subject: t("summary.beacon.subject"),
      text: t("summary.beacon.text")
    };

    if (window.Beacon) {
      window.Beacon("open");
      window.Beacon("navigate", "/ask/message/");
      window.Beacon("prefill", message);
    }
  };

  return (
    <div className={`flex flex-col ${(noSummary) ? "bg-black-5" : "bg-white"} flex-1`}>
      <div className="transcription__sidebar-wrapper flex flex-col flex-1">
        <div className="transcription__sidebar-container summary flex-1">
          <div className="candidate__sidebar-comments__header flex w-full items-center justify-between">
            <div className="flex flex-row items-center gap-4">
              <CustomTooltip
                content={
                  <div className="font-normal">
                    <span className="text-main-purple font-poppins">{t("willoIntelligence.0")}</span>
                    {t("willoIntelligence.1")}
                  </div>
                }
              >
                <img
                  src={WilloIntelligence}
                  alt="Willo Intelligence"
                  className="w-5 h-5 cursor-help"
                />
              </CustomTooltip>
              <span className="text-base text-black-90">{t("summary.sidebarTitle")}</span>
            </div>

            <SidebarCloseIcon
              style={{ pointerEvents: "all", cursor: "pointer" }}
              onClick={() => store.dispatch(setSidebarOpen(false))}
            />
          </div>

          {
            (isJobCandidateLoading || isSummaryLoading) ? (
              <div className="flex flex-col justify-center items-center flex-1">
                <BeatLoader color="#5A2AF1" size={10} />
              </div>
            ) : summary?.isGeneratingSummary ? (
              <div className="flex flex-col justify-center items-center flex-1">
                <BeatLoader color="#5A2AF1" size={10} />
                <span className="loading-text">{t("summary.generatingTranscripts")}</span>
              </div>
            ) : (
              <>
                { summary?.content?.length ? (
                  <div
                    className="summary__container"
                    onMouseEnter={() => setIsHovering(true)}
                    onMouseLeave={() => setIsHovering(false)}
                  >
                    <div className="summary__content">
                      <ReactMarkdown className="prose max-w-none text-black-90">
                        {summary?.content}
                      </ReactMarkdown>
                    </div>

                    <Copy
                      handleCopy={handleCopy}
                      isCopied={isShowTooltip}
                      isShowTooltip={isShowTooltip}
                      successStyles={{ marginLeft: 5 }}
                      copyBtn={<CopyTable
                        style={{ marginLeft: 5, display: isHovering || isMobile ? "inline-block" : "none" }}
                        svgIconClass="not-clickable-icon"
                        fill={isHovering && isHoveringIcon ? "#5A2AF1" : "#B6BDC5"}
                        onMouseEnter={() => setIsHoveringIcon(true)}
                        onMouseLeave={() => setIsHoveringIcon(false)}
                      />}
                    />

                    <div className="flex justify-center items-center w-full summary__ai">
                      <div><SmallDiamondsIcon /></div>
                      <div>
                        {t("summary.aiGeneratedSummary.0")}
                        <a
                          href="#"
                          data-beacon-article="175"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="underline n-purple-100 text-xs"
                        >
                          {t("summary.aiGeneratedSummary.1")}
                        </a>
                        {t("summary.aiGeneratedSummary.2")}
                        <strong>{summary?.answersUsed}</strong>
                        {t("summary.aiGeneratedSummary.3")}
                        <strong>{candidateAnswers?.length}</strong>
                        {t("summary.aiGeneratedSummary.4")}
                      </div>
                    </div>
                  </div>
                ) : <NoSummaryHistory />
                }
              </>
            )
          }
        </div>
      </div>

      <>
        <div className="flex flex-col sticky bottom-0">
          <div className="h-5 bg-gradient-to-t from-white to-transparent" />
          {
            !noSummary ? (
              <div className="flex flex-row flex-wrap w-full items-center justify-center text-xs p-3 gap-1 bg-white">
                {t("summary.notAccurate.0")}
                <button
                  type="button"
                  className="button__without-styles underline hover:no-underline n-purple-100"
                  onClick={handleOpenBeacon}
                >
                  {t("summary.notAccurate.1")}
                </button>
              </div>
            ) : null
          }
        </div>
      </>
    </div>
  );
};

const mapStateToProps = (state: { isJobCandidateLoading: boolean, token: string }) => ({
  isJobCandidateLoading: getIsJobCandidateLoading(state),
  token: getToken(state),
  candidateAnswers: getCandidateAnswers(state),
  currentUser: getCurrentUser(state)
});

export default connect(mapStateToProps)(withRouter((CandidateSidebarSummary)));
