import React, { useMemo } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { DBS_RTW_TYPE, DBS_TYPE, IDVStatusChip } from "configs/jobs/constants";
import { get, isNull } from "lodash";

const DBSCheck = ({
  t,
  idvCheckData,
  isIdvSkipped
}) => {
  const isDBSCheck = [DBS_TYPE, DBS_RTW_TYPE].includes(idvCheckData?.check_type);

  const isDocumentProcessing =
    useMemo(() => (isNull(get(idvCheckData, "documents[0].document_type", null)) ||
    isNull(get(idvCheckData, "documents[0].images_id")) ||
    !idvCheckData?.documents) && !isIdvSkipped, [idvCheckData, isIdvSkipped]);

  if (!isDBSCheck || isDocumentProcessing) return null;

  return (
    <div className="candidateanswer__section__response-idv__response-item">
      <div className="candidateanswer__section__response-idv__response-item__row title">
        <div className="candidateanswer__section__response-idv__response-item-title">
          {t("job.candidate.overview.idv.dbs_check.title")}
        </div>
        {IDVStatusChip[idvCheckData?.dbs?.status]}
      </div>

      <div className="candidateanswer__section__response-idv__response-item__row">
        <p style={{ width: "75%" }}>
          { idvCheckData?.overall_status === "accepted" ? `${t(
            "job.candidate.overview.idv.answer.levelOfConfidence"
          )}` : ""}
        </p>

      </div>
    </div>
  );
};

export default withTranslation()(DBSCheck);


DBSCheck.defaultProps = {
  idvCheckData: {},
  isIdvSkipped: false
};

DBSCheck.propTypes = {
  t: PropTypes.func.isRequired,
  idvCheckData: PropTypes.shape({
    documents: PropTypes.shape([]),
    dbs: PropTypes.shape({
      status: PropTypes.string
    }),
    check_type: PropTypes.string,
    overall_status: PropTypes.string
  }),
  isIdvSkipped: PropTypes.bool
};
