import React from "react";

const AnswerListIcon = () => (
  <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.09375 0.0625H2.40625C2.76172 0.0625 3.0625 0.363281 3.0625 0.71875V2.03125C3.0625 2.41406 2.76172 2.6875 2.40625 2.6875H1.09375C0.710938 2.6875 0.4375 2.41406 0.4375 2.03125V0.71875C0.4375 0.363281 0.710938 0.0625 1.09375 0.0625ZM5.25 0.5H13.125C13.5898 0.5 14 0.910156 14 1.375C14 1.86719 13.5898 2.25 13.125 2.25H5.25C4.75781 2.25 4.375 1.86719 4.375 1.375C4.375 0.910156 4.75781 0.5 5.25 0.5ZM5.25 4.875H13.125C13.5898 4.875 14 5.28516 14 5.75C14 6.24219 13.5898 6.625 13.125 6.625H5.25C4.75781 6.625 4.375 6.24219 4.375 5.75C4.375 5.28516 4.75781 4.875 5.25 4.875ZM5.25 9.25H13.125C13.5898 9.25 14 9.66016 14 10.125C14 10.6172 13.5898 11 13.125 11H5.25C4.75781 11 4.375 10.6172 4.375 10.125C4.375 9.66016 4.75781 9.25 5.25 9.25ZM0.4375 5.09375C0.4375 4.73828 0.710938 4.4375 1.09375 4.4375H2.40625C2.76172 4.4375 3.0625 4.73828 3.0625 5.09375V6.40625C3.0625 6.78906 2.76172 7.0625 2.40625 7.0625H1.09375C0.710938 7.0625 0.4375 6.78906 0.4375 6.40625V5.09375ZM1.09375 8.8125H2.40625C2.76172 8.8125 3.0625 9.11328 3.0625 9.46875V10.7812C3.0625 11.1641 2.76172 11.4375 2.40625 11.4375H1.09375C0.710938 11.4375 0.4375 11.1641 0.4375 10.7812V9.46875C0.4375 9.11328 0.710938 8.8125 1.09375 8.8125Z" fill="#5A2AF1" />
  </svg>

);

export default AnswerListIcon;
