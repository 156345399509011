/* eslint-disable camelcase */

export enum SUMMARY_STATUS {
  AVAILABLE = "available",
  DISABLED = "disabled",
  ENABLED = "enabled"
}

export type SummaryType = {
  summary_content: string;
  answers_used: number;
}
