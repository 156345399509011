import CandidateAudioIcon from "assets/images/candidate/CandidateAudioIcon";
import React from "react";
import "../index.scss";
import PropTypes from "prop-types";
import { formatStringtoDuration } from "mixins/helperVideoRecording";

const CandidateThumbnailAudio = ({ answer }) => (
  <div>
    <CandidateAudioIcon />
    <>
      {
        answer?.media_duration ? (
          <div
            className="candidatethumbnail__item__header-filechip"
            style={{
              background: "#FFFFFF",
              color: "#595959"

            }}
          >
            {formatStringtoDuration(answer?.media_duration)}
          </div>
        ) : null
      }
    </>
  </div>
);

CandidateThumbnailAudio.propTypes = {
  answer: PropTypes.shape({
    thumbnail_remote_link: PropTypes.string,
    html_text: PropTypes.string,
    text: PropTypes.string,
    question: PropTypes.shape({
      html_text: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      answer_type: PropTypes.string.isRequired
    }).isRequired,
    media_duration: PropTypes.string.isRequired
  }).isRequired
};


export default CandidateThumbnailAudio;
