import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { getPublicUserInfo, isPublicInfoLoading } from "store/modules/public/selectors";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Promotion1 from "../../../assets/images/candidate/promotion-1.png";
import Promotion2 from "../../../assets/images/candidate/promotion-2.png";
import Promotion3 from "../../../assets/images/candidate/promotion-3.png";

import "./styles.scss";

const ShowcasePromotions = ({
  t,
  isLoading = true,
  publicCandidateInfo: {
    configuration: { showcase_promotions_enabled: showcasePromotionsEnabled } = {}
  }
}) => {
  if (!showcasePromotionsEnabled || isLoading) return null;

  return (
    <div className="showcase__promotions-wrapper container">
      <div className="showcase__promotions-title">{t("showcase_promotions.title")}</div>

      <div className="showcase__promotions-benefit-wrapper">
        <div className="showcase__promotions-benefit">
          <img
            style={{ height: 180 }}
            src={Promotion1}
            alt="Screen candidates at scale"
          />
          <div className="showcase__promotions-benefit__title">{t("showcase_promotions.promotion_1.title")}</div>
          <div className="showcase__promotions-benefit__description">{t("showcase_promotions.promotion_1.description")}</div>
          <a
            className="showcase__promotions-benefit__cta"
            target="_blank"
            href="https://www.willo.video/case-studies"
            rel="noopener noreferrer"
          >
            {t("candidate.videoCreate.learnMore")}
          </a>
        </div>
        <div className="showcase__promotions-benefit">
          <img
            style={{ height: 180 }}
            src={Promotion2}
            alt="Over 5,000 integrations"
          />
          <div className="showcase__promotions-benefit__title">{t("showcase_promotions.promotion_2.title")}</div>
          <div className="showcase__promotions-benefit__description">{t("showcase_promotions.promotion_2.description")}</div>
          <a
            className="showcase__promotions-benefit__cta"
            target="_blank"
            href="https://www.willo.video/integrations"
            rel="noopener noreferrer"
          >
            {t("link.exploreMore")}
          </a>
        </div>
      </div>

      <div className="showcase__promotions-benefit bottom">
        <img
          style={{ height: 180 }}
          src={Promotion3}
          alt="Hire people, not paper"
        />
        <div className="showcase__promotions-benefit__title">{t("showcase_promotions.promotion_3.title")}</div>
        <div className="showcase__promotions-benefit__description bottom">
          {t("showcase_promotions.promotion_3.description")}
        </div>
        <Link
          className="showcase__promotions-benefit__cta"
          to={`/sign-up?utm_source=showcase&utm_medium=cv-video&utm_campaign=candidates+distibution&utm_id=header&back=${encodeURIComponent(window?.location?.pathname)}`}
        >
          {t("link.startTrialToday")}
        </Link>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  publicCandidateInfo: getPublicUserInfo(state),
  isLoading: isPublicInfoLoading(state)
});

export default connect(mapStateToProps)(withTranslation()(ShowcasePromotions));

ShowcasePromotions.defaultProps = {
  publicCandidateInfo: {},
  isLoading: true
};

ShowcasePromotions.propTypes = {
  t: PropTypes.func.isRequired,
  publicCandidateInfo: PropTypes.shape({
    configuration: PropTypes.shape({
      showcase_promotions_enabled: PropTypes.bool
    })
  }),
  isLoading: PropTypes.bool
};
