import React from "react";
import PropTypes from "prop-types";

const Title = ({ children }) => (
  <h2 className="profile-card__title break-words">{children}</h2>
);

Title.defaultProps = {
  children: undefined
};

Title.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
};

export default Title;
