/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import "./index.scss";
import { Form } from "react-bootstrap";
import CorrectIcon from "assets/images/candidate/CandidateCorrectIcon";
import { Close } from "mixins/svgIcons";


const CandidateAnswerMCQItem = ({
  title,
  isChecked,
  isCorrect,
  hasCorrectOption,
  isRadio
}) => {
  const status = useMemo(() => {
    if (!hasCorrectOption) {
      return {
        status: "no-correct",
        bgColor: ""
      };
    }

    if (isCorrect && isChecked) {
      return {
        status: "correct",
        bgColor: "#eefaed"
      };
    }
    if (!isCorrect && isChecked) {
      return {
        status: "incorrect",
        bgColor: "#E11F1F"
      };
    }
    if (isCorrect && !isChecked) {
      return {
        status: "correct_unselected",
        bgColor: "#eefaed"
      };
    }

    return {
      status: "unselected",
      bgColor: ""
    };
  }, [hasCorrectOption, isChecked, isCorrect]);


  return (
    <div
      className={`candidateanswer__mcq ${status.status}`}
    >
      {
        !["correct", "incorrect"].includes(status.status) ? <Form.Check
          type={isRadio ? "radio" : "checkbox"}
          checked={isChecked}
          style={{
            pointerEvents: "none",
            accentColor: "gray"
          }}
          className={`radio__value ${isChecked ? "checked" : ""}`}
        /> : null
      }
      {
        status.status === "correct" && <CorrectIcon />
      }
      {
        status.status === "incorrect" && <Close fill="#E11F1F" />
      }
      <div className="candidateanswer__mcq-item">
        <div>{title}</div>
        {
         status?.status &&
          (
            <div className={`candidateanswer__mcq-pill ${status.status}`}>
              {
                status.status === "correct" ? "Correct" : status.status === "incorrect" ? "Incorrect" : ""
              }
            </div>
          )
        }
      </div>
    </div>
  );
};

CandidateAnswerMCQItem.propTypes = {
  isChecked: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  isCorrect: PropTypes.bool.isRequired,
  hasCorrectOption: PropTypes.bool.isRequired
};

export default CandidateAnswerMCQItem;
