import "./polyfills";
import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";

import App from "./App";

if (process.env.REACT_APP_SENTRY_ENABLED === "true") {
  console.log("Sentry enabled");
  Sentry.init({
    dsn: `https://${process.env.REACT_APP_SENTRY_KEY}.ingest.sentry.io/${process.env.REACT_APP_SENTRY_ID}`,
    environment: process.env.REACT_APP_ENV,
    ignoreErrors: [
      "ResizeObserver loop limit exceeded",
      "Permission denied", // when media devices are not permitted
      "The object can not be found here.", // when media devices are blocked
      "Could not start video source", // when media devices are blocked
      "Permission dismissed", // when media devices are not permitted
      "The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.", // when media devices are not permitted
      "candidate.videoCreate.mediaBlocked", // when media devices are not permitted
      "Operation canceled due to new request", // happens when api request is called few times at the same time
      "Firebase: Error (auth/network-request-failed)", // needs to be investigated,
      "NotFoundError: Requested device not found", // happens when the device is not found, being used somewhere or doesnt exist,
      "Non-Error exception captured with keys: message", // happens when return or retry button is clicked while uploading media,
      "NotAllowedError: play() failed because the user didn't interact with the document first. https://goo.gl/xX8pDD", // happens when play is called without user interaction
      "Failed to execute 'querySelectorAll' on 'Element': 'map:not(svg map),canvas:not(svg canvas),del:not(svg del),ins:not(svg ins),a:not(svg a)' is not a valid selector." // happens when browser is <v90 where tinymce is not supported
    ],
    tracesSampleRate: 0.1,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    integrations: [Sentry.replayIntegration({
      unmask: [".sentry-unmask, [data-sentry-unmask]"],
      maskAllText: false,
      stickySession: true,
      networkDetailAllowUrls: [
        window.location.origin,
        String(process.env.REACT_APP_BACKEND_URL)
      ]
    })]
  });
}

const container = document.getElementById("root");
ReactDOM.render(<App />, container);
