/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import moment from "moment";
import debounce from "lodash/debounce";

import Table from "components/Common/Table";
import TooltipInfo from "components/Common/Tooltip/Info";
import withLoader from "hoc/withLoader";
import { Edit, TrashBin, CopyTable } from "mixins/svgIcons";
import { DATE_FORMATS } from "configs/dates";

import { Email, Message, Eye } from "./svgIcons";
import "./styles.scss";

const Libraries = ({
  t,
  isAdmin,
  currentUser,
  fetchLibraries,
  fetchLatestLibraries,
  duplicateLibrary,
  dataLibraries,
  dataLibrariesPagination: { next, count },
  setModal,
  isLatestLibrariesLoading,
  filter,
  clearFilter,
  currentLanguage
}) => {
  const firstUpdate = useRef(true);
  const fetchData = newFilter =>
    fetchLibraries({ filter: newFilter, language: currentLanguage });
  const fetchDataDebounce = useCallback(
    debounce(newFilter => fetchData(newFilter), 500),
    []
  );

  useEffect(() => {
    clearFilter();
  }, [currentUser.key]);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      fetchData(filter);
      return;
    }

    fetchDataDebounce(filter);
  }, []);

  useEffect(() => {
    fetchData(filter);
  }, [currentLanguage]);

  // eslint-disable-next-line max-len
  const handleDuplicate = useCallback(
    key => duplicateLibrary({ key, filter, language: currentLanguage }),
    [filter]
  );

  const handleDelete = useCallback(key =>
    setModal({
      type: "deleteLibraryConfirmation",
      libraryConfirmation: { key }
    }));

  const openModal = templateInfo =>
    setModal({
      type: "previewTemplate",
      templateInfo
    });

  return (
    <Table
      t={t}
      next={next}
      fetchLatestItems={fetchLatestLibraries}
      isLoading={isLatestLibrariesLoading}
      count={count}
      header={[
        {
          Header: t("libraries.table.title"),
          accessor: "title",
          headerClassName: "left-align-column",
          Cell: ({
            original: { key, title, type, system_default: systemDefault }
          }) =>
            (isAdmin && !systemDefault ? (
              <Link
                to={`/libraries/edit/${key}`}
                className="n-font-small libraries__title flex flex-row items-center justify-start"
              >
                {type === "email" ? <Email /> : <Message />}
                {title}
              </Link>
            ) : (
              <div className="n-font-small flex flex-row items-center justify-start ">
                {type === "email" ? <Email /> : <Message />}
                {title}
              </div>
            )),
          width: 300,
          style: { fontWeight: 400 }
        },
        {
          Header: t("libraries.table.updated"),
          accessor: "updated_at",
          headerClassName: "left-align-column",
          Cell: ({ original: { updated_at: updatedAt } }) => (
            <span>
              {moment(updatedAt).format(DATE_FORMATS.SLASH_SEPARATOR)}
            </span>
          ),
          width: 100
        },
        {
          Header: t("libraries.table.preview"),
          accessor: "content_text",
          headerClassName: "left-align-column",
          minWidth: 400
        },
        {
          Header: "",
          minWidth: 30
        },
        {
          accessor: false,
          Cell: ({ original: { title, content, type, trigger } }) => (
            <div className="tooltip__edit">
              <div
                role="presentation"
                className="n-font-small libraries__title"
                onClick={() =>
                  openModal({
                    label: title,
                    content,
                    type,
                    trigger
                  })
                }
              >
                <Eye />
              </div>
            </div>
          ),
          style: { textAlign: "center", paddingTop: 14, height: "100%" },
          width: 30,
          sortable: false
        },
        {
          accessor: false,
          Cell: ({ original: { key, system_default: systemDefault } }) => {
            if (isAdmin && !systemDefault) {
              return (
                <div className="tooltip__edit">
                  <Link to={`/libraries/edit/${key}`} className="link__edit">
                    <Edit />
                  </Link>
                </div>
              );
            }
            return null;
          },
          style: { textAlign: "center", paddingLeft: 0, paddingRight: 10, height: "100%" },
          width: 30,
          sortable: false
        },
        {
          accessor: false,
          Cell: ({ original: { key } }) => {
            if (isAdmin) {
              return (
                <TooltipInfo
                  prefix="tooltip__icon-duplicate without-styles align-center md"
                  position="left"
                  message={t("libraries.table.duplicate")}
                  element={
                    <button
                      onClick={() => handleDuplicate(key)}
                      className="button__without-styles duplicate h-full"
                      type="button"
                    >
                      <CopyTable svgIconClass="not-clickable-icon" />
                    </button>
                  }
                />
              );
            }
            return null;
          },
          style: { textAlign: "center",
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 0,
            paddingBottom: 0,
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "auto" },
          width: 30
        },
        {
          accessor: false,
          Cell: ({
            original: { key, system_default: systemDefault, is_in_use: isInUse }
          }) => {
            if (isAdmin && !systemDefault && !isInUse) {
              return (
                <button
                  onClick={() => handleDelete(key)}
                  className="button__without-styles icon__delete libraries-delete__icon h-full"
                  type="button"
                >
                  <TrashBin svgIconClass="not-clickable-icon" />
                </button>
              );
            }
            return null;
          },
          style: {
            textAlign: "center",
            paddingLeft: 0,
            paddingRight: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%"

          },
          height: "100%",
          width: 30,
          sortable: false
        }
      ]}
      body={dataLibraries || []}
      noDataMessage={t("libraries.table.noData")}
    />
  );
};

Libraries.defaultProps = {
  filter: { title: "", type: null }
};

Libraries.propTypes = {
  t: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isLatestLibrariesLoading: PropTypes.bool.isRequired,
  filter: PropTypes.shape({
    title: PropTypes.string,
    type: PropTypes.string
  }),
  currentUser: PropTypes.shape({
    key: PropTypes.string
  }).isRequired,
  original: PropTypes.shape({
    key: PropTypes.string,
    title: PropTypes.string,
    updated_at: PropTypes.string,
    content: PropTypes.string,
    content_text: PropTypes.string,
    system_default: PropTypes.bool,
    type: PropTypes.string,
    trigger: PropTypes.string,
    is_in_use: PropTypes.bool
  }).isRequired,
  dataLibraries: PropTypes.arrayOf(PropTypes.object).isRequired,
  dataLibrariesPagination: PropTypes.shape({
    next: PropTypes.string,
    count: PropTypes.number
  }).isRequired,
  fetchLibraries: PropTypes.func.isRequired,
  fetchLatestLibraries: PropTypes.func.isRequired,
  duplicateLibrary: PropTypes.func.isRequired,
  setModal: PropTypes.func.isRequired,
  clearFilter: PropTypes.func.isRequired,
  currentLanguage: PropTypes.string.isRequired
};

export default withTranslation()(withLoader(Libraries));
