
import CandidateTextIcon from "assets/images/candidate/CandidateTextIcon";
import { prefilledEditor } from "mixins/helpers";
import React from "react";
import { useTranslation } from "react-i18next";

const CandidateThumbnailText = ({ answer }) => {
  const { t } = useTranslation();
  const text = prefilledEditor(answer?.html_text)?.getCurrentContent()?.getPlainText();
  const count = answer?.html_text ? text?.trim().split(/\s+/).length : 0;
  return (
    <div>
      <CandidateTextIcon />
      <div className="candidatethumbnail__item__header-filechip">
        {t("candidate.common.wordCount", { count })}
      </div>
    </div>
  );
};

export default CandidateThumbnailText;
