import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import Lottie from "react-lottie";
import animationData from "assets/images/candidate/error.json";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const CandidateAnswerFile = ({
  t,
  size = "small"
}) => (
  <>
    <div className="candidateanswer__section__response-expired response">
      <div className="candidateanswer__section__response-expired-name">
        <Lottie
          options={defaultOptions}
          height={size === "big" ? 320 : 165}
          width={size === "big" ? 450 : 165}
        />
      </div>
      <div className="candidateanswer__section__response-expired-action">
        {t("job.candidate.expiredContent")}
      </div>
    </div>
    <div className="candidateanswer__floatingbackground" />
  </>
);

CandidateAnswerFile.defaultProps = {
  t: () => {},
  size: "small"
};

CandidateAnswerFile.propTypes = {
  t: PropTypes.func,
  size: PropTypes.string
};

export default withTranslation()(CandidateAnswerFile);
