import PropTypes from "prop-types";
import React from "react";

const CandidateIDVIcon = ({ fill }) => (
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M34.2222 36.2143C33.6285 36.2143 33.1667 35.7612 33.1667 35.1786C33.1667 34.6607 33.6285 34.1429 34.2222 34.1429H42.6667C43.1944 34.1429 43.7222 34.6607 43.7222 35.1786C43.7222 35.7612 43.1944 36.2143 42.6667 36.2143H34.2222ZM44.7778 15.5C47.0868 15.5 49 17.3772 49 19.6429V40.3571C49 42.6875 47.0868 44.5 44.7778 44.5H15.2222C12.8472 44.5 11 42.6875 11 40.3571V19.6429C11 17.3772 12.8472 15.5 15.2222 15.5H44.7778ZM46.8889 40.3571V21.7143H13.1111V40.3571C13.1111 41.5223 14.0347 42.4286 15.2222 42.4286H44.7778C45.8993 42.4286 46.8889 41.5223 46.8889 40.3571ZM13.1111 19.6429H46.8889C46.8889 18.5424 45.8993 17.5714 44.7778 17.5714H15.2222C14.0347 17.5714 13.1111 18.5424 13.1111 19.6429ZM34.2222 27.9286C33.6285 27.9286 33.1667 27.4754 33.1667 26.8929C33.1667 26.375 33.6285 25.8571 34.2222 25.8571H42.6667C43.1944 25.8571 43.7222 26.375 43.7222 26.8929C43.7222 27.4754 43.1944 27.9286 42.6667 27.9286H34.2222ZM34.2222 32.0714C33.6285 32.0714 33.1667 31.6183 33.1667 31.0357C33.1667 30.5179 33.6285 30 34.2222 30H42.6667C43.1944 30 43.7222 30.5179 43.7222 31.0357C43.7222 31.6183 43.1944 32.0714 42.6667 32.0714H34.2222ZM23.6667 32.0714C21.2917 32.0714 19.4444 30.2589 19.4444 27.9286C19.4444 25.6629 21.2917 23.7857 23.6667 23.7857C25.9757 23.7857 27.8889 25.6629 27.8889 27.9286C27.8889 30.2589 25.9757 32.0714 23.6667 32.0714ZM23.6667 25.8571C22.4792 25.8571 21.5556 26.8281 21.5556 27.9286C21.5556 29.0938 22.4792 30 23.6667 30C24.7882 30 25.7778 29.0938 25.7778 27.9286C25.7778 26.8281 24.7882 25.8571 23.6667 25.8571ZM17.3333 40.3571C16.7396 40.3571 16.2778 39.904 16.2778 39.3214C16.2778 36.4732 18.5868 34.1429 21.5556 34.1429H25.7778C28.6806 34.1429 31.0556 36.4732 31.0556 39.3214C31.0556 39.904 30.5278 40.3571 30 40.3571C29.4062 40.3571 28.9444 39.904 28.9444 39.3214C28.9444 37.6384 27.4931 36.2143 25.7778 36.2143H21.5556C19.7743 36.2143 18.3889 37.6384 18.3889 39.3214C18.3889 39.904 17.8611 40.3571 17.3333 40.3571Z" fill={fill} />
  </svg>

);

CandidateIDVIcon.propTypes = {
  fill: PropTypes.string.isRequired
};

export default CandidateIDVIcon;
