/* eslint-disable no-nested-ternary */
import PropTypes from "prop-types";
import React from "react";
import CandidateIDVIcon from "assets/images/candidate/CandidateIDVIcon";
import { capitalize, toLower } from "lodash";

const CandidateThumbnailIDV = ({ status }) => (
  <div>
    <CandidateIDVIcon fill={toLower(status) === "accepted" ? "#0FAC00" : ["inconclusive", "skipped"]?.includes(toLower(status)) ? "#F47704" : toLower(status) === "rejected" ? "#E11F1F" : "#101933"} />
    <div
      className="candidatethumbnail__item__header-filechip"
      style={{
        background: toLower(status) === "accepted" ? "#0FAC00" : toLower(status) === "rejected" ? "#E11F1F" : ["inconclusive", "skipped"]?.includes(toLower(status)) ? "#F47704" : ""
      }}
    >
      {capitalize(status)}
    </div>
  </div>
);

CandidateThumbnailIDV.propTypes = {
  status: PropTypes.string.isRequired
};

export default CandidateThumbnailIDV;
