/* eslint-disable operator-linebreak */
/* eslint-disable no-nested-ternary */
import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { UploadIcon } from "mixins/svgIcons";
import isBrowserSupported from "mixins/helperBrowser";

const Buttons = ({
  t,
  isRecording,
  isCompleted,
  btnsDisabled,
  startRecord,
  stopRecord,
  isShowDelayMessage,
  isMediaBlocked,
  setIsMediaBlocked,
  onClickUpload
}) => {
  const onMediaSuccess = () => setIsMediaBlocked(false);

  const onMediaError = errorMessage => {
    setIsMediaBlocked(errorMessage);
    console.log({ errorMessage });
  };

  const handleStartRecord = () => {
    if (isMediaBlocked) {
      /* can not catch if user enable video/mic, thus have to reset settings */
      setTimeout(onMediaSuccess, 1000);
    } else {
      startRecord(onMediaSuccess, onMediaError);
    }
  };

  const buttonProps = isRecording
    ? isCompleted
      ? {}
      : {
        className:
            "n-fluid n-button__medium n-bg-red-100 n-white n-stop-mobile",
        disabled: btnsDisabled.btnStop,
        onClick: stopRecord,
        label: t("button.recordStopJob")
      }
    : isCompleted
      ? {
        className:
          "n-fluid n-button__medium-border n-border-purple-100 n-purple-100",
        disabled: btnsDisabled.btnStart || typeof EBML === "undefined",
        onClick: handleStartRecord,
        label: t("button.reRecord")
      }
      : {
        className:
          "n-fluid n-button__medium-border n-border-purple-100 n-purple-100",
        style: isMediaBlocked ? { background: "#768493" } : {},
        disabled: btnsDisabled.btnStart || isShowDelayMessage || typeof EBML === "undefined",
        onClick: handleStartRecord,
        label: t("button.recordStartJob")
      };

  return (
    <div className="job__video-buttons">
      {isBrowserSupported && (
        <>
          <div className="n-stop-mobile-wrapper">
            <button type="button" {...buttonProps}>
              {buttonProps.label}
            </button>
          </div>
          <div className="job__video-or n-grey-100">{t("job.preview.or")}</div>
        </>
      )}

      <button
        type="button"
        className="n-fluid n-button__medium-border n-border-grey-50 n-grey-50 flex flex-row items-center justify-center"
        onClick={onClickUpload}
      >
        {t("button.uploadVideo")}
        <UploadIcon svgIconClass="job__video-icon" />
      </button>
    </div>
  );
};

Buttons.defaultProps = {
  btnsDisabled: {},
  isShowDelayMessage: false
};

Buttons.propTypes = {
  t: PropTypes.func.isRequired,
  isRecording: PropTypes.bool.isRequired,
  isCompleted: PropTypes.bool.isRequired,
  isShowDelayMessage: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  isMediaBlocked: PropTypes.bool.isRequired,
  btnsDisabled: PropTypes.shape({
    btnStart: PropTypes.bool,
    btnStop: PropTypes.bool
  }),
  startRecord: PropTypes.func.isRequired,
  stopRecord: PropTypes.func.isRequired,
  setIsMediaBlocked: PropTypes.func.isRequired,
  onClickUpload: PropTypes.func.isRequired
};

export default withTranslation()(Buttons);
