/* eslint-disable no-nested-ternary */
import React from "react";
import { Form } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import Spinner from "components/Common/Spinner";
import PropTypes from "prop-types";
import { ChevronLeft } from "mixins/svgIcons";

const ConnectDropdownTriggerStage = ({
  shouldShowStages,
  isMobileOrTabletScreen,
  currentSelectedJob,
  t,
  setShouldShowStages,
  setCurrentSelectedJob,
  atsDetails,
  selectedJobsAndStages,
  updateSelectedJobsAndStages
}) => {
  const handleClick = (stage, selected) => {
    if (selected) {
      const newList = selectedJobsAndStages.filter(a => {
        if (a.remote_interview_id === currentSelectedJob.remote_id &&
           a.remote_stage_id === stage.remote_id) {
          return false;
        }
        return true;
      });

      updateSelectedJobsAndStages(newList);
    } else {
      const newList = [...selectedJobsAndStages,
        { remote_stage_id: stage.remote_id,
          remote_interview_id: currentSelectedJob.remote_id,
          remote_stage_name: stage.name,
          remote_interview_name: currentSelectedJob.name,
          atsKey: currentSelectedJob.atsKey }];

      updateSelectedJobsAndStages(newList);
    }
  };

  return (
    <div className={`right ${isMobileOrTabletScreen ? "mobile" : ""} ${shouldShowStages ? "" : "hide"}`}>
      {
        !currentSelectedJob?.remote_id ? (
          atsDetails?.availableJobs?.results?.length ? (
            <span className="select-stage-title">
              {t("job.overview.connect.selectAStage")}
            </span>
          ) : null
        ) : (
          <>
            <div className="select-stage-title-mobile">
              { isMobileOrTabletScreen && (
                <span
                  onClick={() => {
                    setShouldShowStages(false);
                    setCurrentSelectedJob(null);
                    setCurrentSelectedJob(null);
                  }}
                  onKeyDown={() => {}}
                  role="button"
                  tabIndex="0"
                >
                  <ChevronLeft fill="#595959" />
                </span>
              )}
              {t("job.overview.connect.selectTriggerStage")}
            </div>
            <div className="selector-item-wrapper">
              {!atsDetails?.isStagesLoading && atsDetails?.availableStages?.map(stage => {
                const selected = selectedJobsAndStages?.some(saved =>
                  saved.remote_stage_id === stage.remote_id
                  && saved.remote_interview_id === currentSelectedJob.remote_id);

                return (
                  <div
                    className="selector-item"
                    key={stage.remote_id}
                    onClick={() => handleClick(stage, selected)}
                    onKeyDown={() => {}}
                    role="button"
                    tabIndex="0"
                  >
                    <Form.Check
                      type="checkbox"
                      id="check"
                      checked={selected}
                      readOnly
                    />
                    {stage.name}
                  </div>
                );
              })}
              {
                !atsDetails?.isStagesLoading && !atsDetails?.availableStages?.length ? (
                  <span className="no-stages">
                    {t("connectDropdown.noStages", { value: currentSelectedJob?.name })}
                  </span>
                ) : null
              }
              { atsDetails?.isStagesLoading && <Spinner /> }
            </div>
          </>
        )
      }
    </div>
  );
};

ConnectDropdownTriggerStage.defaultProps = {
  shouldShowStages: undefined,
  isMobileOrTabletScreen: undefined,
  currentSelectedJob: undefined,
  t: undefined,
  setShouldShowStages: undefined,
  setCurrentSelectedJob: undefined,
  atsDetails: undefined,
  selectedJobsAndStages: undefined,
  updateSelectedJobsAndStages: undefined
};

ConnectDropdownTriggerStage.propTypes = {
  shouldShowStages: PropTypes.bool,
  isMobileOrTabletScreen: PropTypes.bool,
  currentSelectedJob: PropTypes.shape({
    remote_id: PropTypes.string,
    name: PropTypes.string,
    atsKey: PropTypes.string
  }),
  t: PropTypes.func,
  setShouldShowStages: PropTypes.func,
  setCurrentSelectedJob: PropTypes.func,
  atsDetails: PropTypes.shape({
    atsConnected: PropTypes.arrayOf(PropTypes.shape({})),
    isLoading: PropTypes.bool,
    availableJobs: PropTypes.shape({
      next: PropTypes.string,
      results: PropTypes.arrayOf(PropTypes.shape({})),
      atsKey: PropTypes.string
    }),
    availableStages: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string
    })),
    savedJobsAndStages: PropTypes.arrayOf(PropTypes.shape({
      remote_stage_name: PropTypes.string
    })),
    savedJobsAndStagesCount: PropTypes.number,
    isStagesLoading: PropTypes.bool
  }),
  selectedJobsAndStages: PropTypes.arrayOf(PropTypes.shape({})),
  updateSelectedJobsAndStages: PropTypes.func
};

export default withTranslation()(ConnectDropdownTriggerStage);
