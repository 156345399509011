import React, { useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import formatBytes from "components/UploadFile/helpers";
import { store } from "store";
import { updateCandidateFile, updateCandidateFileError, updateCandidateFileResponse } from "store/modules/сandidates/actions";
import { handleFileName } from "mixins/helperVideoRecording";
import { setCurrentQuestionAnswer } from "store/modules/jobs/actions";
import { Edit } from "mixins/svgIcons";
import { getProfileInfo } from "store/modules/profile/selectors";
import UnsafeModal from "components/Answer/List/File/UnsafeModal";
import { MAX_UPLOAD_VIDEO_SIZE } from "../../../../../constants";

const CandidateAnswerFile = ({
  t,
  currentQuestionAnswer: {
    key: currentQuestionAnswerKey,
    question: {
      key
    } = {},
    friendly_name: friendlyName,
    media_extension: mediaExtension,
    remote_link: remoteLink,
    size
  } = {},
  userId,
  jobId,
  error,
  isMaliciousFileMuted,
  realtimeAnswers
}) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [currentFile, setCurrentFile] = useState(() => ({
    name: friendlyName,
    mediaExtension,
    size,
    src: remoteLink
  }));
  const [currentFileName, setCurrentFileName] = useState(() => `${friendlyName}.${mediaExtension}`);
  const [uploadProgress, setUploadProgress] = useState(null);
  const [showUnsafeModal, setShowUnsafeModal] = useState(false);

  const fileInputRef = useRef(null);

  const currentStatus = useMemo(() => realtimeAnswers?.[currentQuestionAnswerKey]?.status,
    [realtimeAnswers, currentQuestionAnswerKey]);

  const handleDownload = () => {
    const a = document.createElement("a");
    a.href = remoteLink;
    a.download = `${friendlyName}.${mediaExtension}`;
    document.body.appendChild(a);
    a.click();

    if (document.body.contains(a)) {
      document.body.removeChild(a);
    }
  };

  const handleClickDownload = () => {
    if (isMaliciousFileMuted) {
      return handleDownload();
    }

    return setShowUnsafeModal(true);
  };

  const handleOnFileChange = e => {
    const result = e.target.files[0];

    if (!result) {
      return;
    }

    const isBigBlobSize = result && result?.size > MAX_UPLOAD_VIDEO_SIZE;

    if (isBigBlobSize) {
      store.dispatch(updateCandidateFileError(t("errors.videoTooBig")));
      return;
    }

    setIsUpdating(true);
    setCurrentFile(result);
    setCurrentFileName(result?.name);
  };

  const handleChangeFile = () => {
    fileInputRef.current.click();
  };

  const handleSaveFile = () => {
    setUploadProgress(0);

    const fileName = handleFileName(currentFile?.name);

    const dataToUpload = {
      jobId,
      userId,
      questionId: key,
      name: fileName?.name,
      mediaExtension: fileName?.ext,
      size: currentFile?.size,
      blob: currentFile,
      isRecruiter: true,
      successCallback: ({ payload }) => {
        store.dispatch(updateCandidateFileResponse(payload));
        store.dispatch(setCurrentQuestionAnswer(payload));

        setIsUpdating(false);
        setUploadProgress(null);
      },
      onUploadProgress: percentage => setUploadProgress(percentage)
    };

    store.dispatch(updateCandidateFile(dataToUpload));
  };

  const handleRemoveFile = () => {
    setCurrentFile({
      name: friendlyName,
      mediaExtension,
      size,
      src: remoteLink
    });

    setCurrentFileName(`${friendlyName}.${mediaExtension}`);
    setIsUpdating(false);
  };

  if (currentStatus === "unsafe") {
    return (
      <>
        <div className="candidateanswer__section__response-file response malicious">
          <span>{t("candidate.videoCreate.mediaUnsafe")}</span>
          <p>
            {t("notFound.contact")}
            <a href="mailto:support@willo.video">support@willo.video</a>
            {t("notFound.moreInfo")}
          </p>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="candidateanswer__section__response-file response">
        <div className="candidateanswer__section__response-file-name">
          <span className="candidateanswer__section__response-file-name-title">
            {`${currentFileName}`}

            {
              isUpdating
                ? <Edit
                  svgIconClass="candidateanswer__section__response-file-action__edit"
                  onClick={handleChangeFile}
                />
                : null
            }
          </span>
          <span className="candidateanswer__section__response-file-size">
            {formatBytes(currentFile?.size || 0)}
          </span>

          {
            uploadProgress !== null && (
              <div className="candidateanswer__section__response-file-progress">
                <progress
                  value={uploadProgress}
                  max="100"
                  className="questions-upload__progress"
                />
              </div>
            )
          }
        </div>
        <div className={`candidateanswer__section__response-file-action ${isUpdating && uploadProgress !== null ? "hidden" : ""}`}>
          <button
            type="button"
            onClick={isUpdating ? handleSaveFile : handleClickDownload}
            className="n-bg-purple-100 n-button__medium n-white"
            aria-label={t(`button.${isUpdating ? "save" : "view"}`)}
          >
            {t(`button.${isUpdating ? "save" : "view"}`)}
          </button>

          <input
            ref={fileInputRef}
            type="file"
            accept="*"
            style={{ display: "none" }}
            onChange={handleOnFileChange}
          />

          <button
            type="button"
            onClick={() => (isUpdating ? handleRemoveFile() : handleChangeFile())}
            className="n-md n-button__medium n-purple-100 n-border-purple-100 n-button__medium-border n-bg-white"
            aria-label={t(`button.${isUpdating ? "cancel" : "change"}`)}
          >
            {t(`button.${isUpdating ? "cancel" : "change"}`)}
          </button>
        </div>
        <span className={`dzu-message ${isUpdating ? "hidden" : ""}`}>{t("candidate.videoCreate.pleaseNote")}</span>

        {
          error && isUpdating ? (
            <span className="error-message">
              {error}
            </span>
          ) : null
        }
      </div>
      <div className="candidateanswer__floatingbackground" />
      {showUnsafeModal && (
        <UnsafeModal
          onDownload={handleDownload}
          handleCloseModal={() => setShowUnsafeModal(false)}
        />
      )}
    </>
  );
};

CandidateAnswerFile.defaultProps = {
  t: () => {},
  currentQuestionAnswer: {},
  userId: "",
  jobId: "",
  error: "",
  isMaliciousFileMuted: false
};

CandidateAnswerFile.propTypes = {
  t: PropTypes.func,
  currentQuestionAnswer: PropTypes.shape({
    friendly_name: PropTypes.string,
    media_extension: PropTypes.string,
    remote_link: PropTypes.string
  }),
  userId: PropTypes.string,
  jobId: PropTypes.string,
  error: PropTypes.string,
  isMaliciousFileMuted: PropTypes.bool
};

const mapStateToProps = state => ({
  currentQuestionAnswer: state?.jobsCandidate?.currentQuestionAnswer,
  error: state?.candidates?.currentCandidateFileError,
  isMaliciousFileMuted: getProfileInfo(state)?.is_malicious_file_message_muted || false
});

export default connect(mapStateToProps)(withTranslation()(CandidateAnswerFile));
