/* eslint-disable no-confusing-arrow */
import { handleActions, combineActions } from "redux-actions";
import xor from "lodash/xor";

import { setNewCandidateInfo } from "store/modules/common/actions";
import { logOut } from "store/modules/auth/actions";
import {
  isCandidateLoading,
  isVideoUploading,
  isInfoUpdating,
  fetchCandidateInfoResponse,
  fetchCandidateInfoError,
  fetchCandidateJobInfoResponse,
  fetchCandidateJobInfoError,
  fetchQuestionsAnswersResponse,
  fetchQuestionsAnswersError,
  updateCandidateInfoResponse,
  updateCandidateInfoError,
  saveRetakeAnswer,
  saveAnswerResponse,
  saveAnswerError,
  saveUserCredentials,
  saveCurrentQuestion,
  saveOnAmazonS3Response,
  saveOnAmazonS3Error,
  changeCandidateFileError,
  savePreviousQuestion,
  saveIsAllAnswerSaved,
  retakeAnswerAfterFileUpload,
  retakeAnswerResponse,
  retakeAnswerError,
  clearPreviousQuestion,
  clearCalendarDates,
  clearSavedAnswers,
  setSavedAnswers,
  clearUploadInfo,
  clearErrors,
  clearIsLoading,
  setCurrentCandidateId,
  setCandidatePosition,
  updateCalendarDates,
  downloadAllResponse,
  updateVideoArchives,
  updateIsOpenVideoArchives,
  downloadAndDeleteLinkResponse,
  fetchHintsAndTips,
  fetchHintsAndTipsResponse,
  fetchHintsAndTipsError,
  updateCandidateLangResponse,
  getIdvCheckDataLoading,
  getIdvCheckDataError,
  getIdvCheckDataResponse,
  downloadAndDeleteLinkError,
  clearDownloadLinkError,
  videoAttemptResponse,
  updateVideoPreference,
  saveCurrentQuestionIsMuted,
  setAudioDevice,
  setVideoDevice,
  downloadAndDeleteLinkFailed,
  updateCandidateFileError
} from "./actions";

const defaultState = {
  currentCandidateId: "",
  videoArchives: [],
  isOpen: false,
  hasDownloadError: false,
  volume: 1,
  audioDevice: undefined,
  videoDevice: undefined
};

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

export default handleActions(
  {
    [isCandidateLoading]: (state, { payload: { userId, isLoading } }) => ({
      ...state,
      [userId]: {
        ...state[userId],
        error: null,
        success: "",
        isCandidateLoading: isLoading
      }
    }),
    [isVideoUploading]: (state, { payload: { userId, isVideoLoading } }) => ({
      ...state,
      [userId]: {
        ...state[userId],
        error: null,
        success: "",
        isVideoLoading
      }
    }),
    [isInfoUpdating]: (state, { payload: { userId, isUpdating } }) => ({
      ...state,
      [userId]: {
        ...state[userId],
        isInfoUpdating: isUpdating
      }
    }),
    [updateCalendarDates]: (state, { payload: { userId, newDates } }) => ({
      ...state,
      [userId]: {
        ...state[userId],
        choosenCalendarDates: xor(state[userId].choosenCalendarDates, newDates)
      }
    }),
    [fetchCandidateInfoResponse]: (
      state,
      { payload: { userId, candidateInfo } }
    ) => ({
      ...state,
      [userId]: {
        ...state[userId],
        error: null,
        candidateInfoError: null,
        candidateInfo
      }
    }),
    [setCandidatePosition]: (
      state,
      { payload: { userId, candidatePosition } }
    ) => ({
      ...state,
      [userId]: {
        ...state[userId],
        candidatePosition
      }
    }),
    [fetchCandidateJobInfoResponse]: (
      state,
      { payload: { userId, candidateJobInfo } }
    ) => ({
      ...state,
      [userId]: {
        ...state[userId],
        error: null,
        candidateJobInfo
      }
    }),
    [updateCandidateLangResponse]: (
      state,
      { payload: { userId, selected_language } }
    ) => ({
      ...state,
      [userId]: {
        ...state[userId],
        error: null,
        candidateInfoError: null,
        candidateInfo: {
          ...state[userId]?.candidateInfo,
          selected_language
        }
      }
    }),
    [fetchQuestionsAnswersResponse]: (
      state,
      { payload: { userId, candidateQuestions } }
    ) => ({
      ...state,
      [userId]: {
        ...state[userId],
        error: null,
        candidateQuestions
      }
    }),
    [updateCandidateInfoResponse]: (state, { payload: { userId } }) => ({
      ...state,
      [userId]: {
        ...state[userId],
        error: null
      }
    }),
    [saveRetakeAnswer]: (state, { payload = {} }) => {
      const {
        answer: { question: payloadQuestion = {} },
        userId
      } = payload;

      const answer = (state[userId].savedAnswers || []).find(
        ({ question: answerQuestion = {} }) =>
          answerQuestion.key === payloadQuestion.key
      );

      return {
        ...state,
        [userId]: {
          ...state[userId],
          error: null,
          cachedAnswer: [],
          savedAnswers: answer
            ? state[userId].savedAnswers
            : [...state[userId].savedAnswers, payload.answer]
        }
      };
    },
    [saveAnswerResponse]: (state, { payload = {} }) => {
      const {
        answer: { question: payloadQuestion = {} },
        userId
      } = payload;

      return {
        ...state,
        [userId]: {
          ...state[userId],
          error: null,
          cachedAnswer: [],
          savedAnswers: [
            ...(state[userId].savedAnswers || []).filter(
              ({ question: answerQuestion = {} }) =>
                answerQuestion.key !== payloadQuestion.key
            ),
            payload.answer
          ]
        }
      };
    },
    [saveIsAllAnswerSaved]: (
      state,
      { payload: { userId, isAllAnsweredSaved = false } }
    ) => ({
      ...state,
      [userId]: {
        ...state[userId],
        isAllAnsweredSaved
      }
    }),
    [retakeAnswerAfterFileUpload]: (
      state,
      { payload: { userId, questionKey } }
    ) => ({
      ...state,
      [userId]: {
        ...state[userId],
        cachedAnswer: state[userId].savedAnswers.filter(
          answer => answer.question.key === questionKey
        ),
        savedAnswers: state[userId].savedAnswers.filter(
          answer => answer.question.key !== questionKey
        )
      }
    }),
    [retakeAnswerResponse]: (state, { payload: { userId, answer } }) => {
      const {
        candidateJobInfo: { questions }
      } = state[userId];

      const updatedQuestions = questions?.map(
        q =>
          q?.key === answer?.question?.key
            ? { ...answer.question, attempt_number: answer.attempt_number }
            : q
        // eslint-disable-next-line function-paren-newline
      );

      return {
        ...state,
        [userId]: {
          ...state[userId],
          candidateJobInfo: {
            ...state[userId].candidateJobInfo,
            questions: updatedQuestions
          }
        }
      };
    },
    [videoAttemptResponse]: (state, { payload: { userId, attempt_number, questionId } }) => {
      const {
        candidateJobInfo: { questions }
      } = state[userId];

      const updatedQuestions = questions.map(
        q =>
          q.key === questionId
            ? { ...q, attempt_number }
            : q
        // eslint-disable-next-line function-paren-newline
      );

      return {
        ...state,
        [userId]: {
          ...state[userId],
          candidateJobInfo: {
            ...state[userId].candidateJobInfo,
            questions: updatedQuestions
          }
        }
      };
    },
    [retakeAnswerError]: (state, { payload: { userId, success } }) => ({
      ...state,
      [userId]: {
        ...state[userId],
        success,
        error: null
      }
    }),
    [saveUserCredentials]: (
      state,
      { payload: { userId, jobId, candidateToken } }
    ) => ({
      ...state,
      [userId]: {
        ...state[userId],
        userId,
        jobId,
        candidateToken
      }
    }),
    [saveOnAmazonS3Response]: (state, { payload: { userId, success } }) => ({
      ...state,
      [userId]: {
        ...state[userId],
        success,
        error: null
      }
    }),
    [saveCurrentQuestion]: (
      state,
      { payload: { userId, questionCurrentCashed } }
    ) => ({
      ...state,
      [userId]: {
        ...state[userId],
        questionCurrentCashed: { ...questionCurrentCashed }
      }
    }),
    [saveCurrentQuestionIsMuted]: (
      state,
      { payload: { userId, isMuted } }
    ) => ({
      ...state,
      [userId]: {
        ...state[userId],
        is_muted: isMuted
      }
    }),
    [savePreviousQuestion]: (state, { payload: { userId, questionId } }) => ({
      ...state,
      [userId]: {
        ...state[userId],
        previousQuestion: questionId
      }
    }),
    [fetchCandidateInfoError]: (state, { payload: { error, userId } }) => ({
      ...state,
      [userId]: {
        ...state[userId],
        candidateInfoError: error,
        error
      }
    }),
    [combineActions(
      fetchCandidateJobInfoError,
      fetchQuestionsAnswersError,
      updateCandidateInfoError,
      saveOnAmazonS3Error,
      saveAnswerError
    )]: (state, { payload: { error, userId } }) => ({
      ...state,
      [userId]: {
        ...state[userId],
        error
      }
    }),
    [changeCandidateFileError]: (state, { payload }) => ({
      ...state,
      candidateFileError: payload
    }),
    [updateCandidateFileError]: (state, { payload }) => ({
      ...state,
      currentCandidateFileError: payload
    }),
    [clearCalendarDates]: (state, { payload: { userId } }) => ({
      ...state,
      [userId]: {
        ...state[userId],
        choosenCalendarDates: []
      }
    }),
    [clearSavedAnswers]: (state, { payload: { userId } }) => ({
      ...state,
      [userId]: {
        ...state[userId],
        savedAnswers: []
      }
    }),
    [setSavedAnswers]: (state, { payload: { userId, answers } }) => ({
      ...state,
      [userId]: {
        ...state[userId],
        savedAnswers: answers
      }
    }),
    [clearUploadInfo]: (state, { payload: { userId } }) => ({
      ...state,
      [userId]: {
        ...state[userId],
        questionUploadInfo: {}
      }
    }),
    [clearPreviousQuestion]: (state, { payload: { userId } }) => ({
      ...state,
      [userId]: {
        ...state[userId],
        previousQuestion: ""
      }
    }),
    [setAudioDevice]: (state, { payload }) => ({
      ...state,
      audioDevice: payload
    }),
    [setVideoDevice]: (state, { payload }) => ({
      ...state,
      videoDevice: payload
    }),
    [clearErrors]: (state, { payload: { userId } = {} }) => ({
      ...state,
      [userId]: {
        ...state[userId],
        isCandidateLoading: null,
        isVideoLoading: null,
        success: "",
        error: null
      }
    }),
    [clearIsLoading]: (state, { payload: { userId } }) => {
      // console.log({ userId, state });

      if (state?.currentCandidateId === userId) {
        // console.log("same and should be updated yes");

        return {
          ...state,
          [userId]: {
            ...state[userId],
            isVideoLoading: null
          }
        };
      }

      return {
        ...state
      };
    },
    [setCurrentCandidateId]: (state, { payload }) => ({
      ...state,
      currentCandidateId: payload
    }),
    [setNewCandidateInfo]: (
      state,
      { payload: { userId, jobId, candidateToken } }
    ) => ({
      ...state,
      currentCandidateId: userId,
      [userId]: {
        ...state[userId],
        savedAnswers: [],
        userId,
        jobId,
        candidateToken,
        previousQuestion: ""
      }
    }),
    [downloadAllResponse]: (state, { payload }) => ({
      ...state,
      isOpen: true,
      videoArchives: [...(state.videoArchives || []), payload].filter(
        onlyUnique
      )
    }),
    [updateVideoArchives]: (state, { payload }) => ({
      ...state,
      isOpen: false,
      videoArchives: payload
    }),
    [updateIsOpenVideoArchives]: (state, { payload }) => ({
      ...state,
      isOpen: payload
    }),
    [downloadAndDeleteLinkResponse]: (state, { payload }) => ({
      ...state,
      isOpen: false,
      videoArchives: (state.videoArchives || []).filter(key => key !== payload)
    }),
    [downloadAndDeleteLinkError]: (state, { payload }) => ({
      ...state,
      isOpen: false,
      videoArchives: (state.videoArchives || []).filter(key => key !== payload),
      hasDownloadError: true
    }),
    [downloadAndDeleteLinkFailed]: state => ({
      ...state,
      isOpen: false,
      hasDownloadError: true
    }),
    [clearDownloadLinkError]: state => ({
      ...state,
      hasDownloadError: false
    }),
    [logOut]: state => ({
      ...state,
      isOpen: false,
      videoArchives: []
    }),
    [fetchHintsAndTips]: state => ({
      ...state,
      isHintsAndTipsLoading: true
    }),
    [fetchHintsAndTipsResponse]: (state, { payload }) => ({
      ...state,
      isHintsAndTipsLoading: false,
      hintsAndTips: payload
    }),
    [fetchHintsAndTipsError]: state => ({
      ...state,
      isHintsAndTipsLoading: false
    }),
    [getIdvCheckDataLoading]: (state, { payload }) => ({
      ...state,
      idvCheckData: {
        ...state.idvCheckData,
        isLoading: payload,
        isRecruiter: false
      }
    }),
    [getIdvCheckDataResponse]: (state, { payload }) => ({
      ...state,
      idvCheckData: {
        ...payload,
        isRecruiter: false
      }
    }),
    [getIdvCheckDataError]: (state, { payload }) => ({
      ...state,
      idvCheckData: {
        error: payload,
        isRecruiter: false
      }
    }),
    [updateVideoPreference]: (state, { payload }) => ({
      ...state,
      volume: payload
    })
  },
  defaultState
);
