import React, { useRef } from "react";
import { LinkIcon, SuccessIcon } from "mixins/svgIcons";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import TooltipSuccess from "components/Common/Tooltip/Success";

const Copy = ({
  t,
  isCopied,
  handleCopy,
  copyBtn,
  successStyles,
  isShowTooltip,
  btnStyles = {}
}) => {
  const btnRef = useRef(null);

  return (
    <>
      <button
        ref={btnRef}
        onClick={handleCopy}
        className="button__without-styles button__copy"
        type="button"
        style={btnStyles}
        aria-label="Copy"
      >
        {isCopied ? (
          <SuccessIcon svgIconClass="not-clickable-icon" style={successStyles} />
        ) : (
          <>
            {
              copyBtn || (
                <>
                  <LinkIcon svgIconClass="not-clickable-icon button__hover-inactive" />
                  <div className="button__copy-hover button__hover-active">
                    <span className="n-purple-100">{t("button.quickInvite")}</span>
                    <LinkIcon svgIconClass="not-clickable-icon" fill="#5A2AF1" />
                  </div>
                </>
              )
            }
          </>
        )}
      </button>

      <TooltipSuccess
        position="top"
        message={t("button.successTip")}
        target={btnRef.current}
        show={isShowTooltip}
      />
    </>
  );
};

Copy.defaultProps = {
  copyBtn: null,
  successStyles: {},
  isShowTooltip: false,
  btnStyles: {}
};

Copy.propTypes = {
  t: PropTypes.func.isRequired,
  isCopied: PropTypes.bool.isRequired,
  handleCopy: PropTypes.func.isRequired,
  copyBtn: PropTypes.node,
  successStyles: PropTypes.shape({}),
  isShowTooltip: PropTypes.bool,
  btnStyles: PropTypes.shape({})
};

export default withTranslation()(Copy);
