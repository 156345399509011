import PropTypes from "prop-types";
import React, { useState } from "react";

const TextIcon = ({
  fill
}) => {
  const [mouseOver, setMouseOver] = useState(false);

  fill = mouseOver ? "#5A2AF1" : fill;
  return (
    <svg
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onMouseEnter={() => {
        setMouseOver(true);
      }}
      onMouseLeave={() => setMouseOver(false)}
    >
      <path d="M9 6.75C9 6.34375 9.28125 6 9.675 6H17.325C17.6906 6 18 6.34375 18 6.75V8.25C18 8.6875 17.6906 9 17.325 9C16.9313 9 16.65 8.6875 16.65 8.25V7.5H14.175V12.5H15.075C15.4406 12.5 15.75 12.8438 15.75 13.25C15.75 13.6875 15.4406 14 15.075 14H11.925C11.5312 14 11.25 13.6875 11.25 13.25C11.25 12.8438 11.5312 12.5 11.925 12.5H12.825V7.5H10.35V8.25C10.35 8.6875 10.0406 9 9.675 9C9.28125 9 9 8.6875 9 8.25V6.75ZM0 0.75C0 0.34375 0.28125 0 0.675 0H10.125C10.4906 0 10.8 0.34375 10.8 0.75V3.25C10.8 3.6875 10.4906 4 10.125 4C9.73125 4 9.45 3.6875 9.45 3.25V1.5H6.075V12.5H7.425C7.79063 12.5 8.1 12.8438 8.1 13.25C8.1 13.6875 7.79063 14 7.425 14H3.375C2.98125 14 2.7 13.6875 2.7 13.25C2.7 12.8438 2.98125 12.5 3.375 12.5H4.725V1.5H1.35V3.25C1.35 3.6875 1.04063 4 0.675 4C0.28125 4 0 3.6875 0 3.25V0.75Z" fill={fill} />
    </svg>


  );
};

TextIcon.propTypes = {
  fill: PropTypes.string.isRequired
};

export default TextIcon;
