/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react-hooks/exhaustive-deps */
import { Formik } from "formik";
import { arrayOf, bool, func, object, shape, string } from "prop-types";
import Switch from "rc-switch";
import React, { useEffect, useMemo, useState } from "react";
import { withTranslation } from "react-i18next";
import uuidv4 from "uuid/v4";
import Input from "components/Common/Input";
import Message from "components/Common/Message";
import TooltipInfo from "components/Common/Tooltip/Info";
import Select from "components/Forms/Select";
import { IDV_TYPE, JOB_STATUSES } from "configs/jobs/constants";
import { previewConfig } from "configs/jobs/previewConfig";
import { ROLE_ADMIN } from "configs/user/rolesConfig";
import languageItems from "i18n/config";
import mmomentTz from "moment-timezone";
import { checkValidity, updateCurrentStateHandler } from "mixins/helpers";
import {
  translateTimezoneForSelect,
  timeZonesWithUtc
} from "mixins/helperTranslate";
import Cookies from "universal-cookie";

import "./styles.scss";
import { isEmpty } from "lodash";
import { FaBell, FaUserFriends } from "react-icons/fa";
import moment from "moment";
import DateTimePopOver from "./DateTimePopOver";

const mapIDVAnswerType = (answerType, idvType) => {
  if (answerType === IDV_TYPE) {
    return idvType;
  }

  return answerType;
};

const Preview = ({
  t,
  profileInfo,
  currentUser,
  currentJob,
  dataUsers,
  fetchUsersList,
  editableJobSetup,
  editableJobQuestions,
  editableJobTemplates,
  clearErrors,
  saveJob,
  error,
  setCurrentStepActive,
  editableJobPreview,
  languagesOptions,
  currentLanguage,
  isEditing,
  scorecardOptions
}) => {
  const cookies = new Cookies();
  cookies.remove("isPageReloaded", { path: "/" });
  cookies.remove("userId", { path: "/" });
  cookies.remove("questionId", { path: "/" });

  const [controls, setControls] = useState(previewConfig);
  const [formIsValid, setFormIsValid] = useState(true);
  const [showDeadline, setShowDeadline] = useState(false);
  const [dateError, setDateError] = useState("");
  const [timezoneError, setTimezoneError] = useState("");

  const langOptions = useMemo(
    () =>
      languagesOptions.map(({ iso_code: isoCode }) => ({
        value: isoCode,
        label: languageItems.find(({ value }) => value === isoCode)?.text
      })),
    [languagesOptions]
  );
  const showDefaultLangMessage = () => {
    if (window.Beacon) {
      window.Beacon("show-message", "88e633c8-5b31-4ed2-8947-414aad5fdb5b", {
        delay: 1,
        force: true
      });
    }
  };

  const scorecards = useMemo(
    () =>
      scorecardOptions.map(({ key, title }) => ({
        value: key,
        label: title
      })),
    [scorecardOptions]
  );

  const defaultScorecardOption = useMemo(
    () => scorecardOptions.find(option => option.is_default),
    [scorecardOptions]
  );

  const globalDefaultScorecardOption = useMemo(
    () => scorecardOptions.find(option => option.is_global_default),
    [scorecardOptions]
  );

  useEffect(() => {
    clearErrors();
    fetchUsersList(currentUser.key);
    if (editableJobPreview.id) {
      let formattedDatetime = new Date();
      if (editableJobPreview?.deadline?.value) {
        setShowDeadline(true);

        const utcDate = mmomentTz(editableJobPreview?.deadline?.value);
        // Get the timezone
        const tzValue = translateTimezoneForSelect(
          editableJobPreview?.deadline_timezone ?? controls.deadline_timezone
        )?.value;

        // Convert the UTC date to the desired timezone
        const dateInTimezone = utcDate.tz(tzValue);
        // Format the date to a string if needed
        formattedDatetime = dateInTimezone.format("YYYY-MM-DD HH:mm:ss");
      }
      setControls({
        ...editableJobPreview,
        redirect_url: {
          ...controls.redirect_url,
          value: editableJobPreview.redirect_url,
          touched: true
        },
        deadline: {
          value: editableJobPreview?.deadline?.value
            ? formattedDatetime
            : undefined
        },
        // eslint-disable-next-line no-nested-ternary
        scorecard: editableJobPreview?.scorecard?.key
          ? editableJobPreview.scorecard
          : defaultScorecardOption?.key
          ? defaultScorecardOption
          : globalDefaultScorecardOption ?? null,
        deadline_timezone: translateTimezoneForSelect(
          editableJobPreview?.deadline_timezone ?? controls.deadline_timezone
        )
      });
    } else if (currentLanguage) {
      setControls({
        ...controls,
        default_language: {
          label: languageItems.find(({ value }) => value === currentLanguage)
            ?.text,
          value: currentLanguage
        }
      });
    }

    if (!isEditing) {
      setControls(prev => ({
        ...prev,
        reviewers_to_notify: [
          ...(prev?.reviewers_to_notify || []),
          profileInfo.key
        ]?.filter(item => !isEmpty(item)),
        scorecard:
          defaultScorecardOption ?? globalDefaultScorecardOption ?? null
      }));
    }
  }, []);

  const handleReviewersDisabled = roles => {
    const { isAdmin = false, isCreator = false } = roles;
    return isAdmin || isCreator;
  };

  const handleReviewersChecked = (roles, values, user) => {
    const newReviewers = values.reviewers
      .map(reviewer => reviewer?.key ?? reviewer)
      ?.filter(item => !isEmpty(item));

    return newReviewers.includes(user.user_key);
  };

  const handleNotificationsDisabled = (roles, values, user) => {
    const { isAdmin = false, isCreator = false } = roles;

    if (isCreator) return false;
    if (!isAdmin) {
      const newReviewers = values.reviewers
        .map(reviewer => reviewer?.key ?? reviewer)
        ?.filter(item => !isEmpty(item));
      return !newReviewers.includes(user.user_key);
    }

    return false;
  };

  const handleNotificationsChecked = (roles, values, user) => {
    const newReviewersToNotify = values.reviewers_to_notify
      .map(reviewer => reviewer?.key ?? reviewer)
      ?.filter(item => !isEmpty(item));
    return newReviewersToNotify.includes(user.user_key);
  };

  const handleReviewers = (user = {}, values = {}, setFieldValue) => {
    const {
      reviewers = [],
      reviewers_to_notify: reviewersToNotify = []
    } = values;

    const newReviewers = reviewers
      .map(reviewer => reviewer?.key ?? reviewer)
      ?.filter(item => !isEmpty(item));
    const newReviewersToNotify = reviewersToNotify
      .map(reviewer => reviewer?.key ?? reviewer)
      ?.filter(item => !isEmpty(item));

    const setReviewers = new Set(newReviewers);
    const setReviewersToNotify = new Set(newReviewersToNotify);
    if (setReviewers.has(user.user_key)) {
      setReviewers.delete(user.user_key);
      setReviewersToNotify.delete(user.user_key);
    } else {
      setReviewers.add(user.user_key);
    }

    setFieldValue("reviewers", Array.from(setReviewers));
    setFieldValue("reviewers_to_notify", Array.from(setReviewersToNotify));
  };

  const handleNotifications = (user = {}, values = {}, setFieldValue) => {
    const { reviewers_to_notify: reviewersToNotify = [] } = values;

    const newReviewersToNotify = reviewersToNotify
      .map(reviewer => reviewer?.key ?? reviewer)
      ?.filter(item => !isEmpty(item));

    const setReviewersToNotify = new Set(newReviewersToNotify);

    if (setReviewersToNotify.has(user.user_key)) {
      setReviewersToNotify.delete(user.user_key);
    } else {
      setReviewersToNotify.add(user.user_key);
    }

    setFieldValue("reviewers_to_notify", Array.from(setReviewersToNotify));
  };

  const handleRoles = user => {
    let isCreator = false;

    const isAdmin = user.role === ROLE_ADMIN;
    const isOwner = user.email === profileInfo.email;
    isCreator = isOwner;

    if (editableJobPreview.id) {
      isCreator = user.email === currentJob?.created_by_email;
    }

    return { isAdmin, isOwner, isCreator };
  };

  const onGoBack = () => setCurrentStepActive("templates");

  const filterUsers = (users = [], profile = {}) => {
    if (currentJob) {
      return users.reduce((acc, item) => {
        const isOwnerEditJob = item.email === currentJob.created_by_email;
        if (isOwnerEditJob) {
          acc.unshift(item);
        } else {
          acc.push(item);
        }

        return acc;
      }, []);
    }

    return users.reduce((acc, item) => {
      const isOwnerCreateJob = item.email === profile.email;
      if (isOwnerCreateJob) {
        acc.unshift(item);
      } else {
        acc.push(item);
      }

      return acc;
    }, []);
  };

  const filtredDataOfUsers = filterUsers(dataUsers, profileInfo);

  const inputChangedHandler = (event, controlName, type) => {
    const updatedControls = updateCurrentStateHandler(
      event,
      controls,
      controlName,
      type
    );

    const formIsValidCurrent = checkValidity(
      updatedControls.redirect_url.value,
      updatedControls.redirect_url.validation
    );

    setControls(updatedControls);
    setFormIsValid(formIsValidCurrent);
  };

  const onSubmitHandler = values => {
    const { questions } = editableJobQuestions;

    const newReviewers = values.reviewers
      .map(reviewer => reviewer?.key ?? reviewer)
      ?.filter(item => !isEmpty(item));
    const newReviewersToNotify = values.reviewers_to_notify
      .map(reviewer => reviewer?.key ?? reviewer)
      ?.filter(item => !isEmpty(item));

    const newQuestions = questions?.map(question => ({
      ...question,
      answer_type: mapIDVAnswerType(question.answer_type, question.idv_type)
    }));

    let formattedDateWithTimezone = null;

    if (showDeadline) {
      const dateFromPicker = values.deadline;

      // Get the timezone from the dropdown
      const timezoneFromDropdown = values?.deadline_timezone?.value;

      // Create a moment object with the date and time from the picker
      const date = moment(dateFromPicker);

      // Combine the date and time with the selected timezone without changing the time
      const dateWithTimezone = date.clone().tz(timezoneFromDropdown, true);

      // Format the date to a string if needed
      formattedDateWithTimezone = moment.utc(dateWithTimezone).format("YYYY-MM-DD HH:mm:ss");
    }

    saveJob({
      id: editableJobPreview.id,
      agency: currentUser.key,
      title: editableJobSetup.title,
      salary: editableJobSetup.salary,
      company: editableJobSetup.company.value,
      video_link: editableJobSetup.isOwn
        ? editableJobSetup.ownVideoUrl
        : editableJobSetup.video,
      is_own_video: editableJobSetup.isOwn,
      description: editableJobSetup.description,
      questions: newQuestions,
      show_hints: values.show_hints,
      retakes: values.retakes,
      deadline: formattedDateWithTimezone,
      deadline_timezone: showDeadline ? values?.deadline_timezone?.value : null,
      reviewers: newReviewers,
      reviewers_to_notify: newReviewersToNotify,
      show_availability_calendar: values.calendar,
      redirect_url: values.redirect_url,
      default_language: values.default_language.value,
      message_templates: {
        invitation_email: !editableJobTemplates?.invitation_email
          ?.system_default
          ? editableJobTemplates?.invitation_email?.value
          : null,
        invitation_sms: !editableJobTemplates?.invitation_sms?.system_default
          ? editableJobTemplates?.invitation_sms?.value
          : null,
        reminder_email: !editableJobTemplates?.reminder_email?.system_default
          ? editableJobTemplates?.reminder_email?.value
          : null,
        reminder_sms: !editableJobTemplates?.reminder_sms?.system_default
          ? editableJobTemplates?.reminder_sms?.value
          : null,
        interview_completed_email: !editableJobTemplates
          ?.success_notification_email?.system_default
          ? editableJobTemplates?.success_notification_email?.value
          : null
      },
      status:
        controls.status !== JOB_STATUSES.DRAFT && controls.status
          ? controls.status
          : JOB_STATUSES.LIVE ?? JOB_STATUSES.LIVE,
      scorecard: values?.scorecard?.value
    });
  };

  const CustomOption = ({ label, innerProps, innerRef, isFocused }, props) => (
    <div
      {...innerProps}
      ref={innerRef}
      role="presentation"
      {...props}
      style={{
        backgroundColor: isFocused ? "#F4F4F5" : "inherit",
        borderRadius: 10,
        padding: 10,
        width: window.innerWidth <= 768 ? "100%" : 240,
        wordWrap: "break-word"
      }}
    >
      {label}
    </div>
  );

  return (
    <Formik
      enableReinitialize
      initialValues={{
        deadline: controls.deadline.value,
        deadline_timezone: controls.deadline_timezone,
        reviewers: controls.reviewers,
        reviewers_to_notify: controls.reviewers_to_notify,
        calendar: controls.show_availability_calendar,
        redirect_url: controls.redirect_url.value,
        show_hints: controls.show_hints,
        default_language: controls.default_language,
        scorecard: {
          label: controls.scorecard?.title,
          value: controls.scorecard?.key
        }
      }}
      onSubmit={values => {
        if ((!values.deadline_timezone || !values.deadline) && showDeadline) {
          if (!values.deadline) setDateError("Please select deadline");
          if (!values.deadline_timezone) setTimezoneError("Please select timezone");
          return;
        }
        onSubmitHandler(values);
      }}
      render={({ values, handleSubmit, setFieldValue }) => (
        <form className="steps-form" onSubmit={handleSubmit}>
          {error && console.log({ error })}
          {error && (
            <Message
              error
              message={
                typeof error === "object" ? JSON.stringify(error) : error
              }
            />
          )}

          <div className="review__avaliability-switcher">
            <p className="review__avaliability-switcher-text">
              <span className="label">{t("job.questions.tips")}</span>
              <TooltipInfo
                position="top"
                message={t("job.questions.tipsInfo")}
              />
            </p>

            <Switch
              name="show_hints"
              onChange={value => setFieldValue("show_hints", value)}
              checked={values.show_hints}
            />
          </div>

          <div className="review__avaliability-switcher">
            <p className="review__avaliability-switcher-text">
              <span className="label">{t("job.preview.request")}</span>
              <TooltipInfo
                position="top"
                message={t("job.preview.showCalendarTip")}
              />
            </p>

            <Switch
              name="calendar"
              onChange={value => setFieldValue("calendar", value)}
              checked={values.calendar}
            />
          </div>

          <div
            className="review__avaliability-switcher"
            style={{
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <p
              className="review__avaliability-switcher-text"
              style={{
                width: "fit-content"
              }}
            >
              <span className="label">{t("job.questions.submission_deadline")}</span>
            </p>
            <Switch
              onChange={() => {
                setShowDeadline(prev => !prev);
              }}
              checked={!!showDeadline}
            />
          </div>

          {showDeadline ? (
            <div className="review__finish-dates">
              <div>
                <div className="label-container-element">
                  <span className="label n-font-small n-black-100">
                    {t("select.jobLiveUntilTitle")}
                  </span>
                </div>
                <div
                  style={{
                    position: "relative"
                  }}
                >
                  <DateTimePopOver
                    error={dateError}
                    onChange={e => {
                      if (moment(e).startOf("day") <= moment().startOf("day")) {
                        setDateError("Deadline should be future date.");
                        setFormIsValid(false);
                      } else {
                        setDateError("");
                        setFormIsValid(true);
                      }
                      setDateError("");
                      setFieldValue("deadline", e);
                    }}
                    value={
                      values?.deadline
                        ? moment(values.deadline).format("YYYY-MM-DDTHH:mm")
                        : undefined
                    }
                  />
                </div>
                {dateError && (
                  <p className="input-error-message">{dateError}</p>
                )}
              </div>
              <div>
                <Select
                  name="deadline_timezone"
                  label={t("select.timezoneTitle")}
                  defaultValue={controls.deadline_timezone}
                  value={values.deadline_timezone}
                  options={timeZonesWithUtc}
                  components={{ Option: CustomOption }}
                  error={timezoneError}
                  changed={option => {
                    setTimezoneError("");

                  setFieldValue("deadline_timezone", option);
                }}
                />
              </div>
            </div>
          ) : null}

          <div className="review__redirect-url">
            <Input
              name="redirect_url"
              formElement={controls.redirect_url}
              inputChangedHandler={inputChangedHandler}
            />
          </div>

          <div className="review__finish-dates review__language">
            <Select
              name="default_language"
              label={t("select.jobLanguageTitle")}
              value={values.default_language}
              options={langOptions}
              changed={option => setFieldValue("default_language", option)}
            />
          </div>

          <div className="review__finish-dates review__language">
            <Select
              name="scorecard"
              label={t("scorecard.title")}
              defaultValue={controls.scorecard}
              value={values.scorecard}
              options={scorecards}
              changed={option => setFieldValue("scorecard", option)}
              disabled={editableJobPreview?.scorecard?.has_scores ?? false}
              tooltipMessage={t("scorecard.create.disabledCriteriaTooltip")}
              isShowTooltip={editableJobPreview?.scorecard?.has_scores}
            />

            <button
              onClick={showDefaultLangMessage}
              type="button"
              className="button__without-styles review__language-badge"
            >
              {t("select.jobLanguageBadge")}
            </button>
          </div>

          {filtredDataOfUsers && filtredDataOfUsers.length !== 0 && (
            <div className="review__table">
              <h3 className="review__table-title n-font-small n-font-bold">
                <span>{t("job.preview.title")}</span>
                <TooltipInfo
                  position="top"
                  message={t("job.preview.usersTip")}
                />
              </h3>

              <header className="review__table-header n-font-small n-bg-grey-50 n-white">
                <span>{t("job.preview.table.user")}</span>
                <span className="review__table-header-icons">
                  <i className="fas fa-user-friends md white">
                    <FaUserFriends />
                  </i>

                  <TooltipInfo
                    position="top"
                    message={t("job.preview.showNotificationTip")}
                  >
                    <i className="fas fa-bell sm white">
                      <FaBell />
                    </i>
                  </TooltipInfo>
                </span>
              </header>

              <main className="review__table-main">
                {filtredDataOfUsers.map((user, index) => {
                  const roles = handleRoles(user);
                  const { isCreator } = roles;

                  return (
                    <div
                      key={uuidv4()}
                      className="review__table-row n-font-small n-bg-grey-5 "
                    >
                      <span
                        className="review__email n-black-100"
                        title={user.email}
                      >
                        {user.email}
                      </span>

                      <div className="review__checkbox-container">
                        <span className="review__checkbox-wrapper">
                          {isCreator ? (
                            <span>Owner</span>
                          ) : (
                            <input
                              id={`checkbox-${index}`}
                              type="checkbox"
                              name="reviewers"
                              disabled={handleReviewersDisabled(roles)}
                              checked={handleReviewersChecked(
                                roles,
                                values,
                                user
                              )}
                              onChange={() =>
                                handleReviewers(user, values, setFieldValue)
                              }
                            />
                          )}
                        </span>

                        <span className="review__checkbox-wrapper">
                          <input
                            id={`checkbox-notificatoins-${index}`}
                            type="checkbox"
                            name="reviewers_to_notify"
                            disabled={handleNotificationsDisabled(
                              roles,
                              values,
                              user
                            )}
                            checked={handleNotificationsChecked(
                              roles,
                              values,
                              user
                            )}
                            onChange={() =>
                              handleNotifications(user, values, setFieldValue)
                            }
                          />
                        </span>
                      </div>
                    </div>
                  );
                })}
              </main>
            </div>
          )}

          <p className="review__info-text n-font-small n-black-100">
            <b>{t("job.preview.titleFinish")}</b>
            <br />
            {t("job.preview.textFinish")}
          </p>

          <div className="button__group_center">
            <button
              type="button"
              className="
                n-button__medium-border
                n-border-purple-100
                n-purple-100
              "
              onClick={onGoBack}
            >
              {t("button.back")}
            </button>

            <button
              type="submit"
              className="
                n-button__medium
                n-bg-purple-100
                n-white
              "
              disabled={!formIsValid}
            >
              <span>{t("button.publish")}</span>
            </button>
          </div>
        </form>
      )}
    />
  );
};

Preview.defaultProps = {
  scorecardOptions: undefined
};

Preview.propTypes = {
  t: func.isRequired,
  profileInfo: shape({
    key: string,
    email: string
  }).isRequired,
  currentUser: shape({
    key: string
  }).isRequired,
  currentJob: shape({
    created_by_email: string
  }).isRequired,
  dataUsers: arrayOf(object).isRequired,
  fetchUsersList: func.isRequired,
  editableJobSetup: shape({}).isRequired,
  editableJobQuestions: shape({}).isRequired,
  saveJob: func.isRequired,
  clearErrors: func.isRequired,
  error: string.isRequired,
  setCurrentStepActive: func.isRequired,
  editableJobPreview: shape({}).isRequired,
  editableJobTemplates: shape({}).isRequired,
  languagesOptions: arrayOf({ iso_code: string }).isRequired,
  currentLanguage: string.isRequired,
  isEditing: bool.isRequired,
  scorecardOptions: arrayOf(shape({}))
};

export default withTranslation()(Preview);
