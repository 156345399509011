/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import CandidateAnswerMCQItem from "./CandidateAnswerMCQItem";

const CandidateAnswerMCQ = ({ t, currentQuestionAnswer }) => {
  const [selectedValues, setSelectedValues] = useState([]);

  useEffect(() => {
    setSelectedValues(
      currentQuestionAnswer?.type_specific_data?.selected_answer_options?.map(a => a.option)
    );
  }, [currentQuestionAnswer.type_specific_data.selected_answer_options]);

  const hasCorrectOption =
  currentQuestionAnswer?.question?.type_specific_data?.answer_options?.filter(a => a.is_correct)
    ?.length > 0;


  const questions = useMemo(() => {
    const correctSelectedAnswers = [];
    const correctUnselectedAnswers = [];
    const incorrectSelectedAnswers = [];
    const incorrectUnselectedAnswers = [];

    currentQuestionAnswer?.question?.type_specific_data?.answer_options?.forEach(a => {
      if (a.is_correct) {
        if (selectedValues?.includes(a.key)) {
          correctSelectedAnswers.push(a);
        } else {
          correctUnselectedAnswers.push(a);
        }
      } else if (selectedValues?.includes(a.key)) {
        incorrectSelectedAnswers.push(a);
      } else {
        incorrectUnselectedAnswers.push(a);
      }
    });

    return {
      sortedQuestions: [
        ...correctSelectedAnswers,
        ...incorrectSelectedAnswers,
        ...correctUnselectedAnswers,
        ...incorrectUnselectedAnswers
      ],
      correctSelectedAnswers,
      incorrectSelectedAnswers,
      correctUnselectedAnswers
    };
  }, [currentQuestionAnswer, selectedValues]);

  const extraTextStatus = useMemo(() => {
    if (hasCorrectOption === false) {
      return "";
    }
    const textStrings = [];
    if (questions?.correctSelectedAnswers?.length) {
      textStrings.push(
        `${t("multipleChoice.correctCount", { value: questions?.correctSelectedAnswers?.length })}`
      );
    }
    if (questions?.incorrectSelectedAnswers?.length) {
      textStrings.push(
        `${t("multipleChoice.incorrectCount", { value: questions?.incorrectSelectedAnswers?.length })}`
      );
    }
    if (questions?.correctUnselectedAnswers?.length) {
      textStrings.push(
        `${t("multipleChoice.missedCount", { value: questions?.correctUnselectedAnswers?.length })}`
      );
    }

    if (textStrings.length === 0) {
      return "";
    }

    return ` - ${textStrings.join(", ")}`;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questions]);

  return (
    <>
      <div className="candidateanswer__mcq-list answer-scroll">
        {
            questions?.sortedQuestions?.map(option => (
              <CandidateAnswerMCQItem
                isRadio={currentQuestionAnswer?.question?.answer_type === "multiple_choice"}
                title={option.text}
                key={option.key}
                isChecked={selectedValues?.includes(option.key)}
                id={option.key}
                isCorrect={option.is_correct}
                hasCorrectOption={hasCorrectOption}

              />
            ))
        }
      </div>

      <div className="candidateanswer__mcq-status__wrapper flex justify-start">
        <p className="candidateanswer__mcq-status n-font-extra-small n-font-regular">
          {selectedValues?.length}
          {" "}
          {t("multipleChoice.of")}
          {" "}
          {questions?.sortedQuestions?.length}
          {" "}
          {t("multipleChoice.selected")}
          {extraTextStatus}
        </p>
      </div>

      <div className="candidateanswer__floatingbackground" />
    </>
  );
};

const mapStateToProps = state => ({
  currentQuestionAnswer: state?.jobsCandidate?.currentQuestionAnswer,
  error: state?.candidates?.currentCandidateFileError
});

export default connect(mapStateToProps)(withTranslation()(CandidateAnswerMCQ));
