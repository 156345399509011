import React, { useState } from "react";

const DownloadThumbnailIcon = () => {
  const [isMouseOver, setIsMouseOver] = useState(false);
  return (
    <div style={{
      position: "absolute",
      top: 10,
      right: 10
    }}
    >
      <svg
        width="26"
        height="26"
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onMouseEnter={() => setIsMouseOver(true)}
        onMouseLeave={() => setIsMouseOver(false)}
      >
        <circle cx="13" cy="13" r="13" fill={isMouseOver ? "#5A2AF1" : "white"} />
        <g clipPath="url(#clip0_13361_13421)">
          <path
            d="M13.75 7.75V13.4453L15.4609 11.7344C15.7422 11.4297 16.2344 11.4297 16.5156 11.7344C16.8203 12.0156 16.8203 12.5078 16.5156 12.7891L13.5156 15.7891C13.2344 16.0938 12.7422 16.0938 12.4609 15.7891L9.46094 12.7891C9.15625 12.5078 9.15625 12.0156 9.46094 11.7344C9.74219 11.4297 10.2344 11.4297 10.5156 11.7344L12.25 13.4453V7.75C12.25 7.35156 12.5781 7 13 7C13.3984 7 13.75 7.35156 13.75 7.75ZM8.5 15.25H10.8672L11.9219 16.3281C12.5078 16.9141 13.4688 16.9141 14.0547 16.3281L15.1094 15.25H17.5C18.3203 15.25 19 15.9297 19 16.75V17.5C19 18.3438 18.3203 19 17.5 19H8.5C7.65625 19 7 18.3438 7 17.5V16.75C7 15.9297 7.65625 15.25 8.5 15.25ZM17.125 17.6875C17.4297 17.6875 17.6875 17.4531 17.6875 17.125C17.6875 16.8203 17.4297 16.5625 17.125 16.5625C16.7969 16.5625 16.5625 16.8203 16.5625 17.125C16.5625 17.4531 16.7969 17.6875 17.125 17.6875Z"
            fill={isMouseOver ? "white" : "#595959"}

          />
        </g>
        <defs>
          <clipPath id="clip0_13361_13421">
            <rect width="12" height="12" fill="white" transform="translate(7 7)" />
          </clipPath>
        </defs>
      </svg>
    </div>


  );
};

export default DownloadThumbnailIcon;
