import React from "react";
import PropTypes from "prop-types";

const ChevronIcon = ({ isCollapsed }) => {
  if (isCollapsed) {
    return (
      <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.46634 7.31434L11.8068 2.18095C12.0644 1.93337 12.0644 1.53198 11.8068 1.28443L11.1839 0.685705C10.9268 0.438548 10.5101 0.438073 10.2524 0.684648L5.99999 4.75294L1.74764 0.684622C1.48991 0.438046 1.07318 0.438521 0.816057 0.685678L0.193175 1.28441C-0.0643917 1.53198 -0.0643917 1.93337 0.193175 2.18092L5.53366 7.31431C5.7912 7.56189 6.20878 7.56189 6.46634 7.31434Z" fill="#5A2AF1" />
      </svg>

    );
  }
  return (
    <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.53366 0.685662L0.193175 5.81905C-0.0643926 6.06663 -0.0643925 6.46802 0.193175 6.71557L0.816057 7.3143C1.07318 7.56145 1.48991 7.56193 1.74765 7.31535L6.00001 3.24706L10.2524 7.31538C10.5101 7.56195 10.9268 7.56148 11.1839 7.31432L11.8068 6.71559C12.0644 6.46801 12.0644 6.06663 11.8068 5.81907L6.46634 0.685688C6.2088 0.438109 5.79122 0.438109 5.53366 0.685662Z" fill="#5A2AF1" />
    </svg>

  );
};

export default ChevronIcon;

ChevronIcon.propTypes = {
  isCollapsed: PropTypes.bool.isRequired
};
