/* eslint-disable no-nested-ternary */
import PropTypes from "prop-types";
import React from "react";

const CandidateMultipleChoiceIcon = ({ status }) => (
  <div className="flex flex-col items-center justify-center">
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.7143 38.3281C20.7589 38.3281 21.6429 39.209 21.6429 40.25C21.6429 41.3711 20.7589 42.1719 19.7143 42.1719C18.5893 42.1719 17.7857 41.3711 17.7857 40.25C17.7857 39.209 18.5893 38.3281 19.7143 38.3281ZM40.2857 38.9688C40.9286 38.9688 41.5714 39.6094 41.5714 40.25C41.5714 40.9707 40.9286 41.5312 40.2857 41.5312H26.1429C25.4196 41.5312 24.8571 40.9707 24.8571 40.25C24.8571 39.6094 25.4196 38.9688 26.1429 38.9688H40.2857ZM20.1161 32.5625C19.7946 32.5625 19.4732 32.4824 19.2321 32.2422L16.8214 29.9199C16.3393 29.3594 16.3393 28.5586 16.8214 28.0781C17.3036 27.5977 18.1875 27.5977 18.6696 28.0781L20.1161 29.5195L23.8929 25.7559C24.375 25.1953 25.2589 25.1953 25.7411 25.7559C26.2232 26.2363 26.2232 27.0371 25.7411 27.5176L21 32.2422C20.7589 32.4824 20.4375 32.5625 20.1161 32.5625ZM19.7143 21.6719C18.5893 21.6719 17.7857 20.8711 17.7857 19.75C17.7857 18.709 18.5893 17.8281 19.7143 17.8281C20.7589 17.8281 21.6429 18.709 21.6429 19.75C21.6429 20.8711 20.7589 21.6719 19.7143 21.6719ZM40.2857 28.7188C40.9286 28.7188 41.5714 29.3594 41.5714 30C41.5714 30.7207 40.9286 31.2812 40.2857 31.2812H28.7143C27.9911 31.2812 27.4286 30.7207 27.4286 30C27.4286 29.3594 27.9911 28.7188 28.7143 28.7188H40.2857ZM42.8571 9.5C45.6696 9.5 48 11.8223 48 14.625V45.375C48 48.2578 45.6696 50.5 42.8571 50.5H17.1429C14.25 50.5 12 48.2578 12 45.375V14.625C12 11.8223 14.25 9.5 17.1429 9.5H42.8571ZM45.4286 45.375V14.625C45.4286 13.2637 44.2232 12.0625 42.8571 12.0625H17.1429C15.6964 12.0625 14.5714 13.2637 14.5714 14.625V45.375C14.5714 46.8164 15.6964 47.9375 17.1429 47.9375H42.8571C44.2232 47.9375 45.4286 46.8164 45.4286 45.375ZM40.2857 18.4688C40.9286 18.4688 41.5714 19.1094 41.5714 19.75C41.5714 20.4707 40.9286 21.0312 40.2857 21.0312H26.1429C25.4196 21.0312 24.8571 20.4707 24.8571 19.75C24.8571 19.1094 25.4196 18.4688 26.1429 18.4688H40.2857Z" fill={status === "Correct" ? "#0FAC00" : status === "Inconclusive" ? "#101933" : status === "Incorrect" ? "#E11F1F" : "#101933"} />
    </svg>
    {
      status !== ""
        ? (
          <div
            className="candidatethumbnail__item__header-pill"
            style={{
              background: status === "Correct" ? "#0FAC00" : status === "Incorrect" ? "#E11F1F" : status === "Inconclusive" ? "#101933" : ""
            }}
          >
            {status}
          </div>
        ) : null
    }
  </div>
);

CandidateMultipleChoiceIcon.propTypes = {
  status: PropTypes.string.isRequired
};

export default CandidateMultipleChoiceIcon;
