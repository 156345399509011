import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { MultiSelect } from "react-multi-select-component";

import Dropdown from "./Dropdown";

const MultiSelectComponent = ({
  t,
  options,
  isLoading,
  value,
  placeholder,
  handleSelect,
  withTranslationLabel,
  next,
  fetchLatest,
  isNextLoading,
  isSearchLoading,
  filterOptions,
  fetchOnOpen,
  fetchOnSearch
}) => {
  const customValueRenderer = selected => (
    selected.length
      ? t("select.ownerFilterSelected", { selected: selected.length })
      : t(placeholder)
  );

  return (
    <MultiSelect
      id="multi-select"
      options={options}
      value={value}
      onChange={handleSelect}
      valueRenderer={customValueRenderer}
      isLoading={isLoading}
      Dropdown={Dropdown}
      withTranslationLabel={withTranslationLabel}
      next={next}
      fetchLatest={fetchLatest}
      isNextLoading={isNextLoading}
      isSearchLoading={isSearchLoading}
      filterOptions={filterOptions}
      fetchOnOpen={fetchOnOpen}
      fetchOnSearch={fetchOnSearch}
    />
  );
};

MultiSelectComponent.defaultProps = {
  placeholder: "select.ownerFilterPlaceholder",
  withTranslationLabel: false,
  next: null,
  fetchLatest: null,
  isNextLoading: false,
  isSearchLoading: false,
  filterOptions: null,
  isLoading: undefined,
  fetchOnOpen: undefined
};

MultiSelectComponent.propTypes = {
  t: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isLoading: PropTypes.bool,
  value: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  placeholder: PropTypes.string,
  handleSelect: PropTypes.func.isRequired,
  withTranslationLabel: PropTypes.bool,
  next: PropTypes.string,
  fetchLatest: PropTypes.func,
  isNextLoading: PropTypes.bool,
  isSearchLoading: PropTypes.bool,
  filterOptions: PropTypes.func,
  fetchOnOpen: PropTypes.bool
};

export default React.memo(
  withTranslation()(MultiSelectComponent),
  (prevProps, nextProps) =>
    prevProps.options === nextProps.options &&
    prevProps.value === nextProps.value &&
    prevProps.isLoading === nextProps.isLoading &&
    prevProps.next === nextProps.next &&
    prevProps.isNextLoading === nextProps.isNextLoading &&
    prevProps.isSearchLoading === nextProps.isSearchLoading &&
    prevProps.fetchOnOpen === nextProps.fetchOnOpen

);
