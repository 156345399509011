/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable react/jsx-indent */
import React from "react";
import PropTypes from "prop-types";

import { resolveCurrency } from "mixins/helperForPayment";
import { FaCheck } from "react-icons/fa";
import "./styles.scss";
import { useMediaQuery } from "react-responsive";
import classNames from "classnames";
import { DescriptionInfo } from "mixins/svgIcons";
import { BeatLoader } from "react-spinners";

function numberWithCommas(x) {
  if (x === "") return "";
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const PlanInfo = ({
  t,
  plan: {
    name,
    numerator,
    denominator,
    description,
    jobs,
    sms,
    whiteLabel,
    premiumIntegrations,
    fairUsage
  },
  currencyPlan,
  getCheckoutLink,
  currency,
  index,
  setHoverIndex,
  onMouseLeave,
  onHoverIconMouseLeave,
  handleHoverInfoIcon,
  showFairUsage,
  isCurrenciesLoading
}) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1330px)" });
  const handleSelectPlan = () => {
    if (index === 3) {
      if (window.Beacon) {
        window.Beacon("open");
        window.Beacon("navigate", "/ask/message/");
        window.Beacon("prefill", {
          subject: "Enterprise plan quote",
          text: "Hello, I would like to request a quote for the Willo `Enterprise` plan"
        });
      }
    } else {
      getCheckoutLink(currencyPlan.key);
    }
  };

  const backgroundColor = {
    0: "#efefef",
    1: isTabletOrMobile ? "#5a2af2" : "#948cf0",
    2: "#7c55f9",
    3: "#5a2af2"
  };

  const activeJobsMobile = {
    0: 3,
    1: 24,
    2: "Custom",
    3: "Custom"
  };

  return (
    <div className="plan-info">
      <div
        className="plan-info__header"
        style={{
          background: backgroundColor[index],
          color: index === 0 ? "#5a2aef" : "white",
          borderTopRightRadius: index === 3 ? 16 : 0
        }}
      >
        <div
          className="plan-info__name"
          style={{
            color: index === 0 ? "#595959" : ""
          }}
        >
          {name}
          {name === "Growth" && !isTabletOrMobile ? (
            <div className="plan-info__name-info">{t("plans.growth.info")}</div>
          ) : null}
        </div>
        <div className="plan-info__details">
          {isCurrenciesLoading ? (
            <div className="plan-info__price">
              <BeatLoader />
            </div>
          ) : (
            <>
              <div className="plan-info__price">
                {name === "Enterprise" ? (
                  <span className="plan-info__price-enterpise">
                    {t("plans.customQuote")}
                  </span>
                ) : (
                  <span className="plan-info__price-value">
                    {`${resolveCurrency(currency)}${numberWithCommas(
                      currencyPlan?.price ?? ""
                    )}`}
                  </span>
                )}
                {name !== "Enterprise" && (
                  <div
                    className="plan-info__price-label"
                    style={{
                      color: index === 0 ? "#595959" : ""
                    }}
                  >
                    <span className="">{t(numerator)}</span>
                    {name === "Build" ? (
                      <span className="">{t("plans.perYear")}</span>
                    ) : (
                      <span className="">{t(denominator)}</span>
                    )}
                  </div>
                )}
              </div>
            </>
          )}
          <div
            className="plan-info__notice"
            style={{
              color: index === 0 ? "#768493" : ""
            }}
          >
            {t(description)}
          </div>
        </div>
      </div>
      {!isTabletOrMobile ? (
        <>
          <div className={classNames("plan-info__content", `column__${index}`)}>
            {jobs}
          </div>
          <div
            className={classNames("plan-info__content", `column__${index}`)}
            style={{
              height: 80
            }}
          >
            {t("plans.unlimited")}
            {name !== "Build" && (
              <div
                onMouseOver={handleHoverInfoIcon}
                onMouseOut={onHoverIconMouseLeave}
              >
                <DescriptionInfo
                  style={{ height: 10, width: 10, marginLeft: 5 }}
                  onMouseOver={handleHoverInfoIcon}
                  onMouseOut={onHoverIconMouseLeave}
                />
              </div>
            )}
          </div>
          <div
            className={classNames(
              "plan-info__content",
              `column__${index} fair-usage`
            )}
            style={{
              display: showFairUsage ? "flex" : "none"
            }}
          >
            {fairUsage}
          </div>
          <div
            className={classNames("plan-info__content", `column__${index}`)}
            style={{
              height: 80
            }}
          >
            {sms}
          </div>
          <div className={classNames("plan-info__content", `column__${index}`)}>
            {t(`plans.${name.toLowerCase()}.dataStorage`)}
          </div>
          <div
            className={classNames("plan-info__content", `column__${index}`)}
            onMouseOver={() => {
              setHoverIndex(index);
            }}
            onMouseOut={onMouseLeave}
          >
            <div className="check-icon flex items-center justify-center">
              <FaCheck color="white" />
            </div>
          </div>
        </>
      ) : (
        <div className="plan-info__mobile-container">
          <button
            className="plan-info__cta"
            onClick={handleSelectPlan}
            type="button"
          >
            {t("button.started")}
          </button>
          <div>
            <div className="plan-details flex flex-row flex-wrap gap-1 items-center odd">
              <FaCheck color="#62ce55" />
              <strong>{activeJobsMobile[index]}</strong>
              {" "}
              {t("plans.activeJobs")}
            </div>
            <div className="plan-details flex flex-row flex-wrap gap-1 items-center">
              <FaCheck color="#62ce55" />
              <strong>
                {t("plans.unlimited")}
              *
              </strong>
              {t("plans.includedResponse")}
              <DescriptionInfo
                style={{ height: 10, width: 10, marginLeft: 5 }}
              />
            </div>
            <div className="plan-details flex flex-row flex-wrap gap-1 items-center odd">
              <FaCheck color="#62ce55" />
              <strong>
                {" "}
                {sms}
              </strong>
              {" "}
              {t("plans.includedSMS")}
            </div>
            <div className="plan-details flex flex-row flex-wrap gap-1 items-center">
              <FaCheck color="#62ce55" />
              <strong>
                {t(`plans.${name.toLowerCase()}.dataStorage`)}
              </strong>
              {" "}
              {t("plans.dataStorage")}
            </div>
            <div className="plan-details flex flex-row flex-wrap gap-1 items-center odd">
              <FaCheck color="#62ce55" />
              {t("plans.basicIntegrations")}
            </div>
            {premiumIntegrations && (
              <div className="plan-details flex flex-row flex-wrap gap-1 items-center">
                <FaCheck color="#62ce55" />
                {t("plans.premiumIntegrations")}
              </div>
            )}
            {whiteLabel && (
              <div className="plan-details flex flex-row flex-wrap gap-1 items-center odd">
                <FaCheck color="#62ce55" />
                {t("plans.whiteLabel")}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

PlanInfo.defaultProps = {
  isCurrenciesLoading: true
};

PlanInfo.propTypes = {
  currency: PropTypes.string.isRequired,
  currencyPlan: PropTypes.shape({
    annual_price: PropTypes.string,
    key: PropTypes.string.isRequired,
    monthly_price: PropTypes.string,
    price: PropTypes.number.isRequired
  }).isRequired,
  getCheckoutLink: PropTypes.func.isRequired,
  handleHoverInfoIcon: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  onHoverIconMouseLeave: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
  plan: PropTypes.shape({
    name: PropTypes.string,
    numerator: PropTypes.string,
    denominator: PropTypes.string,
    billedMonthly: PropTypes.string,
    description: PropTypes.string,
    interval: PropTypes.string,
    size: PropTypes.number,
    users: PropTypes.number,
    sms: PropTypes.string,
    jobs: PropTypes.number,
    whiteLabel: PropTypes.bool,
    premiumIntegrations: PropTypes.bool,
    fairUsage: PropTypes.string.isRequired
  }).isRequired,
  setHoverIndex: PropTypes.func.isRequired,
  showFairUsage: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  isCurrenciesLoading: PropTypes.bool
};

export default PlanInfo;
