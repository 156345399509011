import { handleActions } from "redux-actions";
import { setSidebarCommentsData, sidebarAddComment, sidebarRemoveComment } from "./actions";
import { isCommentsLoading, updateCachedComment, isCommentUpdating, updateCommentResponse } from "../jobs/actions";

const defaultState = {
  isLoading: false,
  comments: []
};

export default handleActions(
  {
    [setSidebarCommentsData]: (state, { payload }) => ({
      ...state,
      comments: payload
    }),
    [sidebarAddComment]: (state, { payload }) => ({
      ...state,
      comments: [payload, ...state.comments]
    }),
    [sidebarRemoveComment]: (state, { payload }) => ({
      ...state,
      comments: state.comments.filter(a => a.key !== payload)
    }),
    [updateCachedComment]: (state, { payload }) => {
      const updatedComments = state.comments.map(item => {
        if (item.key === payload.key) {
          return {
            ...item,
            cached: payload?.text
          };
        }

        return {
          ...item,
          cached: ""
        };
      });

      return {
        ...state,
        comments: updatedComments
      };
    },
    [updateCommentResponse]: (state, { payload }) => {
      const updatedComments = state.comments.map(item => {
        if (item.key === payload.key) {
          return {
            ...item,
            ...payload,
            isLoading: false
          };
        }

        return item;
      });

      return {
        ...state,
        comments: updatedComments
      };
    },
    [isCommentsLoading]: (state, { payload }) => ({
      ...state,
      isLoading: payload
    }),
    [isCommentUpdating]: (state, { payload }) => ({
      ...state,
      comments: state.comments.map(item => {
        if (item.key === payload) {
          return {
            ...item,
            isLoading: true
          };
        }

        return item;
      })
    })

  },
  defaultState
);
